import React from "react";
import { Container, Typography, Box, CardMedia, Paper } from "@mui/material";
import SectionImage1 from "../../../assets/images/Section4Image2.png";
import SectionImage2 from "../../../assets/images/Section4Image1.png";
import SectionImage3 from "../../../assets/images/Section4Image3.png";
import WorldMapImg from "../../../assets/images/delivery.jpg";

const Section4 = () => {
  return (
    <Paper  
      sx={{
        backgroundImage: `url(${WorldMapImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className="main-step"
    >
      <Container maxWidth="xl">
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}  
        className="step-order p-80"
        container
        align="center"
        justifyContent="center"
      >
        <Box
          item
          xs={12}
          md={4}
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{ }}
        >
          <Box className="step-order-inner" alignItems="center" sx={{ padding: 1 }}>
            <Box sx={{  }}>
              <CardMedia
                component="img"
                src={SectionImage1}
                alt="Live from space album cover"
                sx={{ width: "40%" }}
                className="section-4-img"
              />
            </Box>
            <Box className="step-order-details" mt={4}>
              <Typography variant="h4">No Minimum Order</Typography>
              <Typography variant="body1" mt={1}>
                Locrem ipsum dolor sit amet, consectetur adipiscing elit,
              </Typography>
            </Box>
          </Box>
          <Box  className="step-order-inner" alignItems="center" sx={{ padding: 1 }}>
            <Box sx={{}}>
              <CardMedia
                component="img"
                src={SectionImage2}
                alt="Live from space album cover"
                sx={{ width: "40%" }}
                className="section-4-img"
              />
            </Box>
            <Box className="step-order-details" mt={4}>
              <Typography variant="h4">Live Order Tracking</Typography>
              <Typography variant="body1" mt={1}>
                Locrem ipsum dolor sit amet, consectetur adipiscing elit,
              </Typography>
            </Box>
          </Box>
          <Box  className="step-order-inner" alignItems="center" sx={{ padding: 1 }}>
            <Box sx={{}}>
              <CardMedia
                component="img"
                src={SectionImage3}
                alt="Live from space album cover"
                sx={{ width: "40%" }}
                className="section-4-img"
              />
            </Box>
            <Box className="step-order-details" mt={4}>
              <Typography variant="h4">Lightning-Fast Delivery</Typography>
              <Typography variant="body1" mt={1}>
                Locrem ipsum dolor sit amet, consectetur adipiscing elit,
              </Typography>
            </Box>
          </Box>
        </Box>
       
      </Box>
      </Container>
    </Paper>
  );
};

export default Section4;
