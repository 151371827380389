import React from "react";
import {
  Card,
  Box,
  Divider,
  Avatar,
  Typography,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
} from "@mui/material";
// import CallIcon from "@mui/icons-material/Call";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PropTypes from "prop-types";
import { orderStatus } from "utils/objectMapping";
import { IMAGE_URL } from "utils/api";
import OrderIcon from "../../../assets/images/clipboard.png";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { useEffect } from "react";
import { getPromoByID } from "services/DiscountService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { updateOrder } from "services/OrderService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OrderDetail = (props) => {
  const { orderData } = props;
  const dispatch = useDispatch();
  const socketOrder = useSelector((state) => state.SocketReducer.order);

  const [usedPromo, setUsedPromo] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (orderData?.discount_id)
      getPromoByID({ id: orderData?.discount_id })
        .then(({ data }) => setUsedPromo(data?.promocode))
        .catch(() => {
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while fetching discount data !",
              type: SNACKBAR_MESSAGE_TYPE.warning,
            })
          );
        });
  }, [orderData]);

  const handleCancel = (id) => {
    if (id) {
      updateOrder({ id: id, payload: { order_status: "Cancelled" } })
        .then(() => {
          dispatch(
            snackbarOpen({
              open: true,
              message: "Your order has been cancelled !",
              type: SNACKBAR_MESSAGE_TYPE.success,
            })
          );
        })
        .catch(() => {
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while cancelling your order !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const itemTotal = orderData?.OrderDetails?.reduce(
    (prev, { price, quantity }) => {
      prev = prev + price * quantity;
      return prev;
    },
    0
  );

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "70%" },
        my: "30px",
        mx: "auto",
        display: "flex",
        flexDirection: "column",
      }}      
      className="order-track"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          my: 1,
        }}
      >
        <Card
          sx={{
            bgcolor: (theme) => theme.palette.tertiary.main,
            p: 1,
            width: { xs: "auto", md: "50%" },
            mx: 1,
            height: "fit-content",
          }}
          className="order-track-detail-card"
        >
          {orderStatus(socketOrder?.order_status)}
          <Box
            sx={{
              p: 1,
              display: socketOrder?.Delivery_boy ? "flex" : "none",
            }}
          >
            <Avatar
              alt={socketOrder?.Delivery_boy?.first_name?.charAt(0)}
              src={`${IMAGE_URL}/profile/${socketOrder?.Delivery_boy?.image}`}
              sx={{ mr: 1 }}
            />
            <Box>
              <Typography>
                Hello, I am {socketOrder?.Delivery_boy?.first_name}{" "}
                {socketOrder?.Delivery_boy?.last_name}, your valet.
              </Typography>
              <Typography>
                Feel free to call me for real-time updates.
                {socketOrder?.Delivery_boy?.mobile}.
              </Typography>
            </Box>
            <Divider />
          </Box>
          <Box sx={{ display: "flex", p: 1, flexDirection: "column" }}  >  
            <Box sx={{ display: "flex" }} className="order-summary-title">
              <SummarizeOutlinedIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: "bold" }}>Order Summary</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
              className="charge"
            >
              <Typography>Total Amount</Typography>
              <Typography>${itemTotal}</Typography>
            </Box>
            <Box
              sx={{
                display: orderData?.discount_value ? "flex" : "none",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography
                display={orderData?.discount_id ? "block" : "none"}
                color="green"
              >
                Coupon ({usedPromo})
              </Typography>
              <Typography
                display={
                  orderData?.LoyaltyPoint?.redeem_point ? "block" : "none"
                }
                color="green"
              >
                Loyalty Point ({orderData?.LoyaltyPoint?.redeem_point})
              </Typography>
              <Typography color="green">
                -${orderData?.discount_value?.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              className="charge"
            >
              <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
                Tax Charges (13%)
              </Typography>
              <Typography>${orderData?.tax_charge?.toFixed(2)}</Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              className="charge"
            >
              <Typography>
                Delivery Charges
              </Typography>
              <Typography>${orderData?.delivery_charge?.toFixed(2)}</Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", my: 1 }}
              className="total-price"   
            >
              <Typography>Paid Amount</Typography>
              <Typography>
                ${orderData?.total_paid_amount?.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: orderData?.total_save_amount ? "flex" : "none",
                justifyContent: "space-between",
                my: 1,
              }}
            >
              <Typography>Total Savings</Typography>
              <Typography>
                ${orderData?.total_save_amount?.toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: { xs: "0px", md: "-25px" },
                justifyContent: "end",
                display:
                  socketOrder?.order_status !== "Pending" ? "none" : "flex",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#faa148",
                  boxShadow: "none",
                  borderRadius: "30px",
                  width: "fit-content",
                }}
                startIcon={<CancelOutlinedIcon />}
                onClick={() => handleDialogOpen(orderData?.id)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Card>

        <Card
          sx={{
            bgcolor: (theme) => theme.palette.tertiary.main,
            p: 1,
            mt: { xs: 2, md: 0 },
            width: { xs: "auto", md: "50%" },
            height: "fit-content",
            mx: 1,
          }}
        >
          <Box sx={{ p: 1, display: "flex", alignItems: "center" }}>
            <CardMedia
              src={OrderIcon}
              component="img"
              sx={{
                width: { xs: "12%", md: 40 },
                height: { xs: "12%", md: 40 },
                mr: 1,
                bgcolor: "white",
                backgroundColor: "transparent",
              }}
            />
            <Typography sx={{ fontWeight: "bold" }}>Your Order</Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 1 }} className="order-track-details">
            {orderData?.OrderDetails?.map((item, index) => (
              <>
                <Box
                  key={item?.id}
                  display="flex"
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ p: 0.5 }}
                >
                  <Box>
                    <Typography>{item?.Item?.name}</Typography>
                    <Typography>
                      {item?.quantity} x ${item?.price}
                    </Typography>
                  </Box>
                  <Typography>
                    ${parseInt(item?.quantity) * parseInt(item?.price)}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    display:
                      orderData?.OrderDetails?.length !== index + 1
                        ? "block"
                        : "none",
                  }}
                />
              </>
            ))}
          </Box>
        </Card>
      </Box>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        handleClose={handleDialogClose}
        open={dialogOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You sure Cancle the Order?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCancel(orderData?.id)}>Yes</Button>
          <Button onClick={handleDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

OrderDetail.propTypes = {
  orderData: PropTypes.object,
};

export default OrderDetail;
