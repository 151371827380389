import { Grid, Pagination, styled, TextField, Typography } from "@mui/material";
import PageWrapper from "components/wrapper/PageWrapper";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "./components/DataTable";
import "./orderhistory.css";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "60px",
    backgroundColor: (theme) => theme.palette.secondary.main,
    // border: "3px solid  #ded5cc",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      border: "3px solid #faa148",
    },
  },
}));

const showItems = 5;

const OrderHistory = () => {
  const [pageCount, setPageCount] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    setPageCount(Math.ceil(dataLength / showItems));
  }, [dataLength]);

  const handleSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  const handlePageChange = (event, page) => {
    event.preventDefault();
    setCurrentPage(page);
  };
  return (
    <PageWrapper className="order-history">
      <Typography variant="h5">Order History</Typography>
      <CustomTextField
        margin="normal"
        id="search"
        placeholder="Search"
        name="search"
        required
        fullWidth
        onChange={handleSearchInput}
        sx={{ display: "none" }}
         
      />
      <DataTable
        setDataLength={setDataLength}
        searchInput={searchInput}
        setIsLoading={setIsLoading}
        currentPage={currentPage}
        showItems={showItems}
        
      />
      {dataLength && !isLoading ? (
        <Grid
          container
          mt={3}
          alignItems="end"
          display="flex"
          flexDirection="column"
        >
          <Pagination count={pageCount} onChange={handlePageChange} />
        </Grid>
      ) : null}
    </PageWrapper>
  );
};

export default OrderHistory;
