import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const upadateUser = async ({ formData, id }) => {
  try {
    const response = await api.put(endPoints.UPDATE_USER, formData, {
      urlParams: { id: id },
    });

    return response;
  } catch (error) {
    throw Error(error);
  }
};
