import React from "react";
import { Typography, Grid, Button, Container } from "@mui/material";
// import PageWrapper from "../../components/wrapper/PageWrapper";
import AddressList from "../address/components/AddressList";
import { useNavigate } from "react-router-dom";
import './manageaddress.css';

const Address = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm" className="address">
      <Grid justifyContent="space-between" display="flex" mt={5}>
        <Typography
          containerdisplay="flex"
          flexDirection="column"
          alignItems="center"
          variant="h4"
        >
          Address
        </Typography>
        <Button
          sx={{
            borderRadius: "30px",
            py: 1,
            border: "3px solid ",
            borderColor: "#faa148",
            width: "100px",
          }}
          variant="standard"
          onClick={() => {
            navigate("/manage-address");
          }}
          className="add-address-btn"
        >
          Add
        </Button>
      </Grid>

      <Grid
        mt={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <AddressList />
      </Grid>
    </Container>
  );
};

export default Address;
