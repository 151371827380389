import React from "react";
import { Typography } from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import ManageUser from "./components/manage-user";

const Profile = () => {
  return (
    <PageWrapper>
      <Typography className="page-heading" variant="h3">Account</Typography>
      <ManageUser />
    </PageWrapper>
  );
};

export default Profile;
