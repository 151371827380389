import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import RouteMap from "./RouteMap";

const Map = (props) => {
  const { distance, setDistance } = props;

  const socketOrderReducer = useSelector(
    (state) => state?.SocketReducer?.order
  );

  const [addressCords, setAddressCords] = useState({
    lat: 23.044246,
    lng: 72.4820587,
  });

  useEffect(() => {
    if (socketOrderReducer?.Address?.lat && socketOrderReducer?.Address?.long) {
      setAddressCords({
        lat: parseFloat(socketOrderReducer?.Address?.lat),
        lng: parseFloat(socketOrderReducer?.Address?.long),
      });
    }
  }, [socketOrderReducer?.Address?.lat, socketOrderReducer?.Address?.long]);

  return (
    <RouteMap
      addressCords={addressCords}
      setDistance={() => setDistance()}
      distance={distance}
      zoom={15}
      height={"inherit"}
    />
  );
};

Map.propTypes = {
  setDistance: PropTypes.func,
  distance: PropTypes.object,
};

export default Map;
