import { useEffect, useMemo, useState } from "react";
import Geocode from "react-geocode";
import PropTypes from "prop-types";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  DirectionsRenderer,
  Marker,
} from "react-google-maps";
import { Skeleton } from "@mui/material";
import { getRestraunts } from "services/RestauntService";
import { useDispatch, useSelector } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { findNearest } from "geolib";
import DeliveryBoy from "../../../assets/images/delivery_man_marker.png";
import UserMarker from "../../../assets/images/user_marker.png";
const GoogleMapsAPI = process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyCQ7NpAn-3OKyjhvW4dZn33-uuFXZQSlKM';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

const RouteMap = (props) => {
  const googles = window?.google;

  const directionsService = new googles.maps.DirectionsService();
  const trackOrder = useSelector((state) => state?.SocketReducer);

  const socketOrderReducer = useSelector(
    (state) => state?.SocketReducer?.order
  );
  const { addressCords, zoom, setDistance } = props;
  const [origin, setOrigin] = useState();

  const dispatch = useDispatch();

  const [directions, setDirections] = useState();
  const [restrauntCords, setRestrauntCords] = useState([]);

  useEffect(() => {
    getRestraunts()
      .then((res) => {
        setRestrauntCords(
          res?.map(({ Address }) => ({
            lat: parseFloat(Address?.lat),
            lng: parseFloat(Address?.long),
          }))
        );
      })
      .catch(() =>
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching restraunts data !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        )
      );
  }, []);

  useEffect(() => {
    if (addressCords && restrauntCords) {
      const nearest = findNearest(addressCords, restrauntCords);
      setOrigin(nearest);
    }
  }, [addressCords, restrauntCords]);

  useEffect(() => {
    if (
      trackOrder?.trackData &&
      trackOrder?.trackData?.orderid == socketOrderReducer?.id
    ) {
      setOrigin({
        lat: parseFloat(trackOrder?.trackData?.latitude),
        lng: parseFloat(trackOrder?.trackData?.longitude),
      });
    }
  }, [trackOrder?.trackData, socketOrderReducer]);

  useEffect(() => {
    // windows.google return undefined on refresh
    if (googles) {
      const destination = {
        lat: parseFloat(addressCords?.lat),
        lng: parseFloat(addressCords?.lng),
      };
      if (origin) {
        directionsService.route(
          {
            origin: {
              lat: parseFloat(origin.lat),
              lng: parseFloat(origin.lng),
            },
            destination: {
              lat: parseFloat(destination.lat),
              lng: parseFloat(destination.lng),
            },
            travelMode: googles.maps.TravelMode.DRIVING,
          },
          (result) => {
            if (result?.routes?.length) {
              setDistance({
                distance: result?.routes?.[0]?.legs?.[0]?.distance?.value,
                duration: result?.routes?.[0]?.legs?.[0]?.duration?.value,
              });
              setDirections(result);
            } else {
              setDistance({
                distance: 0,
                duration: 0,
              });
            }
          }
        );
      }
    }
  }, [
    addressCords?.lat,
    addressCords?.lng,
    restrauntCords,
    origin?.lat,
    origin?.lng,
  ]);

  // function makeMarker(position, title, map) {
  //   new googles.maps.Marker({
  //     position: position,
  //     map: map,
  //     icon: "https://w7.pngwing.com/pngs/881/772/png-transparent-man-riding-red-motor-scooter-illustration-pizza-delivery-pizza-delivery-delivery-boy-photography-people-boy-thumbnail.png",
  //     title
  //   });
  // }
  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        suppressMarkers={true}
        defaultZoom={zoom}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        defaultCenter={{
          lat: addressCords?.lat,
          lng: addressCords?.lng,
        }}
        options={{
          // zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        <Marker
          position={origin}
          icon={{ url: DeliveryBoy, scaledSize: { height: 45, width: 30 } }}
          title="start"
        ></Marker>
        <Marker
          position={
            new googles.maps.LatLng(addressCords?.lat, addressCords?.lng)
          }
          icon={{ url: UserMarker, scaledSize: { height: 45, width: 30 } }}
          title="end"
        ></Marker>

        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: "#1976D2",
                strokeWeight: 4,
              },
              // markerOptions: {
              //   icon: { url: DeliveryBoy,scaledSize:{height: 60, width:40} },
              //   position: new googles.maps.LatLng(43.64476777063184 , -79.39107363135054)
              // },
              suppressMarkers: true,
            }}
          />
        )}
      </GoogleMap>
    ))
  );

  let map;
  if (addressCords?.lat !== undefined) {
    map = useMemo(() =>{
      return (
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places&sensor=false`}
          loadingElement={
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="auto"
              height={400}
            />
          }
          containerElement={<div style={{ width: "auto", height: 400 }} />}
          mapElement={<div style={{ height: "100%" }} />}
        />
      );
    },[GoogleMapsAPI, addressCords?.lat,directions])
  } else {
    map = (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="auto"
        height={400}
      />
    );
  }
  return map;
};

RouteMap.propTypes = {
  zoom: PropTypes.number,
  setDistance: PropTypes.func,
  addressCords: PropTypes.object,
  trackOrder: PropTypes.object,
};

export default RouteMap;
