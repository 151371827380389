import { Avatar, Button } from "@mui/material";
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { googleLoginVerification } from "../../store/auth/actions";
import GoogleIcon from "../../assets/images/icons/google.svg";
import "./auth.css";

const GoogleAuth = () => {
  const dispatch = useDispatch();

  const signIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      dispatch(
        googleLoginVerification({
          token: tokenResponse.access_token,
        })
      );
    },
    onFailure: () => {},
  });

  return (
    <Button
      onClick={signIn}
      variant="contained"
      sx={{
        mt: 3,
        mb: 2,
        backgroundColor: "white",
        boxShadow: "none",
        borderRadius: "30px",
        width: "fit-content",
        mr: "auto",
        ml: "auto",
      }}
      startIcon={<Avatar src={GoogleIcon} />}
    >
      Google
    </Button>
  );
};

export default GoogleAuth;
