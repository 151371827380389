import * as React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Card, TextField } from "@mui/material";
import CustomButton from "components/ui/CustomButton";

const NewsletterCard = () => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #d3d3d3",
        py: 4,
        px: 2,
        backgroundColor: "#22262f",
        textAlign: "initial",
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ color: "#ffffff", fontWeight: "bold" }}>
          Newsletter
        </Typography>
        <Typography variant="h6" sx={{ color: "#ffffff", fontSize: "14px" }}>
          Did not miss promotion & discounts.
        </Typography>
        <TextField
          id="email"
          name="email"
          variant="standard"
          placeholder="Enter your email"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "30px",
            mt: 2,
            width: "90%",
            p: 1,
          }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </CardContent>

      <CardActions sx={{ px: 2, pb: 4 }}>
        <CustomButton
          label="Subscribe"
          variant="contained"
          sx={{
            borderRadius: "30px",
            py: 1,
            color: "white",
            width: "95%",
          }}
        />
      </CardActions>
    </Card>
  );
};

export default NewsletterCard;
