import {
  Box,
  Card,
  Chip,
  // Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { addItemToCart } from "utils/core-utils";
import CustomButton from "components/ui/CustomButton";
import { getOrderHistory } from "services/OrderService";
import { addToCart, clearCart } from "store/cart/actions";
import { getItem } from "services/ItemsService";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import EventIcon from "@mui/icons-material/Event";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";

const DataTable = (props) => {
  const { setDataLength, setIsLoading, searchInput, currentPage, showItems } =
    props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = useState(true);
  const [orderList, setOrderList] = useState([]);
  // const [orderedItems, setOrderedItems] = useState([]);
 
  const loggedUserID = useSelector(
    (state) => state?.AuthReducer?.user?.data?.id
  );

  useEffect(() => {
    if (loggedUserID) {
      getOrderHistory({ id: loggedUserID })
        .then(({ data }) => {
          setOrderList(data);
          // const orderDetails = data?.map(({ OrderDetails }) => OrderDetails);

          // let orderItemList = [];
          // orderDetails?.forEach((order) => {
          //   order?.forEach(({ Item, id, item_id }) => {
          //     orderItemList.push({
          //       ...Item,
          //       orderId: id,
          //       itemId: item_id,
          //     });
          //   });
          // });
          // setOrderedItems(orderItemList);
          setLoading(false);
          setIsLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setIsLoading(false);
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while fetching order history !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
  }, [loggedUserID]);

  const searchedData = orderList?.filter((order) => {
    const lwrSearch = searchInput?.toLowerCase();
    const lwrAddress = order?.Address?.address_one?.toLowerCase();
    const strAmount = order?.total_paid_amount?.toString();
    const lclDate = new Date(order?.order_date).toLocaleDateString();

    if (!searchInput) {
      return order;
    } else {
      return (
        lwrAddress?.includes(lwrSearch) ||
        strAmount?.includes(lwrSearch) ||
        lclDate?.includes(lwrSearch)
      );
    }
  });

  const currentPageData = searchedData?.slice(
    parseInt(currentPage - 1) * parseInt(showItems),
    parseInt((currentPage - 1) * showItems) + parseInt(showItems)
  );

  useEffect(() => setDataLength(searchedData?.length), [searchedData, setDataLength]);

  const handleOrderAgain = async ({ orderDetails, type }) => {
    const itemIds = orderDetails?.map(({ item_id }) => item_id);

    dispatch(clearCart());
    const cartItems = [];

    const response = await Promise.all(itemIds?.map(getItem));

    if (response?.length) {
      const items = response?.map((data) => ({
        price: data?.price,
        id: data?.id,
        quantity: orderDetails?.find(({ item_id }) => item_id === data?.id)
          ?.quantity,
      }));

      items?.forEach(({ id, price, quantity }) => {
        const addedItems = addItemToCart(cartItems, id, price, type, quantity);

        const addDetails = addedItems?.map((item) => {
          const findItem = response?.find(
            (current) => item?.itemId === current?.id
          );

          return {
            ...item,
            itemName: findItem?.name,
            itemDescription: findItem?.description,
            itemImage: findItem?.images,
          };
        });

        dispatch(addToCart({ data: addDetails }));
      });
      navigate("/cart");
    }
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      {loading ? (
        <Grid
          display="flex"
          justifyContent="center"
          overflow="hidden"
          mt={10}
          mb={10}
        >
          <CircularProgress />
        </Grid>
      ) : orderList?.length ? (
        currentPageData?.length ? (
          matches ? (
            currentPageData?.map((order) => (
              <Card
                sx={{
                  p: 1,
                  bgcolor: (theme) => theme.palette.secondary.main,
                  mb: 1,
                }}
                key={order?.id}
              >
                <Box display={"flex"} justifyContent="space-between">
                  <Box>
                    {order?.OrderDetails?.map(({ Item, item_id, quantity }) => (
                      <Typography fontSize={15} key={item_id}>
                        {quantity} x {Item?.name}
                      </Typography>
                    ))}
                  </Box>

                  <Chip
                    label={order?.order_status}
                    variant="outlined"
                    size="small"
                    sx={{
                      bgcolor:
                        order?.order_status === "Completed"
                          ? "#e1f7e3"
                          : order?.order_status === "Cancelled"
                          ? "#f0dfdf"
                          : "#f7efe1",
                      color:
                        order?.order_status === "Completed"
                          ? "green"
                          : order?.order_status === "Cancelled"
                          ? "red"
                          : "orange",
                      borderColor: `1px solid ${
                        order.order_status === "Completed"
                          ? "green"
                          : order.order_status === "Cancelled"
                          ? "red"
                          : "orange"
                      }`,
                      borderRadius: 1,
                    }}
                    
                  />
                </Box>
                {/* <Divider sx={{ my: 1 }} /> */}

                {/* <Typography fontSize={15}>Restraunt Name</Typography> */}

                <Divider sx={{ my: 1 }} />
                <Box display={"flex"}>
                  <PlaceIcon />
                  <Typography fontSize={15}>
                    {order?.Address?.address_one}
                  </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"}>
                    <EventIcon
                      sx={{
                        mr: 1,
                      }}
                    />
                    <Typography fontSize={15}>
                      {new Date(order?.order_date).toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </Typography>
                  </Box>
                  <Typography fontSize={15}>
                    ${order?.total_paid_amount?.toFixed(2)}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box display="flex" justifyContent={"flex-end"}>
                  <CustomButton
                    label="Reorder"
                    size="small"
                    variant="contained"
                    sx={{
                      width: "fit-content",
                      fontSize: "70%",
                      whiteSpace: "nowrap",
                    }}
                    fullWidth
                    onClick={() =>
                      handleOrderAgain({
                        orderDetails: order?.OrderDetails,
                        type: "inc",
                      })
                    }
                    // startIcon={<ReplayIcon />}
                  />
                </Box>
              </Card>
            ))
          ) : (
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    <Grid container alignItems="center">
                      <Typography fontWeight="bold">Order Id</Typography>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography fontWeight="bold">Date</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography fontWeight="bold">Address</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography fontWeight="bold">Total</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Typography fontWeight="bold">Status</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData?.map((order, index) => (
                  <TableRow key={order?.id} sx={{ border: "0px 1px" }} 
                  >
                    <TableCell sx={{ width: "9%", minWidth: "9%" }}>
                        <Chip
                          variant="outlined"
                          key={index}
                          label={order?.id}
                          sx={{ mr: 1, mb: 1 }}
                          className="menu-chips"
                        />
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", width: "20%", minWidth: "9%" }}
                    >
                      <Typography>
                        {new Date(order?.order_date).toLocaleDateString(
                          "en-US",
                          {
                            weekday: "long",
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        width: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <Box>
                        <Typography>
                          {order?.Address?.address_one}{" "}
                          {order?.Address?.address_two}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center", width: "9%", minWidth: "9%" }}
                    >
                      <Typography>
                        ${order?.total_paid_amount?.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "9%", minWidth: "9%" }}>
                      <Grid container justifyContent="center">
                        {/* <Box
                          border={`1px solid ${
                            order.order_status === "Completed"
                              ? "green"
                              : order.order_status === "Cancelled"
                              ? "red"
                              : "orange"
                          }`}
                          borderRadius={30}
                          bgcolor={
                            order.order_status === "Completed"
                              ? "#e1f7e3"
                              : order.order_status === "Cancelled"
                              ? "#f0dfdf"
                              : "#f7efe1"
                          }
                          mr={"auto"}
                          ml={"auto"}
                        >
                          <Typography
                            p={1}
                            color={
                              order.order_status === "Completed"
                                ? "green"
                                : order.order_status === "Cancelled"
                                ? "red"
                                : "orange"
                            }
                            variant="caption"
                          >
                            {order.order_status}
                          </Typography>
                        </Box> */}
                        <Chip
                          variant="outlined"
                          label={order.order_status}
                          sx={{
                            color:
                              order.order_status === "Completed"
                                ? "green"
                                : order.order_status === "Cancelled"
                                ? "red"
                                : "orange",
                            bgcolor:
                              order.order_status === "Completed"
                                ? "#e1f7e3"
                                : order.order_status === "Cancelled"
                                ? "#f0dfdf"
                                : "#f7efe1",
                            border: `1px solid ${
                              order.order_status === "Completed"
                                ? "green"
                                : order.order_status === "Cancelled"
                                ? "red"
                                : "orange"
                            }`,
                          }}
                          className="status-chips"
                        ></Chip>
                      </Grid>
                    </TableCell>
                    <TableCell sx={{ width: "12%", minWidth: "12%" }}>
                      <Grid container justifyContent="center">
                        <CustomButton
                          label="Reorder"
                          variant="contained"
                          sx={{
                            borderRadius: "50px",
                            width: "fit-content",
                            fontSize: "92%",
                            whiteSpace: "nowrap",
                          }}
                          fullWidth
                          onClick={() =>
                            handleOrderAgain({
                              orderDetails: order?.OrderDetails,
                              type: "inc",
                            })
                          }
                          className="reorder-btn"
                        />
                      </Grid>
                    </TableCell>

                    <TableCell sx={{ width: "9%", minWidth: "12%" }}>
                      <Grid container justifyContent="center">
                        <CustomButton
                          label="View"
                          variant="contained"
                          sx={{
                            borderRadius: "50px",
                            width: "fit-content",
                            fontSize: "92%",
                            whiteSpace: "nowrap",
                          }}
                          fullWidth
                            onClick={() => navigate(`/order-summary/${order?.id}`)}
                          className="reorder-btn"
                        />
                      </Grid>
                    </TableCell>




                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )
        ) : (
          <Typography m={10} textAlign="center" variant="h5">
            No data found for your search
          </Typography>
        )
      ) : (
        <Typography m={10} textAlign="center" variant="h5">
          Looks like you have not ordered anything yet !{" "}
          <Link href="/" underline="none">
            Go Shopping
          </Link>
        </Typography>
      )}
    </TableContainer>
  );
};

DataTable.propTypes = {
  showItems: PropTypes.number,
  setIsLoading: PropTypes.func,
  setDataLength: PropTypes.func,
  searchInput: PropTypes.string,
  currentPage: PropTypes.number,
};

export default DataTable;
