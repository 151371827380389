import UserAccount from "../pages/user-account";
import Checkout from "pages/checkout";
import OrderHistory from "pages/order-history";
import AboutUs from "pages/about-us";
import ContactUs from "pages/contact-us";
import Layout from "layout";
import Menu from "pages/menu";
import Categories from "pages/menu/components/Categories";
import Home from "pages/home";
import Cart from "pages/cart";
import UserProfile from "pages/user-profile";
import ForgotPassword from "components/authentication/ForgotPassword";
import ChangePassword from "components/authentication/ChangePassword";
import Address from "pages/address";
import ManageAddress from "pages/address/components/manage-address";
import Faq from "pages/faq";
import LoyalityPoint from "pages/loyality-point";
import Notification from "pages/notification";
import Item from "../components/ui/Item";
import OrderStatus from "pages/track-order";
import ManageLoyalityPoint from "pages/loyality-point/components/ManageLoyalityPoint";
import Error404 from "error/error404";
import Summary from "pages/summary/Summary";

export const Router = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/user", element: <UserProfile /> },
      { path: "/cart", element: <Cart /> },
      { path: "/user-account", element: <UserAccount /> },
      { path: "/checkout", element: <Checkout /> },
      { path: "/order-history", element: <OrderHistory /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/menu/:id", element: <Menu /> },
      { path: "/menu", element: <Menu /> },
      { path: "/categories", element: <Categories /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/change-password", element: <ChangePassword /> },
      { path: "/address", element: <Address /> },
      { path: "/manage-address", element: <ManageAddress mode="Add" /> },
      { path: "/manage-address/:id", element: <ManageAddress mode="Update" /> },
      { path: "/faq", element: <Faq /> },
      { path: "/loyality-point", element: <LoyalityPoint /> },
      { path: "/notification", element: <Notification /> },
      { path: "/loyality-point", element: <LoyalityPoint /> },
      { path: "/item/:id", element: <Item /> },
      { path: "/track-order/:id", element: <OrderStatus /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/loyality-point", element: <ManageLoyalityPoint /> },
      { path: "/order-summary/:orderId", element: <Summary /> },
      { path: "*", element: <Error404 /> },
    ],
  },
];
