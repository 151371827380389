import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const getCategories = async () => {
  try {
    const response = await api.get(endPoints.GET_CATEGORIES);
    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};

export const getSubCategories = async (parent_id) => {
  try {
    const response = await api.get(endPoints.GET_SUB_CATEGORIES, {
      urlParams: { id: parent_id },
    });

    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};

export const getItemsByCat = async (cat_id) => {
  try {
    const response = await api.get(endPoints.GET_ITEMS_BY_CAT, {
      params: { cat_id },
    });
    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};

export const getItem = async (id) => {
  try {
    const response = await api.get(endPoints.GET_ITEM, {
      urlParams: { id: id },
    });

    return response?.data?.data;
  } catch ({ response }) {
    throw Error(response.data.message);
  }
};

export const getPackageItem = async (id) => {
  try {
    const response = await api.get(endPoints.GET_PACKAGE_ITEM, {
      urlParams: { id: id },
    });

    return response?.data;
  } catch ({ response }) {
    throw Error(response.data.message);
  }
};

export const getPopularPairing = async (pairing) => {
  try {
    const response = await api.get(endPoints.POPULAR_PAIRING, {
      params: { pairing },
    });

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getAllCat = async () => {
  try {
    const response = await api.get(endPoints.GET_All_CAT);
    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};
