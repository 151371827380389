import React from "react";
import { Table, TableCell, TableRow, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const OrderSummary = () => {
  const cartItems = useSelector((state) => state?.CartReducer?.cart);
  const checkoutSummary = JSON.parse(localStorage.getItem("checkoutData"));

  return (
    <Table
      sx={{
        backgroundColor: "#f2f2f2",
        borderRadius: "10px",
        padding: 2,
      }}
      className="check-summary-tbl"
    >
      <TableRow sx={{ "& td": { border: "0px 1px" } }}>
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Your Order</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>

      {cartItems?.map((item, index) => (
        <TableRow key={index} >
          <TableCell
            sx={{ textAlign: "center" }}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography> {item?.itemName}</Typography>
            {item?.quantity}x
          </TableCell>
          <TableCell sx={{ textAlign: "center" }}>
            <Typography>${item?.defaultPrice * item?.quantity}</Typography>
          </TableCell>
        </TableRow>
      ))}

      <TableRow>
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Subtotal</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Typography>${checkoutSummary?.subTotal.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>

      <TableRow sx={{ display: checkoutSummary?.discount ? null : "none" }}>
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Discount</Typography>
        </TableCell>
        <TableCell
          sx={{
            textAlign: "center",
          }}
        >
          <Typography>- ${checkoutSummary?.discount.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Tax Charges</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Typography>${checkoutSummary?.taxCharges.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Delivery</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Typography>${checkoutSummary?.deliveryCharge.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>

      <TableRow sx={{ "& td": { border: 0 } }} className="grand-total-row">
        <TableCell
          sx={{ textAlign: "center" }}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>Grand Total</Typography>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <Typography>${checkoutSummary?.grandTotal.toFixed(2)}</Typography>
        </TableCell>
      </TableRow>
    </Table>
  );
};

export default OrderSummary;
