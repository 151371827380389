import React from "react";
import { Box } from "@mui/material";
import Section0 from "./components/Section0";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import CategorySection from "./components/CategorySection";

const Home = () => {
  return (
    <Box sx={{ padding: 0, width: "100%" }}>
      <Section0 />
      <Section1 />
      <Section2 />
      <CategorySection />
      <Section3 />
      <Section4 />
    </Box>
  );
};

export default Home;
