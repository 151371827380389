import React from "react";
import { Grid, Typography, Box, List, Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
// const aboutLinks = [
//   "History",
//   "Our Team",
//   "Branch Guidelines",
//   "Terms & Conditions",
//   "Privcay & Policy",
// ];
import CustomButton from "./CustomButton";
import { useLocation, useNavigate } from "react-router-dom";

// const serviceLinks = [
//   "How to Order",
//   "Our Product",
//   "Order Status",
//   "Promo",
//   "Payment Method",
// ];

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pages = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Us",
      path: "/about-us",
    },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
    {
      title: "Menu",
      path: "/menu",
    },
  ];

  return (
    <Grid
      sx={{
        width: "100%",
        // zIndex: "99",
        position: "relative ",
      }}
      className="footer-section p-80"
    >
      <Container maxWidth="xl">
        <Grid
          sx={{
            width: "100%",
            backgroundColor: "transparent",
          }}
          container
          justifyContent="flex-start"
        >
          <Grid item xs={12} md={4} display="flex">
            <Box p={4}>
              <Typography variant="h4">Aanch Food Bazaar</Typography>
              <Typography variant="body1" mt={4}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                at dignissim nunc, id maximus ex. Etiam nec dignissim elit, at
                dignissim enim.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} display="flex" alignItems="top">
            <Box p={4}>
              <Typography variant="h4">About</Typography>
              <List dense={false}>
                {pages.map((page) => (
                  <CustomButton
                    type="contained"
                    label={page.title}
                    key={page.title}
                    onClick={() => navigate(page.path)}
                    sx={{
                      my: 0,
                      display: "block",
                      fontSize: 17,
                    }}
                    textColor={pathname === page.path ? "#faa148" : "#000"}
                    className="footer-menu-btn"
                  />
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} display="flex">
            <Box p={4} className="loction-detail">
              <Typography variant="h4">Our Location</Typography>
              <ul className="list">
                <li>
                  <span>Head Office :</span> Lorem Ipsum is simply dummy text of
                  the printing and typesetting industry.
                </li>
                <li>
                  <span>Call for Reservations : </span> +1 - 123456789 -
                  1011121613
                </li>
                <li>
                  <span>E-mail : </span>
                  <a href="mailto:test@gmail.com">test@gmail.com</a>
                </li>
              </ul>

              <div className="social-status">
                <a href="#0" className="facebook">
                  <FacebookIcon />
                </a>
                <a href="#0" className="twitter">
                  <TwitterIcon />
                </a>
                <a href="#0" className="instagram">
                  <InstagramIcon />{" "}
                </a>
                {/* <a href="#0"><span class="fab fa-behance"></span></a> */}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
