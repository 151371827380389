import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const getOrderHistory = async ({ id }) => {
  try {
    const response = await api.get(endPoints.GET_ORDER_HISTORY, {
      urlParams: { id },
    });

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const placeOrder = async ({ payload }) => {
  try {
    const response = await api.post(endPoints.ADD_ORDER, payload);

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getOrder = async (id) => {
  try {
    const response = await api.get(endPoints.GET_ORDER, {
      urlParams: { id: id },
    });

    return response?.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};

export const updateOrder = async ({ id, payload }) => {
  try {
    const response = await api.put(endPoints.UPDATE_ORDER, payload, {
      urlParams: { id: id },
    });
    return response?.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};



export const getLoginUserOrderHistory = async () => {
  try {
    const response = await api.get(endPoints.GET_LOGIN_USER_ORDER_HISTORY);
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};