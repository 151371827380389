import React from "react";
import { Grid, Typography } from "@mui/material";
import PageWrapper from "components/wrapper/PageWrapper";
import FaqAccord from "./components/FaqAccord";
import HelpCard from "pages/user-profile/components/HelpCard";
import AppCard from "pages/user-profile/components/AppCard";

const Faq = () => {
  return (
    <PageWrapper>
      <Typography variant="h4">FAQ</Typography>
      <Grid container align="left" mt={4} justifyContent="left">
        <Grid item md={9} xs={12} mt={3}>
          <FaqAccord />
        </Grid>
        <Grid item md={3} xs={12} mt={3} px={4}>
          <HelpCard />
          <AppCard />
          </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Faq;
