// Authentication
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const GOOGLE_LOGIN_VERIFICATION = "GOOGLE_LOGIN_VERIFICATION";
export const GOOGLE_LOGIN_VERIFICATION_SUCCESS =
  "GOOGLE_LOGIN_VERIFICATION_SUCCESS";
export const GOOGLE_LOGIN_VERIFICATION_FAILED =
  "GOOGLE_LOGIN_VERIFICATION_FAILED";

export const LOGOUT_USER = "LOGIN_LOGIN_OUTUSER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

export const FACEBOOK_LOGIN_VERIFICATION = "FACEBOOK_LOGIN_VERIFICATION";
export const FACEBOOK_LOGIN_VERIFICATION_SUCCESS =
  "FACEBOOK_LOGIN_VERIFICATION_SUCCESS";
export const FACEBOOK_LOGIN_VERIFICATION_FAILED =
  "FACEBOOK_LOGIN_VERIFICATION_FAILED";

export const RESET_ERROR = "RESET_ERROR";

// REGISTRATION
export const REQ_REGISTER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_USER_FAILED";

// OTP VERIFICATION
export const REQ_VERIFY_OTP = "REQ_VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";

export const REQ_RESEND_OTP = "REQ_RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILED = "RESEND_OTP_FAILED";

//PASSWORD
export const PASSWORD_FORGOT = "PASSWORD_FORGOT";
export const PASSWORD_FORGOT_SUCCESS = "PASSWORD_FORGOT_SUCCESS";
export const PASSWORD_FORGOT_FAILED = "PASSWORD_FORGOT_FAILED";

export const CONFIRM_PASSWORD = " CONFIRM_PASSWORD";
export const CONFIRM_PASSWORD_SUCCESS = " CONFIRM_PASSWORD_SUCCESS";
export const CONFIRM_PASSWORD_FAILED = "CONFIRM_PASSWORD_FAILED";

export const CHANGE_PASSWORD = " CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = " CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
// CART
export const GET_CART = "GET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const GET_CART_DATA = "GET_CART_DATA";
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS";

export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";

//ADDRESS
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";

export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS";
export const GET_ADDRESS_LIST_FAILED = "GET_ADDRESS_LIST_FAILED";

export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILED = "GET_ADDRESS_FAILED";

export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";

export const CLEAR_ADDRESS = "CLEAR_ADDRESS";
export const CLEAR_ADDRESS_SUCCESS = "CLEAR_ADDRESS_SUCCESS";

// Snackbar
export const SNACKBAR_OPEN = "SNACKBAR_OPEN";

// Socket
export const SOCKET_GET_ORDER = "SOCKET_GET_ORDER";
export const SOCKET_GET_ORDER_SUCCESS = "SOCKET_GET_ORDER_SUCCESS";
export const SOCKET_GET_ORDER_FAILED = "SOCKET_GET_ORDER_FAILED";
export const SOCKET_GET_DRIVE_POSITION = "SOCKET_GET_DRIVE_POSITION";
export const SOCKET_GET_DRIVE_POSITION_FAILED =
  "SOCKET_GET_DRIVE_POSITION_FAILED";
export const SOCKET_GET_DRIVE_POSITION_SUCCESS =
  "SOCKET_GET_DRIVE_POSITION_SUCCESS";
