import { Box, CardMedia, Divider, Typography } from "@mui/material";
import Pan from "../assets/gifs/pan.gif";
import Success from "../assets/gifs/success.gif";
import Shipping from "../assets/gifs/shipping.gif";
import Cancel from "../assets/gifs/cancel.gif";
import Hourglass from "../assets/gifs/hourglass.gif";
import Celebration from "../assets/gifs/celebration.gif";

const boxCSS = { display: "flex", alignItems: "center", p: 1 };
const cardMediaCSS = {
  width: { xs: "12%", md: 40 },
  height: { xs: "12%", md: 40 },
  borderRadius: 1,
  mr: 1,
};

const typographyCSS = { fontWeight: "bold" };

export const orderStatus = (status) => {
  const statusList = {
    Pending: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Hourglass}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Waiting for restraunt to accept your order.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Cancelled: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been cancelled.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Failed: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Looks like restraunt failed to accept your order. Please try again.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Accepted: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been accepted by the restraunt.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Prepared: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Pan}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order is being prepared.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Completed: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order is finished and ready to be dispatched.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    ["Out For Delivery"]: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Shipping}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been picked up by our valet and is on its way.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Delivered: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Celebration}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been delivered. Enjoy your food !
          </Typography>
        </Box>
        <Divider />
      </>
    ),
  };

  if (statusList[status]) {
    return statusList[status];
  }
};

const mobileTypographyCSS = { fontSize: 12, mt: -0.5 };
const mobileBoxCSS = { display: "flex", alignItems: "center" };

export const orderStatusMobile = (status) => {
  const statusList = {
    Pending: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Hourglass}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Waiting for restraunt to accept your order.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Cancelled: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order has been cancelled.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Failed: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Looks like restraunt failed to accept your order. Please try again.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Accepted: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order has been accepted by the restraunt.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Prepared: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Pan}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order is being prepared.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Completed: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order is finished and ready to be dispatched.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    ["Out For Delivery"]: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Shipping}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order has been picked up by our valet and is on its way.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Delivered: (
      <>
        <Box sx={{ ...mobileBoxCSS }}>
          {/* <CardMedia
            component="img"
            src={Celebration}
            alt=""
            sx={{
              ...cardMediaCSSMobile,
            }}
          /> */}
          <Typography sx={{ ...mobileTypographyCSS }}>
            Your order has been delivered. Enjoy your food !
          </Typography>
        </Box>
        <Divider />
      </>
    ),
  };

  if (statusList[status]) {
    return statusList[status];
  }
};

const dateCSS = { fontSize: 13 };

export const timeDifference = (date) => {
  const currentDate = new Date();
  const diff = currentDate - date;

  const diffInMinutes = diff / (1000 * 60);
  const diffInHours = diffInMinutes / 60;
  const diffInDays = diffInHours / 24;
  const diffInWeeks = diffInDays / 7;
  const diffInMonths = diffInWeeks / 4;
  const diffInYears = diffInMonths / 12;

  // minutes in an hour = 60
  // minutes in a day = 60 * 24 = 1440
  // minutes in a week = 60 * 24 * 7 = 10080
  // minutes in a month = 60 * 24 * 30.417 = 43800.48 ~ 43800
  // minutes in a year = 60 * 24 * 30.417 * 12 = 525605.6 ~ 525600

  // TODO optimize below conditions

  if (diffInMinutes < 1) {
    return <Typography sx={dateCSS}>Just Now</Typography>;
  }
  if (diffInMinutes > 1 && diffInMinutes < 60) {
    return (
      <Typography sx={dateCSS}>
        {Math.floor(diffInMinutes)} min(s) ago
      </Typography>
    );
  }
  if (diffInHours > 1 && diffInHours < 24) {
    return (
      <Typography sx={dateCSS}>
        {Math.floor(diffInHours)} hour(s) ago
      </Typography>
    );
  }
  if (diffInDays > 1 && diffInDays < 7) {
    return (
      <Typography sx={dateCSS}>{Math.floor(diffInDays)} day(s) ago</Typography>
    );
  }
  if (diffInWeeks > 1 && diffInWeeks < 4) {
    return (
      <Typography sx={dateCSS}>
        {Math.floor(diffInWeeks)} week(s) ago
      </Typography>
    );
  }
  if (diffInMonths > 1 && diffInMonths < 12) {
    return (
      <Typography sx={dateCSS}>
        {Math.floor(diffInMonths)} month(s) ago
      </Typography>
    );
  }
  if (diffInYears > 1) {
    return (
      <Typography sx={dateCSS}>
        {Math.floor(diffInYears)} year(s) ago
      </Typography>
    );
  }
};

export const orderStatusChip = () => {
  const statusList = {
    Pending: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Hourglass}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Waiting for restraunt to accept your order.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Cancelled: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been cancelled.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Failed: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Cancel}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Looks like restraunt failed to accept your order. Please try again.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Accepted: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been accepted by the restraunt.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Prepared: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Pan}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order is being prepared.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Completed: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Success}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order is finished and ready to be dispatched.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    ["Out For Delivery"]: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Shipping}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been picked up by our valet and is on its way.
          </Typography>
        </Box>
        <Divider />
      </>
    ),
    Delivered: (
      <>
        <Box sx={{ ...boxCSS }}>
          <CardMedia
            component="img"
            src={Celebration}
            alt=""
            sx={{
              ...cardMediaCSS,
            }}
          />
          <Typography sx={{ ...typographyCSS }}>
            Your order has been delivered. Enjoy your food !
          </Typography>
        </Box>
        <Divider />
      </>
    ),
  };

  if (statusList[status]) {
    return statusList[status];
  }
};
