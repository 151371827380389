import React, { useEffect } from "react";
import { Grid, Typography, Box, CardMedia } from "@mui/material";
import PageWrapper from "components/wrapper/PageWrapper";
import "../about-us.css";
import Malecook from "../../../assets/images/Malecook.png";

const Section1 = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => scrollToTop(), []);

  return (
    <Box className="section1">
      <PageWrapper className="section-p">
        <Grid container className="section-1-container">
          <Grid item md={4} xs={12} className="section-1-item aboutus-img">
            <Box>
              <CardMedia
                component="img"
                src={Malecook}
                className="section1-img"
                sx={{
                  width: "65%",
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "50px",
                }}
              />
            </Box>
          </Grid>

          <Grid item md={8} xs={12} className="section-1-item aboutus-detail">
            <Typography variant="h3" className="section1-about-us">
              About Us
            </Typography>
            <Typography variant="body1" className="section1-content">
              At Aanch Food Bazaar, our kitchen is a playground for creative pitches. We tingle the sensation of nostalgia with a generous dose of homely cooking with a bit of modern twist. With freshly selected local produce and constantly inventing creative platters, we offer the best modernistic experience for your guests.
            </Typography>
            <br />

            <Typography variant="h4" className="section1-about-us">
              About our Chef
            </Typography>
            <br />

            <Typography variant="h5" className="section1-owener">
              Sudhanshu Kumar
            </Typography>
            <Typography variant="h6" className="section1-owener-sub">
              CHEF & OWNER OF AANCH
            </Typography>
            <br />
            <Typography variant="body1" className="section1-content">
              Sudhanshu Kumar, the man behind Aanch – Modernistic Indian Cuisine is quite a storyteller with each dish unfolding his journey of becoming a chef. With a few cooking tricks up his sleeve and a pocket full of dreams Sudhanshu journeyed to Canada where he studied Culinary Management in Niagara College. It was here he realized his true calling for showcasing Indian food with a modern approach.
              <br />
              Upon entering the kitchen, he relies solely on his emotions and intuitions to create dishes. In fact, he is perpetually seeking unique ingredients and grinding his own spices to create contemporary palates.
              <br />
              For Sudhanshu, his restaurant is a reminiscence of friends and family sharing stories and enjoying his cuisine. It is for this reason you will often notice a friendly smile approaching you to make sure you feel at home.
            </Typography>
          </Grid>
          <Typography variant="body1" className="section1-about-us description">
            <b> Description and process of our offerings - </b> <span>this should be included in WHO WE ARE / HOW TO ORDER SECTION</span>
          </Typography>
          <br />
          <br />
          <Typography variant="body1" className="section1-desc-content">
            {`Join us on a delectable journey into the heart of Indian flavors.We are delighted to introduce our latest offering from Aanch Food Bazaar, our new and exciting "Indian  Lunch Combo meals" delivered piping hot right at your doorstep free.`}
            <br />
            {`Prepared by Chef Sudhanshu Kumar  brings you home-style Indian meals using the finest quality ingredients and in-house blended spices. We understand the importance of savoring a delightful meal without breaking the bank.`}
          </Typography>
          <Typography variant="h6" className="lower-heading-about-us">Why choose us:</Typography>
          <ul className="list-point">
            <li><strong>A Gourmet Experience at Affordable Prices :</strong> Get a taste of restaurant luxury at affordable pricing. We understand the importance of savoring a delightful meal without breaking the bank.</li>
            <li><strong>Weekly Changing Menus : </strong>Our commitment to variety means you will never tire of the same flavors. Explore new menus every week, offering a diverse range of dishes to suit every palate. </li>
            <li><strong>Convenience at Your Doorstep : </strong>Say goodbye to cooking woes! We bring the culinary delights straight to your doorstep, ensuring a hassle-free dining experience.</li>
            <li><strong>Personalized Chef Interaction : </strong>Ever wished you could chat with the chef? At By Aanch, you can! Chef Kumar is at your service, ready to discuss your food preferences, answer queries, and make your dining experience truly personal.</li>
            <li><strong>Avail Exciting Promotions : </strong>Enjoy the perks of various promotions that we regularly offer, making your lunch extra satisfactory.</li>
            <li><strong>Customize Your Dishes : </strong>Have specific dietary restrictions? No problem! We offer the flexibility to customize your dishes according to your preferences and dietary needs. You do not need to worry about limited choices because of your dietary restrictions.</li>
          </ul>

          <Typography variant="h6" className="lower-heading-about-us">Taste Before You Commit!</Typography>
          <Typography className="section1-desc-content">
            We would love the opportunity to plan a visit with you, give you a complimentary tasting box, and further understand your preferences to customise your perfect daily lunch meals. Do let us know when is the best time to schedule a quick meet and greet with you; we look forward to hearing back from you soon.
            We understand commiting to something takes trials and hence
            Curious about our offerings? Sign up now and receive a complimentary tasting box to experience the culinary magic firsthand. We believe in letting our food speak for itself.
          </Typography>
          <Typography variant="h6" className="lower-heading-about-us">Personal Visit Option: We Come to You!</Typography>
          <Typography className="section1-desc-content">
            If you are intrigued and want to know more, we willll personally visit you at your convenience. Lets discuss your preferences, answer any questions, and tailor our offerings to your liking.
          </Typography>

          <Typography variant="h6" className="lower-heading-about-us">
            How to Get Started:
          </Typography>
          <ul className="list-point">
            <li><strong>1. 🍽️ Sign up on our website.</strong></li>
            <li><strong>2. 📦 Receive your free tasting box.</strong></li>
            <li><strong>3. 🤝 Schedule a personal visit for a one-on-one consultation with Chef Kumar.</strong></li>
          </ul>

          <Typography className="section1-desc-content">At Aanch Food Bazaar, we are passionate about bringing the authentic taste of home-style Indian meals to your table. Do not miss out on this opportunity to elevate your dining experience with us.</Typography>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default Section1;
