import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Alert,
  Link,
  Typography,
  Divider,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { useFormik, FormikProvider, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { register } from "store/auth/actions";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { requestForToken } from "../../firebase";
import CustomLoadingButton from "components/ui/CustomLoadingButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleAuth from "./GoogleAuth";
import FacebookAuth from "./FacebookAuth";

const clientId =
  "435668069346-69dgand8hrkvhcvc3liiuhuavot3msir.apps.googleusercontent.com";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    backgroundColor: (theme) => theme.palette.secondary.main,
    border: "3px solid  #ded5cc",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      border: "3px solid #faa148",
    },
  },
  "& .MuiGrid-root": {
    "& > fieldset": {
      width: "100%",
    },
  },
}));

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  mobile: "",
  gender: "",
};

const validationSchema = Yup.object({
  first_name: Yup.string().required().label("First Name"),
  last_name: Yup.string().required().label("Last Name"),
  email: Yup.string("Enter a valid email address")
    .email()
    .required()
    .label("Email"),
  password: Yup.string()
    .required()
    .min(8)
    .label("Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$$/,
      "Password should contains atleast 8 charaters and containing uppercase,lowercase, numbers and special character !"
    ),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords doest not match")
    .label("Confirm password"),
  mobile: Yup.string().required().label("Mobile Number"),
  gender: Yup.string().required().label("Gender"),
});

const Signup = (props) => {
  const { setSignupMode, setLoginMode } = props;
  const dispatch = useDispatch();

  const [initFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showRegPassword, setShowRegPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    formik;

  const onSubmit = async (values) => {
    setLoading(true);
    event.preventDefault();

    const notificationToken = await requestForToken();

    const data = new FormData();

    // use foreach

    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("mobile", values.mobile.split(" ").join(""));
    data.append("gender", values.gender);
    data.append("notification_token", notificationToken);

    dispatch(
      register({
        data: data,
        callback: ({ error, message }) => {
          setLoading(false);
          if (error) {
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
            setSignupMode(error);
          } else {
            setSignupMode(error);
          }
        },
      })
    );
  };

  const handleClickShowRegPassword = () => {
    setShowRegPassword(!showRegPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Welcome to Aanch Food Bazaar
      </Typography>
      <Typography variant="body2">Register your account</Typography>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item md={6} pr={1} width="100%">
              <CustomTextField
                margin="normal"
                id="firstName"
                label="Firstname"
                name="first_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                autoComplete="firstname"
                required
                fullWidth
                error={errors.first_name && touched.first_name}
                helperText={
                  errors.first_name && touched.first_name
                    ? errors.first_name
                    : null
                }
              />
            </Grid>

            <Grid item md={6} pr={1} width="100%">
              <CustomTextField
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                id="lastName"
                label="Lastname"
                name="last_name"
                value={values.last_name}
                autoComplete="lastname"
                required
                fullWidth
                error={errors.last_name && touched.last_name}
                helperText={
                  errors.last_name && touched.last_name
                    ? errors.last_name
                    : null
                }
              />
            </Grid>

            <Grid item md={6} pr={1} width="100%">
              <CustomTextField
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                id="email"
                label="Email"
                name="email"
                value={values.email}
                autoComplete="email"
                required
                fullWidth
                error={errors.email && touched.email}
                helperText={errors.email && touched.email ? errors.email : null}
              />
            </Grid>

            <Grid item md={6} pr={1} width="100%">
              <InputMask
                mask="9999 999 999"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={false}
                maskChar=" "
                value={values.mobile}
              >
                {() => (
                  <CustomTextField
                    margin="normal"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    id="mobile"
                    label="Mobile no"
                    name="mobile"
                    className="auto-fill"
                    required
                    fullWidth
                    error={errors.mobile && touched.mobile}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : null
                    }
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item md={6} pr={1}>
              <CustomTextField
                id="outlined-end-adornment"
                name="password"
                value={values.password}
                label="Password"
                type={showRegPassword ? "text" : "password"}
                margin="normal"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRegPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showRegPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} pr={1}>
              <CustomTextField
                id="outlined-end-adornment"
                name="confirm_password"
                label="Confrim Password"
                type={showConfirmPassword ? "text" : "password"}
                margin="normal"
                required
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirm_password && touched.confirm_password}
                helperText={
                  errors.confirm_password && touched.confirm_password
                    ? errors.confirm_password
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={12} pr={1}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Gender
              </FormLabel>
              <RadioGroup
                row
                name="gender"
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <CustomLoadingButton
                  label="Register"
                  variant="contained"
                  type="submit"
                  loading={loading}
                  onClick={handleSubmit}
                  sx={{
                    my: 2,
                    borderRadius: 1,
                    px: 3,
                    py: 1,
                    color: "#fff",
                    boxShadow: "none",
                  }}
                />

                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    setLoginMode(true);
                    setSignupMode(false);
                  }}
                >
                  {"Have an account? Login"}
                </Link>
              </Box>

              <Divider>
                <Typography variant="caption">OR</Typography>
              </Divider>

              <Box
                justifyContent={"space-between"}
                display="flex"
                sx={{ flexDirection: { xs: "column", md: "row" } }}
              >
                <GoogleOAuthProvider clientId={clientId}>
                  <GoogleAuth />
                </GoogleOAuthProvider>
                <FacebookAuth />
              </Box>
            </Grid>

            <Box sx={{ minHeight: "70px", width: "100%" }}>
              {errors.password && touched.password ? (
                <Alert severity="error">
                  <ErrorMessage name="password" />
                </Alert>
              ) : null}
            </Box>

            {/* <Grid container> */}
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

Signup.propTypes = {
  setSignupMode: PropTypes.func,
  setLoginMode: PropTypes.func,
};

export default Signup;
