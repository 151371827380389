export const endPoints = {
  googleRefreshToken: "/auth/refresh_token",
  googleLoginVerification: "/auth/login_whith_google",
  facebookLoginVerification: "/auth/login_whith_facebook",
  // AUTH
  REGISTER: "/auth/register",
  USER_LOGIN: "/auth/login",
  USER_LOGOUT: "/auth/logout",
  GET_PROFILE: "/auth/profile",
  RESEND_OTP: "/auth/resend_otp",
  VERIFY_OTP: "/auth/verification_otp_registration",
  UPDATE_USER: "/auth/update_profile/:id",
  FORGOT_PASSWORD: "/auth/forgot_password",
  CONFIRM_PASSWORD: "/auth/confirm_forgot_password",
  CHANGE_PASSWORD: "/auth/change_password",
  // ORDER
  GET_ORDER_HISTORY: "/order/getorderbyuserid/:id",
  GET_LOGIN_USER_ORDER_HISTORY: "/items/gethistoryitem",
  ADD_ORDER: "/order/addorder",
  GET_ORDER: "/order/getorderbyid/:id",
  UPDATE_ORDER: "/order/updateorder/:id",
  // CATEGORY
  GET_CATEGORIES: "/category/getcategories",
  GET_SUB_CATEGORIES: "/category/getsubcategory/0",
  GET_ITEMS_BY_CAT: "/items/getitems",
  GET_ITEM: "/items/getitemsbyid/:id",
  GET_PACKAGE_ITEM: "/items/getitemspackagebyid/:id",
  GET_All_CAT: "/category/getallcategory",

  POPULAR_PAIRING: "/items/searchitems",
  // ADDRESS
  GET_ADDRESSES: "/address/getaddressbyuser",
  ADD_ADDRESS: "/address/addaddress",
  UPDATE_ADDRESS: "/address/updateaddress/:id",
  GET_ADDRESS: "/address/getaddressbyid/:id",
  DELETE_ADDRESS: "/address/deleteaddress/:id",
  // DISCOUNT
  GET_DISCOUNTS: "/discount/getdiscount",
  VERIFY_PROMO: "/discount/verifypromo",
  GET_PROMO_BY_ID: "/discount/getdiscountbyid/:id",
  GET_BEST_DISCOUNT_OF_THE_DAY: "/discount/getbestdiscountofday",
  CALCULATE_CASHBACK: "/loyalitypoint/calculate-cashback",
  GET_BANNER: "/banner/getbanner",
  MAKE_PAYMENT: "/payment/make-payment",
  GET_RESTRAUNTS: "/restaurant/get-restaurant",
  GET_DEFAULT_PROMOCODE: "/discount/defaultpromo",
  // DELIVERY CHARGES
  CAL_DELIVERY_CHARGES: "/delivery-charges/get_delivery_charges",
  //PAYMENT
  GET_SETTING: "/setting/get-setting",
  //LOYALITY POINTS
  GET_LOYALITY_POINTS: "loyalitypoint/getloyalitypoint/:id",
  // ADD_TO_CART
  ADD_TO_CART: "/cart/addcart",
  DELETE_CART_ITEM: "/cart/deletecartitem/:id",
  GET_CART_DATA: "/cart/getcart",
};
