import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography, Grid, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const CustomAccordion = styled(Accordion)(() => ({
  "& :before": {
    height: "0px",
  },
}));

const rows = [
  {
    date: "June 8, 2022",
    deliveryaddress: "27 division, New York",
    amount: "$221.35",
    status: "Preparing",
    items: [
      {
        name: "Soup 2x",
        amount: "$221.35",
      },
      {
        name: "Soup 2x",
        amount: "$221.35",
      },
    ],
    payment: "Payment Method",
    mode: "credit Card: ****5467",
  },
  {
    date: "June 8, 2022",
    deliveryaddress: "27 division, New York",
    amount: "$221.35",
    status: "Delivered",
    items: [
      {
        name: "Soup 2x",
        amount: "$221.35",
      },
      {
        name: "Soup 2x",
        amount: "$221.35",
      },
    ],
  },
];

const DataTable = () => {
  return rows.map((row, index) => {
    return (
      <CustomAccordion sx={{ boxShadow: "none", py: 2 }} key={index}>
        <AccordionSummary
          sx={{
            backgroundColor: "#d3d3d3",
            borderRadius: "30px",
            py: 2,
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Grid container justifyContent="flex-start" key={index}>
            <Grid item xs={3} sx={{ textAlign: "left", pl: 2 }}>
              <Typography sx={{ fontSize: "14px" }}>{row.date}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "left", pl: 2 }}>
              <Typography sx={{ fontSize: "14px" }}>
                {row.deliveryaddress}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "left", pl: 4 }}>
              <Typography sx={{ fontSize: "14px" }}>{row.amount}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "left", pl: 2 }}>
              <Typography sx={{ fontSize: "14px" }}>{row.status}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableBody>
              {row.items?.map((item) => (
                <TableRow key={item.name}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell sx={{ pl: "-32px" }}>{item.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </CustomAccordion>
    );
  });
};

export default DataTable;
