export const addItemToCart = (
  cartItems,
  itemId,
  defaultPrice,
  type,
  existingQuantity = 0,
  isCart
) => {
  console.log("🚀 ~ 11111111111111111111isCart:", isCart)
  console.log("🚀 ~ 11111111111111111111existingQuantity:", existingQuantity)
  console.log("🚀 ~ 1111111111111111111type:", type)
  console.log("🚀 ~ 1111111111111111111defaultPrice:", defaultPrice)
  console.log("🚀 ~ 11111111111111111111111itemId:", itemId)
  console.log("🚀 ~ 11111111111111111111111cartItems:", cartItems)
  let isLoggedIn = localStorage.getItem('isLoggedIn')
  const item = cartItems?.find((current) => isCart == true ? isLoggedIn == 'true' ? current?.id === itemId : current?.itemId === itemId : current?.itemId === itemId);

  if (item) {
    if (!existingQuantity) {
      let quantity = parseInt(item?.quantity);
      if (type === "inc") {
        quantity++;
      } else {
        quantity--;
      }
      item.quantity = quantity;
    } else {
      item.quantity = existingQuantity;
    }
  } else {
    cartItems.push({
      itemId,
      quantity: existingQuantity ? existingQuantity : 1,
      defaultPrice,
    });
  }
  return cartItems;
};

export const addItemToCartWithSize = (
  cartItems,
  itemId,
  size,
  defaultPrice,
  type,
  size_price_id,
  existingQuantity = 0,
) => {
  const item = cartItems?.find(
    (current) => current.itemId === itemId && current.size === size
  );

  // const index = cartItems?.findIndex(
  //   (current) => current.itemId === itemId && current.size === size
  // );
  if (item) {
    if (!existingQuantity) {
      let quantity = parseInt(item?.quantity);
      if (type === "inc") {
        quantity++;
      } else {
        quantity--;
       
      }
      item.quantity = quantity;
    } else {
      item.quantity = existingQuantity;
    }
  } else {
    cartItems.push({
      itemId,
      quantity: existingQuantity ? existingQuantity : 1,
      defaultPrice,
      size,
      size_price_id
    });
  }

  // if (item) {
  //   let quantity = parseInt(item?.quantity);
  //   if (type === "inc") {
  //     quantity++;
  //   } else {
  //     quantity--;
  //     if (!quantity) {
  //       cartItems.splice(index, 1);
  //     }
  //   }
  //   item.quantity = quantity;
  // } else {
  //   cartItems.push({ itemId, quantity: quantity, defaultPrice, size,size_price_id });
  // }
  return cartItems;
};
