import React from "react";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import CustomButton from "components/ui/CustomButton";
import NotificationList from "pages/notification/components/NotificationList";
import Section from "pages/notification/components/Section";

const Notification = () => {
  return (
    <PageWrapper>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
        Notification
      </Typography>
      <Grid container>
        <Grid md={6} xs={12}>
          <CustomButton
            variant="contained"
            label="Activity"
            type="submit"
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: "20px",
              px: 3,
              py: 1,
              color: "#fff",
              boxShadow: "none",
              justifyContent: "space-between",
            }}
          />
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: "20px",
              px: 3,
              py: 1,
              color: " #a6a1a1",
              boxShadow: "none",
              backgroundColor: "#f5f5f5",
            }}
          >
            ACtivity
          </Button>
        </Grid>
        <Grid
          md={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box
            sx={{
              minWidth: 120,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Select</InputLabel>
              <Select label="Day" sx={{ borderRadius: "65px" }}>
                <MenuItem>Today</MenuItem>
                <MenuItem>Yesterday</MenuItem>
                <MenuItem>This Week</MenuItem>
                <MenuItem>Last Week</MenuItem>
                <MenuItem>This Month</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid mt={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Today
        </Typography>
        <NotificationList />
      </Grid>
      <Grid mt={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Yesterday
        </Typography>
        <Section />
      </Grid>
    </PageWrapper>
  );
};

export default Notification;
