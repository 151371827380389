import React, { useState, useEffect } from "react";
import RoomIcon from "@mui/icons-material/Room";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Skeleton, Typography } from "@mui/material";

import AddressDialog from "./AddressDialog";
import CustomizedDialog from "components/ui/Dialog";
import CustomButton from "components/ui/CustomButton";
import { getAddressList, getAddress } from "store/address/actions";
import Authentication from "components/authentication/Authentication";
import { upadateUser } from "services/UserService";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import RouteMap from "pages/address/components/RouteMap";
import PropTypes from "prop-types";

const AddressSection = (props) => {
  const { distance, setDistance } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const apiCall = useRef(false);

  const token = useSelector((state) => state.AuthReducer.token);
  const user = useSelector((state) => state.AuthReducer.user.data);
  const addressReducer = useSelector((state) => state.AddressReducer);
  const localStorageAddress = parseInt(localStorage.getItem("addressID"));

  const [addressID, setAddressID] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [dialogOpenAuth, setDialogOpenAuth] = useState(false);
  const [addressCords, setAddressCords] = useState({
    lat: 23.044246,
    lng: 72.4820587,
  });

  useEffect(() => {
    if (user) {
      setDialogOpenAuth(false);
    }
  }, [user]);

  useEffect(() => {
    // if (apiCall.current) {
    if (!addressReducer?.addressList?.length && user) {
      dispatch(
        getAddressList({
          callback: ({error,status}) => {
            if (error && status != 404) {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "Something went wrong while fetching address list !",
                  type: SNACKBAR_MESSAGE_TYPE.error,
                })
              );
            }
          },
        })
      );
    }
    // }
    // apiCall.current = true;
    setAddressList(addressReducer?.addressList);
    setDeliveryAddress(addressReducer?.address);
    setAddressCords({
      lat: parseFloat(addressReducer?.address?.lat),
      lng: parseFloat(addressReducer?.address?.long),
    });
  }, [addressReducer?.address?.lat, addressReducer?.addressList, user]);

  useEffect(()=>{
    if(addressReducer?.address)
    {
      setDeliveryAddress(addressReducer?.address);
    }
  }, [addressReducer?.address])

  useEffect(() => {
    if (user) {
      if (localStorageAddress && user?.address_id !== localStorageAddress) {
        setAddressID(localStorageAddress);
      } else {
        if (!isNaN(parseInt(user?.address_id))) {
          setAddressID(parseInt(user?.address_id));
        } else {
          localStorage.setItem("my_address_id", addressList?.[0]?.id);
          setAddressID(parseInt(addressList?.[0]?.id));
          const formData = new FormData();
          formData.append("address_id", addressList?.[0]?.id || null);
          upadateUser({ formData, id: user?.id });
        }

        if (!localStorageAddress) {
          localStorage.setItem("address_id", user?.address_id);
        }
      }
    }
  }, [user, localStorageAddress, addressList]);

  
  useEffect(() => {
    if (token && addressID) {
      dispatch(
        getAddress({
          id: addressID,
          callback: ({ error }) => {
            if (error) {
              const formData = new FormData();
              formData.append("address_id", addressList?.[0]?.id);
              upadateUser({ formData, id: user?.id });
              localStorage.setItem("address_id", addressList?.[0]?.id);
            }
          },
        })
      );
    }
  }, [addressID, addressReducer?.error?.message]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogCloseAuth = () => {
    setDialogOpenAuth(false);
  };

  return token ? (
    <Box sx={{ padding: { xs: "16px 0px", md: 2 } }}>
      <Box borderRadius={1} mt="3.8%" bgcolor="#f2f2f2" p={2}   >
        {!addressReducer?.loading ? (
          !Object.keys(deliveryAddress).length ? (
            !addressList.length ? (
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Typography>No Addresses Found</Typography>
                <CustomButton
                  label="Add address"
                  variant="contained"
                  onClick={() => {
                    if (token) {
                      navigate("/manage-address");
                    } else {
                      setDialogOpenAuth(true);
                    }
                  }}
                />
              </Box>
            ) : (
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Typography>No Defalut Address Found</Typography>
                <CustomButton
                  label="Choose Address"
                  variant="contained"
                  onClick={() => setDialogOpen(true)}
                />
                <CustomizedDialog
                  overflow="auto"
                  open={dialogOpen}
                  onCancel={handleDialogClose}
                  handleClose={handleDialogClose}
                >
                  <AddressDialog
                    setAddressID={setAddressID}
                    deliveryAddressID={deliveryAddress?.id}
                    handleDialogClose={handleDialogClose}
                  />
                </CustomizedDialog>
              </Box>
            )
          ) : (
            <>
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Box display={"flex"} mr={1}>
                  <RoomIcon
                    sx={{ mr: 1, color: (theme) => theme.palette.primary.main }}
                  />
                  <Typography>
                    Delivery at {deliveryAddress?.address_type}
                  </Typography>
                  {user?.address_id === deliveryAddress?.id ? (
                    <Chip
                      label="Defalut"
                      variant="outlined"
                      size="small"
                      sx={{
                        borderColor: (theme) => theme.palette.primary.main,
                        ml: 1,
                      }}
                    />
                  ) : null}
                </Box>
                <CustomButton
                  label="Change"
                  variant="contained"
                  size="small"
                  onClick={() => setDialogOpen(true)}
                  sx={{ height: "100%", width: "18.5%", minWidth: "70px" }}
                  className="address-change-btn"
                />
              </Box>
              <Typography width={"70%"} pl={"4%"} mb={1}>
                {deliveryAddress?.address_one} {deliveryAddress?.address_two}{" "}
                {deliveryAddress?.zipcode}
              </Typography>

              <CustomizedDialog
                overflow="auto"
                open={dialogOpen}
                onCancel={handleDialogClose}
                handleClose={handleDialogClose}
              >
                <AddressDialog
                  setAddressID={setAddressID}
                  deliveryAddressID={deliveryAddress.id}
                  handleDialogClose={handleDialogClose}
                />
              </CustomizedDialog>
              <RouteMap
                addressCords={addressCords}
                setDistance={setDistance}
                distance={distance}
                zoom={15}
                height={"inherit"}
              />
            </>
          )
        ) : (
          <>
            <Box
              display={"flex"}
              justifyContent="space-between"
              alignItems={"center"}
              height={30}
              width="100%"
            >
              <Skeleton width={"30%"} height="30px" />
              <Skeleton width={"18.5%"} height="60px" />
            </Box>
            <Skeleton width={"70%"} height="30px" />
          </>
        )}
      </Box>
      <CustomizedDialog
        handleClose={handleDialogCloseAuth}
        open={dialogOpenAuth}
        onClick={() => {}}
        onCancel={handleDialogCloseAuth}
      >
        <Authentication handleDialogClose={handleDialogCloseAuth} />
      </CustomizedDialog>
    </Box>
  ) : null;
};

AddressSection.propTypes = {
  setDistance: PropTypes.func,
  distance: PropTypes.object,
};

export default AddressSection;
