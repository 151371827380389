import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import { SummaryDetails } from "components/ui/summaryDetails";
import { getOrder } from "services/OrderService";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

const Summary = () => {
  const { orderId } = useParams();
  let [state, setState] = useState();
  const loggedUserID = useSelector(
    (state) => state?.AuthReducer?.user?.data?.id,
  );
  useEffect(() => {
    getOrder(orderId).then((res) => {
      if (res && res?.data && res?.data.id == orderId) {
        setState(res?.data);
      }
    });
  }, [loggedUserID, orderId]);

  return (
    <PageWrapper className="cart-table">
      <Typography variant="h3">Order Summary</Typography>
      <Grid container align="center" justifyContent="center">
        <Grid md={12} mt={3} width="100%">
          <SummaryDetails orderList={state} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

Summary.propTypes = {
  orderId: PropTypes.string,
};
export default Summary;
