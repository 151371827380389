import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PageWrapper from "components/wrapper/PageWrapper";
import {
  OutlinedInput,
  Box,
  InputAdornment,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getCategories } from "services/ItemsService";
import { IMAGE_URL } from "utils/api";
import CustomCard from "components/ui/CustomCard";
import CardSkeleton from "components/ui/CardSkeleton";

const Category = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputText, setInputText] = useState("");
  const navigate = useNavigate();

  const inputHandler = (e) => {
    setInputText(e.target.value);
  };

  const skeletonCount = [1, 2, 3];
  // const apiCall = useRef(false);

  const getCategory = () => {
    // if (!apiCall.current) {
    getCategories()
      .then((res) => {
        setCategoryList(res);
        setIsLoading(false);
      })
      .catch((err) => {
        return err;
      });
    // }
    // apiCall.current = true;
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleClick = (category) => {
    navigate(`/menu/${category.id}`);
  };

  const filteredList = categoryList?.filter((list) => {
    const listName = list?.name?.toLowerCase();
    if (!inputText) {
      return list;
    } else {
      return listName?.includes(inputText.toLowerCase());
    }
  });
  return (
    <PageWrapper>
      <Typography variant="h3">Category</Typography>
      <Box container justifyContent="center">
        <Box sx={{ width: "100%", textAlign: "center", mt: 4 }}>
          <OutlinedInput
            placeholder="Search"
            onChange={inputHandler}     
            sx={{ width: "60%", borderRadius: "30px" }}
            className="search-input"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            }
          />
        </Box>
        <Box
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          mt={4}
        >
          {isLoading ? (
            skeletonCount?.map((index) => <CardSkeleton key={index} />)
          ) : filteredList?.length ? (
            filteredList?.map((category) => {
              return (
                <Box
                  item
                  justifyContent="center"
                  display="flex"
                  key={category.id}
                  md={4}
                  sm={6}
                  xs={12}
                  lg={3}
                  xl={3}
                  
                >
                  <CustomCard
                    image={`${IMAGE_URL}/category/${category.image}`}
                    title={category?.name}
                    onClick={() => handleClick(category)}
                    isCategory={true}
                    currentPage="categories"
                  />
                </Box>
              );
            })
          ) : (
            <Typography
              m={10}
              sx={{ marginInline: "auto" }}
              textAlign="center"
              variant="h5"
            >
              No data found !
            </Typography>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Category;
