import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GogglePlay from "../../../assets/images/Googleplay.png";
import AppStore from "../../../assets/images/Appstore.png";
import { Card, CardActionArea, CardMedia } from "@mui/material";

const AppCard = () => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        py: 4,
        px: 2,
        textAlign: "initial",
      }}
    >
      <CardContent sx={{ textAlign: "justify", p: 4 }}>
        <Typography
          variant="h6"
          sx={{ color: "#000000", fontWeight: "bold", mb: 1 }}
        >
          Download The App
        </Typography>

        <CardActionArea sx={{ px: 0, display: "flex" }}>
          <CardMedia
            component="img"
            src={GogglePlay}
            alt=""
            sx={{ width: "50%", mr: 5 }}
          />
          <CardMedia
            component="img"
            src={AppStore}
            alt=""
            sx={{ width: "40%" }}
          />
        </CardActionArea>
      </CardContent>
    </Card>
  );
};

export default AppCard;
