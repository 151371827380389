import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PropTypes from "prop-types";
import { placeOrder } from "services/OrderService";
import { makePayment } from "services/PaymentService";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { deliveryCharge, SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { clearCart } from "store/cart/actions";
import CustomLoadingButton from "components/ui/CustomLoadingButton";
import { v4 as uuid } from "uuid";
import OrderSummary from "./OrderSummary";
import { snackbarOpen } from "store/snackbar/snackbarAction";

const applicationId = "sandbox-sq0idb-MrcMdTGKILvT4KTMb8xpWw";
const locationId = "LBVV3QKR4CFWH";

const Payment = () => {
  let paymentDetails = null;
  const [paymentType, setPaymentType] = useState("Card");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const paymentDatas = JSON.parse(localStorage.getItem("checkoutData"));

  const cardTokenizeResponseReceived = (tokenReceived) => {
    const price =
      deliveryCharge +
      paymentDatas?.subTotal +
      paymentDatas?.taxCharges -
      paymentDatas?.discount;
    const amount = Math.floor(price);
    const unique_id = uuid();

    makePayment({
      payload: {
        sourceId: tokenReceived?.token,
        idempotency_key: unique_id,
        amount,
        currency: "USD",
      },
    }).then(({ data }) => {
      paymentDetails = JSON.parse(data);
      handleSubmit();
    });
  };

  const handleChange = (e) => {
    setPaymentType(e.target.value);
  };
  const handleSubmit = () => {
    setLoading(true);
    const cartItems = JSON.parse(localStorage.getItem("cart"));
    const paymentData = JSON.parse(localStorage.getItem("checkoutData"));
    const addressID =
      parseInt(localStorage.getItem("my_address_id")) ||
      parseInt(localStorage.getItem("address_id"));
    const promoID = parseInt(localStorage.getItem("promoID"));
    const redeem_loyality = parseInt(
      localStorage.getItem("appliedLoyaltyPoints")
    );
    const order = {
      total_amount: paymentData?.subTotal,
      total_paid_amount:
        paymentData.deliveryCharge +
        paymentData?.subTotal +
        paymentData?.deliveryCharge +
        paymentData?.taxCharges -
        paymentData?.discount,
      total_save_amount: paymentData?.discount,
      payment_status: "COMPLETED",
      order_status: "PENDING",
      address_id: addressID,
      redeem_loyality: redeem_loyality,
      discount_id: promoID,
      discount_value: paymentData?.discount,
      delivery_charge: paymentData.deliveryCharge,
      delivery_distance: 1,
      tax_percentage: 13,
      payment_method: paymentType,
      payment_details: paymentDetails,
      tax_charge: paymentData?.taxCharges,
      items_data: cartItems?.map((item) => ({
        item_id: item.itemId,
        quantity: item.quantity,
        price: item.defaultPrice,
        size_price_id:item.size_price_id,
        releventItem: item.relevantItems ? item.relevantItems : [],
      })),
    };

    placeOrder({ payload: order })
      .then(({ data }) => {
        navigate(`/track-order/${data.id}`);
        localStorage.clear();
        dispatch(clearCart());
        setLoading(false);
        dispatch(
          snackbarOpen({
            open: true,
            message: "Order Placed Successfully !",
            type: SNACKBAR_MESSAGE_TYPE.success,
          })
        );
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while placing order !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
      });
  };

  return (
    <Box display={"flex"} flexDirection="column" width={"100%"}>
      <Box onClick={() => navigate("/cart")} sx={{ pointerEvents: "cursor" }}>
        <Typography variant="h6" color="#faa148">
          <Grid container alignItems="center">
            <ArrowBackIcon sx={{ mr: 1 }} /> Back to Cart
          </Grid>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ width: { md: "65%" } }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Card"
            onChange={handleChange}
          >
            <div className="checkout-cart-button">
              <FormControlLabel
                className="paymen-type-btn"
                value="Card"
                control={<Radio />}
                label="Card"
              />
              <FormControlLabel
                className="paymen-type-btn"
                value="COD"
                control={<Radio />}
                label="COD"
              />
            </div>
            {paymentType == "Card" ? (
              <Box
                mt={3}
                sx={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "0px",
                  mr: { md: 2 },
                  mb: { xs: 2 },
                  height: "fit-content",
                }}
                p={3}
              >
                <Typography variant="h6">
                  <Grid container alignItems="center">
                    <CheckCircleOutlineIcon
                      fontSize="large"
                      htmlColor="green"
                      sx={{ mr: 2 }}
                    />
                    Debit / Credit Card
                  </Grid>
                </Typography>
                <PaymentForm
                  applicationId={applicationId}
                  locationId={locationId}
                  cardTokenizeResponseReceived={cardTokenizeResponseReceived}
                >
                  <CreditCard />
                </PaymentForm>
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="place-order-btn"
                  onClick={handleSubmit}
                >
                  Place Order
                </Button>
              </>
            )}
          </RadioGroup>
        </Box>
        <Box mt={3} sx={{ width: { md: "35%" } }}>
          <OrderSummary />
        </Box>
      </Box>

      <CustomLoadingButton
        variant="contained"
        label="Confirm Order"
        sx={{
          bgcolor: "#faa148",
          height: "54px",
          // borderRadius: "50px",
          color: "#fff",
          mt: 4,
          display: { md: "none", xs: "block" },
        }}
        onClick={handleSubmit}
        loading={loading}
      />
    </Box>
  );
};

Payment.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  setOrderData: PropTypes.func,
};

export default Payment;
