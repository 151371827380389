import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../utils/api";
import { endPoints } from "../../utils/endPoints";
import {
  ADD_ADDRESS,
  GET_ADDRESS_LIST,
  DELETE_ADDRESS,
  GET_ADDRESS,
  UPDATE_ADDRESS,
  CLEAR_ADDRESS,
} from "../actionTypes";
import {
  addAddressSuccess,
  addAddressFailed,
  deleteAddressFailed,
  deleteAddressSuccess,
  updateAddressFailed,
  updateAddressSuccess,
  getAddressListSuccess,
  getAddressListFailed,
  getAddressSuccess,
  getAddressFailed,
  clearAddressSuccess,
} from "store/address/actions";

function* addAddress({ payload }) {
  try {
    const response = yield call(api.post, endPoints.ADD_ADDRESS, payload?.data);

    yield put(addAddressSuccess({ response, callback: payload?.callback }));
  } catch (error) {
    yield put(
      addAddressFailed({ error: error.response, callback: payload?.callback })
    );
  }
}

function* getAddressList({ callback }) {
  try {
    const response = yield call(api.get, endPoints.GET_ADDRESSES);

    yield put(getAddressListSuccess({ response: response.data, callback }));
  } catch (error) {
    yield put(getAddressListFailed({ error: error.response, callback }));
  }
}

function* deleteAddress({ payload }) {
  try {
    yield call(api.delete, endPoints.DELETE_ADDRESS, {
      urlParams: { id: payload?.id },
    });

    yield put(
      deleteAddressSuccess({ id: payload?.id, callback: payload?.callback })
    );
  } catch (error) {
    yield put(
      deleteAddressFailed({
        error: error.response,
        callback: payload?.callback,
      })
    );
  }
}

function* getAddress({ payload }) {
  try {
    const response = yield call(api.get, endPoints.GET_ADDRESS, {
      urlParams: { id: payload?.id },
    });

    yield put(
      getAddressSuccess({
        response: response?.data?.data,
        callback: payload?.callback,
      })
    );
  } catch (error) {
    yield put(
      getAddressFailed({
        error: error.response,
        callback: payload?.callback,
      })
    );
  }
}

function* updateAddress({ payload }) {
  try {
    yield call(api.put, endPoints.UPDATE_ADDRESS, payload.data, {
      urlParams: { id: payload.id },
    });

    yield put(
      updateAddressSuccess({
        payload: { ...payload.data, id: parseInt(payload.id) },
        callback: payload?.callback,
      })
    );
  } catch (error) {
    yield put(
      updateAddressFailed({
        error: error.response,
        callback: payload.callback,
      })
    );
  }
}

function* clearAddress() {
  yield put(clearAddressSuccess());
}

function* AddressSaga() {
  yield takeLatest(ADD_ADDRESS, addAddress);
  yield takeLatest(GET_ADDRESS_LIST, getAddressList);
  yield takeLatest(DELETE_ADDRESS, deleteAddress);
  yield takeLatest(GET_ADDRESS, getAddress);
  yield takeLatest(UPDATE_ADDRESS, updateAddress);
  yield takeLatest(UPDATE_ADDRESS, updateAddress);
  yield takeLatest(CLEAR_ADDRESS, clearAddress);
}

export default AddressSaga;
