import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const getDiscounts = async () => {
  try {
    const response = await api.get(endPoints.GET_DISCOUNTS);

    return response.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};

export const verifyPromoCode = async ({ payload }) => {
  try {
    const response = await api.post(endPoints.VERIFY_PROMO, payload);
    return response.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw data;
  }
};

export const getPromoByID = async ({ id }) => {
  try {
    const response = await api.get(endPoints.GET_PROMO_BY_ID, {
      urlParams: { id: id },
    });

    return response.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};

export const getbestdiscountofday = async () => {
  try {
    const response = await api.get(endPoints.GET_BEST_DISCOUNT_OF_THE_DAY);
    return response.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};

export const getDefaultPromoCode = async ({ total_amount }) => {
  try {
    const response = await api.post(endPoints.GET_DEFAULT_PROMOCODE, {
      total_amount,
    });
    return response.data;
  } catch (error) {
    console.log("🚀 ~ getDefaultPromoCode ~ error:", error);
    // throw data
  }
};
