import {
  CardMedia,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IMAGE_URL } from "utils/api";
import DefaultFoodImg from "../../assets/images/default-food-img.png";
import { getItemsByCat } from "services/ItemsService";

export const SummaryDetails = ({ orderList }) => {
  const [state, setState] = useState([]);
  useEffect(() => {
    Promise.all([getItemsByCat()]).then((data) => {
      setState(...data);
    });
  }, []);
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
      className="summaryDetails"
    >
      <Table aria-label="customized table" className="summaryTable">
        <TableHead style={{ background: "#faa148" }}>
          <TableRow
            sx={{
              backgroundColor: "#faa148",
              color: "#000",
            }}
          >
            <TableCell
              sx={{
                borderRadius: "0px",
                color: "#000",
                textAlign: "center",
              }}
            />

            <TableCell
              sx={{ color: "#000", textAlign: "center", fontWeight: 900, fontSize: 17}}
            >
              Product
            </TableCell>
            <TableCell
              sx={{ color: "#000", textAlign: "center", fontWeight: 900, fontSize: 17 }}
            >
              Selected Items
            </TableCell>
            <TableCell
              sx={{ color: "#000", textAlign: "center", fontWeight: 900, fontSize: 17 }}
            >
              Quantity
            </TableCell>
            <TableCell
              sx={{ color: "#000", textAlign: "center", fontWeight: 900, fontSize: 17 }}
            >
              Price
            </TableCell>
            <TableCell
              sx={{
                // borderRadius: "0 50px 50px 0",
                color: "#000",
                textAlign: "center",
                fontWeight: 900, 
                fontSize: 17
              }}
            >
              Subtotal
            </TableCell>
            <TableCell
              sx={{
                borderRadius: "0px",
                color: "#000",
                textAlign: "center",
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {orderList?.OrderDetails?.map((row, index) => {
            const parsedImage = JSON.parse(row?.Item?.images || "[]");
            const image = `${IMAGE_URL}/items/${parsedImage?.[0]?.filename}`;
            return (
              <TableRow key={index} sx={{ border: "0px 1px" }}>
                <TableCell sx={{ textAlign: "right" }}>
                  <CardMedia
                    sx={{
                      display: "inline",
                      width: "50px",
                      height: "50px",
                    }}
                    alt="image"
                    src={parsedImage?.[0]?.filename ? image : DefaultFoodImg}
                    onError={(e) => {
                      e.target.src = DefaultFoodImg;
                    }}
                    component="img"
                  />
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography variant="body1">{row?.Item?.name}</Typography>
                </TableCell>
                <TableCell
                  sx={{ textAlign: "center" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Typography variant="body1">
                    {row?.selectedItems &&
                    row?.selectedItems?.length > 0 &&
                    row?.selectedItems[1].length > 0
                      ? state
                          ?.filter((item) =>
                            row?.selectedItems[1]?.includes(item?.id),
                          )
                          .map(
                            (item, index) =>
                              `${index != 0 ? ", " : ""}${item.name}`,
                          )
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "center !important" }}>
                  {row?.quantity}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ${row?.price}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ${(row?.quantity * row?.price).toFixed(2)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Table
        className="summaryCharge"
        sx={{
          backgroundColor: "#f2f2f2",
          width: { xs: "100%", md: "300px" },
          borderRadius: "7px",
          padding: 2,
        }}
      >
        <div className="billingTitle">Billing Summary</div>
        <tbody>
          <TableRow sx={{ border: "0px 1px" }}>
            <TableCell
              sx={{ textAlign: "center" }}
              display="flex"
              alignItems="center"
              className="summaryText"
              // justifyContent="flex-start"
            >
              Total Amount
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="summaryText">
              ${orderList?.total_amount?.toFixed(2)}
            </TableCell>
          </TableRow>

          <TableRow sx={{ border: "0px 1px" }}>
            <TableCell
              sx={{ textAlign: "center" }}
              display="flex"
              alignItems="center"
              className="summaryText"
              // justifyContent="flex-start"
            >
              Discount Amount
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="summaryText">
              ${orderList?.discount_value}
            </TableCell>
          </TableRow>

          <TableRow sx={{ border: "0px 1px" }}>
            <TableCell
              sx={{ textAlign: "center" }}
              display="flex"
              alignItems="center"
              className="summaryText"
              // justifyContent="flex-start"
            >
              Delivery Charges
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="summaryText">
              ${orderList?.delivery_charge}
            </TableCell>
          </TableRow>

          <TableRow sx={{ border: "0px 1px" }}>
            <TableCell
              sx={{ textAlign: "center" }}
              display="flex"
              alignItems="center"
              className="summaryText"
              // justifyContent="flex-start"
            >
              Tax Charges
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="summaryText">
              $ {orderList?.tax_charge}
            </TableCell>
          </TableRow>
          <TableRow sx={{ border: "0px 1px", background: "#faa148" }}>
            <TableCell
              sx={{ textAlign: "center" }}
              display="flex"
              alignItems="center"
              className="summaryText"
              // justifyContent="flex-start"
            >
              Total Paid Amount
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="summaryText">
              $ {orderList?.total_paid_amount}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </TableContainer>
  );
};

SummaryDetails.propTypes = {
  orderList: PropTypes.any,
};
