import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  GOOGLE_LOGIN_VERIFICATION_SUCCESS,
  GOOGLE_LOGIN_VERIFICATION,
  GOOGLE_LOGIN_VERIFICATION_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  REQ_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  REQ_VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  REQ_RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILED,
  RESET_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
  PASSWORD_FORGOT,
  PASSWORD_FORGOT_SUCCESS,
  PASSWORD_FORGOT_FAILED,
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  // UPDATE_USER,
  // UPDATE_USER_SUCCESS,
  // UPDATE_USER_FAILED,
  FACEBOOK_LOGIN_VERIFICATION_SUCCESS,
  FACEBOOK_LOGIN_VERIFICATION,
  FACEBOOK_LOGIN_VERIFICATION_FAILED,
} from "../actionTypes";

export const loginUser = (data) => {
  return {
    type: LOGIN_USER,
    payload: data,
  };
};

export const loginUserSuccess = (data) => {
  localStorage.setItem("isLoggedIn", true)
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data,
  };
};

export const loginUserFailed = (data) => {
  data.callback(data?.error?.data);
  return {
    type: LOGIN_USER_FAILED,
    payload: data,
  };
};

export const getProfile = (data) => {
  return {
    type: GET_PROFILE,
    payload: data,
  };
};

export const getProfileSuccess = (data) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: data,
  };
};

export const getProfileFailed = (data) => {
  return {
    type: GET_PROFILE_FAILED,
    payload: data,
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};

export const logoutUserSuccess = (data) => {
  localStorage.setItem("isLoggedIn", false)
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: data,
  };
};

export const logoutUserFailed = (data) => {
  data.callback(data?.error?.data);
  return {
    type: LOGOUT_USER_FAILED,
    payload: data,
  };
};

// Google Varification
export const googleLoginVerification = (data) => {
  return {
    type: GOOGLE_LOGIN_VERIFICATION,
    payload: data,
  };
};

export const googleLoginVerificationSuccess = (data) => {
  return {
    type: GOOGLE_LOGIN_VERIFICATION_SUCCESS,
    payload: data,
  };
};

export const googleLoginVerificationFailed = (data) => {
  return {
    type: GOOGLE_LOGIN_VERIFICATION_FAILED,
    payload: data,
  };
};

// REGISTER

export const register = (data) => {
  return {
    type: REQ_REGISTER,
    payload: data,
  };
};

export const registerSuccess = (data) => {
  data.callback({ error: false, message: data.response.message });
  return {
    type: REGISTER_SUCCESS,
    payload: data.response,
  };
};

export const registerFailed = (data) => {
  data.callback({ error: true, message: data.error.data.message });
  return {
    type: REGISTER_FAILED,
    payload: data.error.data,
  };
};

// OTP VERIFICATION

export const verifyOTP = (data) => {
  return {
    type: REQ_VERIFY_OTP,
    payload: data,
  };
};

export const verifyOTPSuccess = (data) => {
  data.callback({ error: false, message: data.response.message });
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data.data,
  };
};

export const verifyOTPFailed = (data) => {
  data.callback({ error: true, message: data?.error?.data?.message });
  return {
    type: VERIFY_OTP_FAILED,
    payload: data.error.data,
  };
};

export const resendOTP = (data) => {
  return {
    type: REQ_RESEND_OTP,
    payload: data,
  };
};

export const resendOTPSuccess = (data) => {
  data?.callback({ error: false, message: data.response.message });
  return {
    type: RESEND_OTP_SUCCESS,
    payload: data.response.data,
  };
};

export const resendOTPFailed = (data) => {
  data.callback({ error: true, message: data?.error?.message });
  return {
    type: RESEND_OTP_FAILED,
    payload: data.error.data,
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};

//password
export const passwordForgot = (data) => {
  return {
    type: PASSWORD_FORGOT,
    payload: data,
  };
};

export const passwordForgotSuccess = (data) => {
  data.callback({ error: false, data: data?.response?.data?.email });
  return {
    type: PASSWORD_FORGOT_SUCCESS,
    payload: data.response?.data?.data?.email,
  };
};

export const passwordForgotFailed = (data) => {
  data.callback({ error: true, message: data?.error?.data?.message });
  return {
    type: PASSWORD_FORGOT_FAILED,
    payload: data.error.data,
  };
};

export const confirmPassword = (data) => {
  return {
    type: CONFIRM_PASSWORD,
    payload: data,
  };
};

export const confirmPasswordSuccess = ({ response, callback }) => {
  callback({ error: false, message: response });
  return {
    type: CONFIRM_PASSWORD_SUCCESS,
    payload: response,
  };
};

export const confirmPasswordFailed = ({ callback, error }) => {
  callback({ error: true, message: error?.message });
  return {
    type: CONFIRM_PASSWORD_FAILED,
    payload: error.message,
  };
};

export const changePassword = (data) => {
  return {
    type: CHANGE_PASSWORD,
    payload: data,
  };
};

export const changePasswordSuccess = ({ callback, response }) => {
  callback({ error: false, message: response?.message });
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: response.data,
  };
};

export const changePasswordFailed = (data) => {
  data.callback({ error: true, message: data?.error?.data?.message });
  return {
    type: CHANGE_PASSWORD_FAILED,
    payload: data.error.data,
  };
};

//update user

// export const updateUser = (data) => {
//   return {
//     type: UPDATE_USER,
//     payload: data,
//   };
// };

// export const updateUserSuccess = (data) => {
//   data?.callback({ error: false });
//   return {
//     type: UPDATE_USER_SUCCESS,
//     payload: data,
//   };
// };

// export const updateUserFailed = (data) => {
//   data?.callback({ error: true });
//   return {
//     type: UPDATE_USER_FAILED,
//     payload: data,
//   };
// };

//facebook Login

export const facebookLoginVerification = (data) => {
  return {
    type: FACEBOOK_LOGIN_VERIFICATION,
    payload: data,
  };
};

export const facebookLoginVerificationSuccess = (data) => {
  return {
    type: FACEBOOK_LOGIN_VERIFICATION_SUCCESS,
    payload: data,
  };
};

export const facebookLoginVerificationFailed = (data) => {
  return {
    type: FACEBOOK_LOGIN_VERIFICATION_FAILED,
    payload: data,
  };
};
