import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import DataTable from "./components/DataTable";
import HelpCard from "./components/HelpCard";
import AppCard from "./components/AppCard";
import NewsletterCard from "./components/NewsletterCard";
import CustomButton from "components/ui/CustomButton";

const Profile = () => {
  return (
    <PageWrapper>
      <Typography variant="h3">My Profile</Typography>
      <Box sx={{ mt: 2 }}>
        <CustomButton
          label="My Orders"
          variant="outlined"
          sx={{ borderRadius: "30px", mr: 1, py: 1, border: "3px solid " }}
        />

        <CustomButton
          label="My Info"
          variant="outlined"
          sx={{ borderRadius: "30px", py: 1, border: "3px solid " }}
        />
      </Box>
      <Grid container align="center" mt={4} justifyContent="center">
        <Grid item md={8} xs={12} sx={{ width: "100%" }}>
          <Grid container align="left" justifyContent="left">
            <Grid item xs={3}>
              <Typography variant="h6" sx={{ color: "#d3d3d3" }}>
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" sx={{ color: "#d3d3d3" }}>
                Delivery Address
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" sx={{ color: "#d3d3d3" }}>
                Amount
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" sx={{ color: "#d3d3d3" }}>
                Status
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={4} xs={12}></Grid>
        <Grid item md={12} mt={2}>
          <Divider />
        </Grid>
        <Grid item md={9} xs={12} mt={3}>
          <DataTable />
        </Grid>
        <Grid item md={3} xs={12} mt={3} px={4}>
          <HelpCard />
          <AppCard />
          <NewsletterCard />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default Profile;
