import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const getBanner = async () => {
  try {
    const response = await api.get(endPoints.GET_BANNER);
    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};