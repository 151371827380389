import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Grid,
  InputAdornment,
  IconButton,
  styled,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { resetError, changePassword } from "../../store/auth/actions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PageWrapper from "components/wrapper/PageWrapper";
import { useNavigate } from "react-router-dom";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import CustomLoadingButton from "components/ui/CustomLoadingButton";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    width: "100%",
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
}));

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  currentPassword: Yup.string()
    .required("Please enter your Current password")
    .min(8)
    .label("Old Password"),
  newPassword: Yup.string()
    .label("New Password")
    .required("Please enter your new password")
    .when("currentPassword", (currentPassword, schema) => {
      return schema.test({
        test: (newPassword) => newPassword !== currentPassword,
        message: "New Password must be different",
      });
    }),
  confirmPassword: Yup.string()
    .label("Confirm Password")
    .required("Please enter your confirm password")
    .when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Passwords does not match"
      ),
    }),
});

const ChangePassword = () => {
  // const hasError = useSelector((state) => state?.AuthReducer?.error);

  const [initFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const onSubmit = (formData) => {
    setLoading(true);
    const userData = {
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
      confirm_password: formData.confirmPassword,
    };

    dispatch(
      changePassword({
        data: userData,
        callback: ({ error, message }) => {
          setLoading(false);
          if (!error) {
            navigate("/user-account");
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.success,
              })
            );
          } else {
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.error,
              })
            );
          }
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    formik;

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <PageWrapper>
      <Grid item className="change-password">
        <Typography
          component="h1"
          variant="h5"
          sx={{
            margin: "auto",
            display: "flex",
            width: "70%",
            marginLeft: "12px",
          }}
        >
          Change Password
        </Typography>
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit} className="forgot-passoord">
            <Grid container>
              <Grid item md={12} pr={1} className="change-container-inner">
                <Box sx={{ width: "100%", margin: "auto" }}>
                  <CustomTextField
                    id="outlined-end-adornment"
                    name="currentPassword"
                    label="Current Password"
                    type={showCurrentPassword ? "text" : "password"}
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="current-password"
                    value={values.currentPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.currentPassword && touched.currentPassword}
                    helperText={
                      errors.currentPassword && touched.currentPassword
                        ? errors.currentPassword
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showCurrentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box sx={{ width: "100%", margin: "auto" }}>
                  <CustomTextField
                    id="outlined-end-adornment"
                    name="newPassword"
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="current-password"
                    value={values.newPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.newPassword && touched.newPassword}
                    helperText={
                      errors.newPassword && touched.newPassword
                        ? errors.newPassword
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", margin: "auto" }}>
                  <CustomTextField
                    id="outlined-end-adornment"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="current-password"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      errors.confirmPassword && touched.confirmPassword
                        ? errors.confirmPassword
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CustomLoadingButton
                    variant="contained"
                    label="Submit"
                    loading={loading}
                    type="submit"
                    onClick={handleSubmit}
                    sx={{
                      my: 2,
                      borderRadius: 1,
                      py: 1,
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    className="sub-mit"
                  />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>
    </PageWrapper>
  );
};

export default ChangePassword;
