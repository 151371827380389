import React from "react";
import { Avatar, Button } from "@mui/material";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "../../assets/images/icons/facebook.svg";
import { facebookLoginVerification } from "store/auth/actions";
import { useDispatch } from "react-redux";

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

export const FacebookAuth = () => {
  const dispatch = useDispatch();

  const responseFacebook = (tokenResponse) => {
    dispatch(
      facebookLoginVerification({
        access_token: tokenResponse.accessToken,
      })
    );
  };
  return (
    <>
    <FacebookLogin
      appId={appId}
      autoLoad={false}
      fields="name,email,picture,birthday"
      scope="public_profile,email"
      callback={responseFacebook}
      render={(renderProps) => (
        <Button
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "white",
            boxShadow: "none",
            borderRadius: "30px",
            width: "fit-content",
            mr: "auto",
            ml: "auto",
          }}
          startIcon={<Avatar src={FacebookIcon} />}
          onClick={renderProps.onClick}
        >
          Facebook
        </Button>
      )}
    />
    
    </>
  );
};

export default FacebookAuth;
