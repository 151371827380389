import React, { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import CustomCard from "components/ui/CustomCard";
import { getCategories } from "services/ItemsService";
import { IMAGE_URL } from "utils/api";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router";
import CustomButton from "components/ui/CustomButton";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { useDispatch } from "react-redux";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardDoubleArrowRightIcon
      color="primary"
      className={className}
      sx={{
        width: "6rem",
        fontSize: "3rem",
        top: "90%",
        "&:hover": { color: "#000" },
      }}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardDoubleArrowLeftIcon
      color="primary"
      className={className}
      sx={{
        width: "6rem",
        fontSize: "3rem",
        zIndex: 9,
        top: "90%",
        "&:hover": { color: "#000" },
      }}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

var settings = {
  dots: false,
  infinite: true,
  adaptiveHeight: true,
  speed: 2000,
  slidesToShow: 8,
  slidesToScroll: 4,
  lazyLoad: true,
  autoplay: true,
  autoplaySpeed: 4000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const CategorySection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [categoryList, setCategoryList] = useState([]);
  // const apiCall = useRef(false);

  const getCategoryList = () => {
    // if (!apiCall.current) {
    getCategories()
      .then((res) => {
        const featuredCategories = res?.filter(({ feature }) => feature === 1);
        setCategoryList(featuredCategories);
        settings.slidesToShow = featuredCategories?.length > 10 ? 8 : 6;
      })
      .catch((err) => {
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching food categories !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
        return err;
      });
    // }
    // apiCall.current = true;
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    
    <Box sx={{ justifyContent: "center"}} className="common-header food-ctegories p-80">
      
      <Container maxWidth="xl">
      <Box sx={{ padding: 4, textAlign: "center" }}>   
        <Typography variant="h3"> FEATURED CATEGORIES</Typography>
        <Typography variant="h6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        </Typography>
      </Box>
      <Slider {...settings}>
        {categoryList?.length &&
          categoryList?.map(({ id, name, image }) => (
            <Box
              key={id}
              sx={{
                display: "flex !important",
                justifyContent: "center",
              }}
            >
              <CustomCard
                image={`${IMAGE_URL}/category/${image}`}
                title={name}
                onClick={() => navigate(`/menu/${id}`)}
                isCategory={true}
                className="feature-slider-card"
              />
            </Box>
          ))}
      </Slider>
      <Box sx={{ padding: 4, textAlign: "center" }}>
        <CustomButton
          label="See all categories"
          variant="contained"
          sx={{ color: "#fff", boxShadow: "none" }}
          onClick={() => navigate("/categories")}
          className="see-all-cat-btn"
        />
      </Box>
      </Container>
    </Box>
   
  );
};

export default CategorySection;
