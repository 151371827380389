import React from "react";
import { Typography } from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import ManageLoyalityPoint from "./components/ManageLoyalityPoint";

const LoyalityPoint = () => {
  return (
    <PageWrapper>
      <Typography
        variant="h4"
        sx={{ justifyContent: "center", display: "flex", marginTop: "15px" }}
      >
        Loyality Points
      </Typography>
      <ManageLoyalityPoint />
    </PageWrapper>
  );
};

export default LoyalityPoint;
