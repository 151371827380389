import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  CardMedia,
  ButtonGroup,
  Box,
  FormLabel,
} from "@mui/material";
import PageWrapper from "../../components/wrapper/PageWrapper";
import { getPackageItem } from "services/ItemsService";
import { addItemToCart, addItemToCartWithSize } from "utils/core-utils";
import CartBar from "../../pages/menu/components/CartBar";
import { addToCart } from "store/cart/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "components/ui/CustomButton";
import { useNavigate, useParams } from "react-router";
import { IMAGE_URL } from "utils/api";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import DefaultFoodImg from "../../assets/images/default-food-img.png";
import CustomizedDialog from "./Dialog";

const Item = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalPriceOfpakage, setTotalPriceOfpakage] = useState(0);
  const [selectedItem] = useState(0);
  const [selectedOptinalItem, setSelectedOptionItem] = useState([]);
  const [sizedialogOpen, setSizeDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [promptItem, setPromtItem] = useState([]);
  const isLoggedIn = localStorage.getItem("isLoggedIn") || "false";
  const cart = useSelector((state) => state?.CartReducer?.cart);
  const [item, setItem] = useState({});
  const [itemSizeValue, setItemSizeValue] = useState([]);

  const totalQuantity = cart?.find(
    ({ itemId }) => itemId === item?.packageDetails?.id
  )?.quantity;

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  useEffect(() => {
    getPackageItem(id)
      .then((res) => {
        setItem(res);
      })
      .catch(() => {
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching item data !",
            type: SNACKBAR_MESSAGE_TYPE.warning,
          })
        );
        navigate("/");
      });
  }, [id]);

  useEffect(() => {
    const updateQty = {
      ...itemSizeValue,
      ItemSizePrices: itemSizeValue?.ItemSizePrices?.map((items) => {
        const carFind = cart?.find((item) => {
          return item.itemId === items.item_id &&
            item.size_price_id === items.size_id
            ? item
            : "";
        });
        if (
          carFind?.size_price_id === items.size_id &&
          carFind.itemId === items.item_id
        ) {
          return { ...items, quantity: carFind.quantity };
        }
        return items;
      }),
    };
    setItemSizeValue(updateQty);
  }, [cart, sizedialogOpen,dialogOpen]);

  const images = JSON.parse(item?.packageDetails?.images || "{}");
  const itemImage = images?.length && images[0]?.filename;

  const handleCartClick = ({
    type = "inc",
    size,
    defaultPrice,
    itemId,
    size_price_id,
    relevantItems,
    ItemSizePrices,
  }) => {
    const itemData = item?.packageDetails;
    const relevantItemsData = item?.relevantItems

    const cartItems = [...cart];
    if (relevantItems?.length) {
      setPromtItem({
        id: itemData?.id,
        price: itemData?.price,
        itemName: itemData?.name,
        itemDescription: itemData?.description,
        relItems:relevantItemsData,
        ItemSizePrices: itemData?.ItemSizePrices,
      });
      setItemSizeValue({
        id: itemData?.id,
        price: itemData?.price,
        itemName: itemData?.name,
        itemDescription: itemData?.description,
        relItems:relevantItemsData,
        ItemSizePrices: itemData?.ItemSizePrices,
      });
      return setDialogOpen(true);
    }
     else
      if (ItemSizePrices?.length) {
      setItemSizeValue({
        id: itemData?.id,
        price: itemData?.price,
        itemName: itemData?.name,
        itemDescription: itemData?.description,
        relItems: relevantItemsData,
        ItemSizePrices: itemData?.ItemSizePrices,
      });
      setPromtItem({
        id: itemData?.id,
        price: itemData?.price,
        itemName: itemData?.name,
        itemDescription: itemData?.description,
        relItems: relevantItemsData,
        ItemSizePrices: itemData?.ItemSizePrices,
      });
      return setSizeDialogOpen(true);
    }
    if (size) {
      const addedItems = addItemToCartWithSize(
        cartItems,
        itemId,
        size,
        defaultPrice,
        type,
        size_price_id
      );
      const addDetails = addedItems?.map((items) => {
        if (itemData.id === items?.itemId) {
          return {
            ...items,
            itemName: itemData?.name,
            itemDescription: itemData?.description,
            is_package: 0,
            itemImage: itemData?.images,
          };
        }
        return items
      });

      dispatch(
        addToCart({
          data: addDetails
            .map((ele) => {
              if (ele.itemId == itemId) {
                return ele;
              }
            })
            .filter((ele) => ele != undefined),
          isLoggedIn: isLoggedIn,
          isCart: true,
        })
      );
    } else {
   
      const addedItems = addItemToCart(
        cartItems, itemId, defaultPrice, type
      );
      const addDetails = addedItems?.map((item) => {
        const findItem = relevantItemsData?.find(
          (current) => item?.itemId === current?.relevant_item?.id
        );
        return {
          ...item,
          itemName: findItem?.relevant_item?.name,
          itemDescription: findItem?.relevant_item?.description,
          is_package: 0,
          itemImage: findItem?.relevant_item?.images,
        };
      });
        dispatch(
        addToCart({
          data: addDetails
            .map((ele) => {
              if (ele.itemId == itemId) {
                return ele;
              }
            })
            .filter((ele) => ele != undefined),
          isLoggedIn: isLoggedIn,
          isCart: true,
        })
      );
    }
  };

  useEffect(() => {
    let totalPrice = 0;
    if (selectedOptinalItem?.length == 0) {
      if (item?.packageDetails?.is_package !== 0) {
        item?.relevantItems?.map((ele, index) => {
          if (index != 0) {
            ele?.map((item, itemIndex) => {
              if (item?.price != null) {
                let price = itemIndex == 0 ? item?.price : 0;
                totalPrice = totalPrice + price;
              }
            });
          }
        });
        setTotalPriceOfpakage(totalPrice);
      }
    } else {
      let initialItems = item?.relevantItems
        ?.map((ele, index) => {
          if (index != 0) {
            return {
              groupId: index,
              itemDetails: ele[0],
            };
          } else {
            return {
              groupId: index,
              itemDetails: ele,
            };
          }
        })
        .filter((item) => item !== undefined);
      let joinArr = [];
      if (selectedOptinalItem?.length == 0) {
        joinArr.push(initialItems);
      } else {
        let newArr = [];
        let selectedGroupId = selectedOptinalItem?.map((item) => item.groupId);

        initialItems?.forEach((item) => {
          if (!selectedGroupId.includes(item.groupId)) {
            newArr.push(item);
          }
        });

        selectedOptinalItem?.forEach((item) => {
          if (!initialItems?.find((obj) => obj.groupId === item.groupId)) {
            newArr.push(item);
          }
        });
        if (newArr?.length > 0) {
          joinArr = [...selectedOptinalItem, ...newArr];
        }
      }

      let newArr = selectedOptinalItem?.length > 0 ? joinArr : joinArr[0];
      newArr?.filter((ele) => {
        if (ele.groupId != 0 && ele.itemDetails.price != null) {
          totalPrice = totalPrice + ele.itemDetails.price;
        }
      });
      setTotalPriceOfpakage(totalPrice);
    }
  }, [item, selectedItem, selectedOptinalItem]);

  const handleChangeForRelevantRadioBtn = (itemDetails, groupId) => {
    if (selectedOptinalItem?.length > 0) {
      const index = selectedOptinalItem.findIndex(
        (item) => item.groupId === groupId
      );
      if (index !== -1) {
        selectedOptinalItem.splice(index, 1);
      }
    }
    setSelectedOptionItem((prevState) => [
      ...prevState,
      {
        itemDetails,
        groupId,
      },
    ]);
  };

  const handleCardClickForPackage = (item) => {
    let initialItems = item?.relevantItems
      ?.map((ele, index) => {
        if (index != 0) {
          return {
            groupId: index,
            itemDetails: ele[0],
          };
        } else {
          return {
            groupId: index,
            itemDetails: ele,
          };
        }
      })
      .filter((item) => item !== undefined);
    let joinArr = [];
    if (selectedOptinalItem?.length == 0) {
      joinArr.push(initialItems);
      setSelectedOptionItem(initialItems);
    } else {
      let newArr = [];
      let selectedGroupId = selectedOptinalItem?.map((item) => item.groupId);

      initialItems?.forEach((item) => {
        if (!selectedGroupId.includes(item.groupId)) {
          newArr.push(item);
        }
      });

      selectedOptinalItem?.forEach((elem) => {
        if (!initialItems?.find((obj) => obj.groupId === elem.groupId)) {
          newArr.push(elem);
        }
      });
      if (newArr?.length > 0) {
        joinArr = [...selectedOptinalItem, ...newArr];
      }
      setSelectedOptionItem(...joinArr);
    }

    item.relItems = joinArr;
    const cartItems = [...cart];
    const addedItems = addItemToCart(cartItems, item.id, item.price, item.type);
    let newArr = selectedOptinalItem?.length > 0 ? joinArr : joinArr[0];
    let relevant_item_ids = [[], []];
    for (let item of newArr) {
      if (Array.isArray(item.itemDetails)) {
        for (let detail of item.itemDetails) {
          relevant_item_ids[0].push(detail.id);
        }
      } else {
        relevant_item_ids[1].push(item.itemDetails.id);
      }
    }

    const addDetails = addedItems?.map(() => {
      return {
        ...item?.packageDetails,
        relevantItems: relevant_item_ids,
        quantity: 1,
      };
    });

    let add_to_package_details = addDetails
      .map((ele) => {
        if (ele.itemId == item.id) {
          return ele;
        }
      })
      .filter((ele) => ele != undefined);
    let finalData = add_to_package_details.map((finalItem) => {
      return {
        defaultPrice: finalItem.price + totalPriceOfpakage,
        is_package: 1,
        itemDescription: finalItem.description,
        itemId: finalItem.id,
        itemImage: finalItem.images,
        itemName: finalItem.name,
        quantity: 1,
        relevantItems: add_to_package_details[0].relevantItems,
      };
    });
    dispatch(
      addToCart({
        data: finalData && finalData.length > 0 ? [finalData[0]] : [],
        isLoggedIn: isLoggedIn,
      })
    );
    setSelectedOptionItem([]);
  };

  return (
    <PageWrapper className="item-detail-section">
      <Grid container className="item-detail-section-container">
        <Grid
          item
          xs={12}
          md={6}
          mt={2}
          className="item-detail-section-img-container"
        >
          <CardMedia
            component={"img"}
            src={itemImage ? `${IMAGE_URL}/items/${itemImage}` : DefaultFoodImg}
            onError={(e) => {
              e.target.src = DefaultFoodImg;
            }}
            sx={{
              width: "50%",
              borderRadius: "15px",
              aspectRatio: "1/1",
              mx: "auto",
            }}
          />

          <CartBar cartData={cart} />
        </Grid>
        <Grid item xs={12} md={6} mt={2} className="item-categories-detail">
          <Typography mt={2} sx={{ fontWeight: "bold", fontSize: "25px" }}>
            {item?.packageDetails?.name}
          </Typography>
          <Typography mt={2} className="item-categories-desc">
            {item?.packageDetails?.description}
          </Typography>
          <Typography
            mt={2}
            sx={{ fontWeight: "bold", fontSize: "30px" }}
            className="item-categories-price"
          >
            Price: ${item?.packageDetails?.price + totalPriceOfpakage}
          </Typography>
        </Grid>
        {console.log("ele", item)}

        {item?.relevantItems?.length > 0 &&
          item?.packageDetails?.is_package !== 0 && (
            <div className="mainDiv">
              {item?.relevantItems?.map((ele, index) => (
                <>
                  <div key={index} className="mainCard">
                    {index == 0 ? (
                      <>
                        <h4>Fixed Item</h4>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="foot_dish_s">
                      {ele?.map((itemElement, innerIndex) => (
                        <>
                          {innerIndex == 0 && index != 0 && (
                            <h4>{itemElement.package_name}</h4>
                          )}
                          {index == 0 ? (
                            <div className="fds_inner">
                              <img
                                className="productImg"
                                src={`${IMAGE_URL}/items/${
                                  JSON.parse(itemElement?.images || "{}")[0]
                                    ?.filename
                                }`}
                                alt=""
                                onError={(e) => {
                                  e.target.src = DefaultFoodImg;
                                }}
                              />
                              <div>{itemElement.name}</div>
                            </div>
                          ) : (
                            <div className="fds_inner">
                              <div className="radioBox">
                                <input
                                  type="radio"
                                  name={index}
                                  value={itemElement.id}
                                  id={itemElement.id}
                                  defaultChecked={ele[0].id == itemElement.id}
                                  onClick={() =>
                                    handleChangeForRelevantRadioBtn(
                                      itemElement,
                                      index
                                    )
                                  }
                                />
                                <label htmlFor={itemElement.id}>
                                  <img
                                    className="productImg"
                                    src={`${IMAGE_URL}/items/${
                                      JSON.parse(itemElement?.images || "{}")[0]
                                        ?.filename
                                    }`}
                                    alt=""
                                    onError={(e) => {
                                      e.target.src = DefaultFoodImg;
                                    }}
                                  />
                                </label>
                              </div>
                              <div className="itemName">{itemElement.name}</div>
                              <div className="itemName item_price">
                                <p> $ {itemElement.price} </p>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

        <Grid xs={12} md={12} mt={2} className="addToCartBtn">
          {totalQuantity &&
          item?.packageDetails?.is_package == 0 &&
          item?.packageDetails?.ItemSizePrices?.length === 0 ? (
            <ButtonGroup
              variant="contained"
              sx={{
                backgroundColor: "white",
                borderRadius: "15px 15px 15px 15px",
              }}
            >
              <CustomButton
                label="-"
                sx={{
                  borderRadius: "0 15px 15px 0",
                  backgroundColor: "white",
                }}
                onClick={() =>
                  handleCartClick({
                    itemId: item?.packageDetails?.id,
                    defaultPrice: item?.packageDetails?.price,
                    type: "dcr",
                  })
                }
                textColor="black"
              />
              <CustomButton
                label={totalQuantity}
                sx={{
                  backgroundColor: "white",
                }}
                textColor="black"
              />

              <CustomButton
                label="+"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "15px 0px 0px 15px",
                  "& :hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => {
                  handleCartClick({
                    itemId: item?.packageDetails?.id,
                    defaultPrice: item?.packageDetails?.price,
                    type: "inc",
                  });
                }}
                textColor="black"
              />
            </ButtonGroup>
          ) : (
            <CustomButton
              label="Add to Cart"
              variant="contained"
              size="medium"
              onClick={() => {
                item?.packageDetails?.is_package == 1
                  ? handleCardClickForPackage(item)
                  : handleCartClick({
                      itemId: item?.packageDetails?.id,
                      ItemSizePrices: item?.packageDetails?.ItemSizePrices,
                      relevantItems: item.relevantItems,
                      defaultPrice: item?.packageDetails?.price,
                      type: "inc",
                    });
              }}
              sx={{
                borderRadius: "15px",
                boxShadow: "none",
                backgroundColor: "primary",
                color: "#fff",
                whiteSpace: "nowrap",
                minWidth: 10,
              }}
            />
          )}
        </Grid>
        <Grid />
      </Grid>
      <CustomizedDialog
        handleClose={handleDialogClose}
        open={dialogOpen}
        onClick={() => {}}
        onCancel={handleDialogClose}
        contentStyle={{ maxWidth: "700px" }}
        className="item-detail-section"
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            alignItems: "center",
            mb: "10px",
            padding: "10px",
            color: "#fff",
          }}
          className="custom-dialog-header"
        >
          <Typography variant="h6">{promptItem?.itemName}</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            alignItems: "center",
          }}
          className="dialog-items-details"
        >
          <Box ml={2}>
            <Typography variant="h6" style={{ fontSize: "15px" }}>
              Price: ${promptItem?.price + totalPriceOfpakage}
            </Typography>

            <Typography variant="h6" style={{ fontSize: "15px" }}>
              Description : {promptItem?.itemDescription}
            </Typography>
          </Box>
          <Box>
            {promptItem?.ItemSizePrices?.length === 0 && (
              <Box p={1}>
                {totalQuantity && item.is_package == 0 ? (
                  <ButtonGroup
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px 15px 15px 15px",
                    }}
                  >
                    <CustomButton
                      label="-"
                      sx={{
                        borderRadius: "0 15px 15px 0",
                        backgroundColor: "white",
                      }}
                      onClick={() =>
                        handleCartClick({
                          itemId: promptItem?.id,
                          defaultPrice: promptItem?.price,
                          type: "dcr",
                        })
                      }
                      textColor="black"
                    />
                    <CustomButton
                      label={totalQuantity}
                      sx={{
                        backgroundColor: "white",
                      }}
                      textColor="black"
                    />
                    <CustomButton
                      label="+"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px 0px 0px 15px",
                        "& :hover": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={() => {
                        handleCartClick({
                          itemId: promptItem?.id,
                          defaultPrice: promptItem?.price,
                          type: "inc",
                        });
                      }}
                      textColor="black"
                    />
                  </ButtonGroup>
                ) : (
                  <CustomButton
                    label="Add"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      let is_package = localStorage.getItem("is_package");
                      is_package == 1
                        ? handleCardClickForPackage(promptItem, item)
                        : handleCartClick({
                            itemId: promptItem?.id,
                            defaultPrice: promptItem?.price,
                            type: "inc",
                          });
                    }}
                    sx={{
                      borderRadius: "15px",
                      boxShadow: "none",
                      backgroundColor: "primary",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        {itemSizeValue?.ItemSizePrices?.length > 0 && (
          <Box m={3}>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              sx={{
                margin: "10px 0px",
                fontWeight: "700",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
            >
              Choose Your Size
            </FormLabel>

            {itemSizeValue?.ItemSizePrices?.map((option, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <Typography variant="subtitle2" sx={{ marginRight: "10px" }}>
                  {`${option?.ItemSize?.size} - $${option?.price}`}
                </Typography>

                {cart?.find(
                  (itemId) =>
                    itemId.defaultPrice === option?.price &&
                    itemId.itemId === option.item_id
                ) ? (
                  <ButtonGroup
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                  >
                    <CustomButton
                      label="-"
                      sx={{
                        borderRadius: "5px 0 0 5px",
                        color: "white",
                        minWidth: 10,
                      }}
                      onClick={() =>
                        handleCartClick({
                          itemId: option?.item_id,
                          defaultPrice: option?.price,
                          type: "dcr",
                          size_price_id: option?.size_id,
                          size: option?.ItemSize?.size,
                        })
                      }
                      textColor="black"
                    />
                    <CustomButton
                      label={option?.quantity}
                      sx={{
                        color: "white",
                      }}
                      textColor="black"
                    />
                    <CustomButton
                      label="+"
                      sx={{
                        borderRadius: "0 5px 5px 0",
                        color: "white",
                      }}
                      onClick={() => {
                        handleCartClick({
                          itemId: option?.item_id,
                          defaultPrice: option?.price,
                          size_price_id: option?.size_id,
                          size: option.ItemSize.size,
                          type: "inc",
                          // size: item?.ItemSize?.size,
                        });
                      }}
                      textColor="black"
                    />
                 { console.log("🚀 ~ Item ~ option:", option)}
                  </ButtonGroup>
                ) : (
                  <CustomButton
                    label="Add"
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      handleCartClick({
                        itemId: option?.item_id,
                        defaultPrice: option?.price,
                        size_price_id: option?.size_id,
                        type: "inc",
                        size: option.ItemSize.size,
                      });
                    }}
                    sx={{
                      borderRadius: "5px",
                      boxShadow: "none",
                      backgroundColor: "primary",
                      color: "#fff",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      minWidth: 10,
                    }}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}
        <div className="thali_m">
          <Box>
            {promptItem?.relItems?.map((item, index) => {
              let is_package = localStorage.getItem("is_package");
              if (is_package == 1) {
                return (
                  <>
                    {index == 0 ? <h4>Fixed Itemsssss</h4> : <h4>Optional</h4>}
                    <Grid
                      container
                      spacing={1}
                      key={index}
                      className="food-item-detail"
                    >
                      {item &&
                        item?.map((itemElement) => (
                          <>
                            {index == 0 ? (
                              <>
                                <Grid className="first_s" item md={2}>
                                  <CardMedia
                                    component="img"
                                    alt="title"
                                    src={`${IMAGE_URL}/items/${
                                      JSON.parse(itemElement?.images || "{}")[0]
                                        ?.filename
                                    }`}
                                    sx={{
                                      borderRadius: "5px",
                                      objectFit: "cover",
                                      height: "4rem",
                                      border: "0.5px solid #d7d7d7",
                                      width: "4rem",
                                      m: 2,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={8}
                                  className="item-details first_s"
                                >
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      display="flex"
                                      alignItems="center"
                                      flex="auto"
                                    >
                                      {itemElement?.name}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid className="first_s" item md={2}></Grid>
                              </>
                            ) : (
                              <>
                                <Grid item md={1}>
                                  <input
                                    type="radio"
                                    name={index}
                                    value={itemElement.id}
                                    defaultChecked={
                                      item[0].id == itemElement.id
                                    }
                                    onClick={() =>
                                      handleChangeForRelevantRadioBtn(
                                        itemElement,
                                        index
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item md={2}>
                                  <CardMedia
                                    component="img"
                                    alt="title"
                                    src={`${IMAGE_URL}/items/${
                                      JSON.parse(itemElement?.images || "{}")[0]
                                        ?.filename
                                    }`}
                                    sx={{
                                      borderRadius: "5px",
                                      objectFit: "cover",
                                      height: "4rem",
                                      border: "0.5px solid #d7d7d7",
                                      width: "4rem",
                                      m: 2,
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={7}
                                  className="item-details item_price"
                                >
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      display="flex"
                                      alignItems="center"
                                      flex="auto"
                                    >
                                      {itemElement?.name}
                                    </Typography>
                                    <Typography
                                      paragraph={true}
                                      display="flex"
                                      alignItems="center"
                                      flex="auto"
                                    >
                                      $ {itemElement?.price}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} className="item_price">
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    flex="end"
                                    mr={2}
                                    ml={1}
                                    className="btn-box"
                                  ></Box>
                                </Grid>
                              </>
                            )}
                          </>
                        ))}
                    </Grid>
                  </>
                );
              } else {
                const totalQuantity = cart?.find(
                  ({ itemId }) => itemId === item?.relevant_item_id
                )?.quantity;
                return (
                  <Grid
                    container
                    spacing={2}
                    key={index}
                    className="food-item-detail"
                    style={{ padding: "0px" }}
                  >
                    <Grid item md={2}>
                      <CardMedia
                        component="img"
                        alt="title"
                        src={`${IMAGE_URL}/items/${
                          JSON.parse(item?.relevant_item?.images || "{}")[0]
                            ?.filename
                        }`}
                        sx={{
                          borderRadius: "5px",
                          objectFit: "cover",
                          height: "4rem",
                          border: "0.5px solid #d7d7d7",
                          width: "4rem",
                          m: 2,
                        }}
                      />
                    </Grid>
                    <Grid item md={7} className="item-details">
                      <Box>
                        <Typography
                          variant="h6"
                          display="flex"
                          alignItems="center"
                          flex="auto"
                        >
                          {item?.relevant_item?.name}
                        </Typography>
                        <Typography
                          paragraph={true}
                          display="flex"
                          alignItems="center"
                          flex="auto"
                        >
                          {item?.relevant_item?.description}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flex="end"
                        margin={"0px"}
                        className="btn-box"
                      >
                        {totalQuantity ? (
                          <ButtonGroup
                            variant="contained"
                            sx={{
                              borderRadius: "15px 15px 15px 15px",
                            }}
                          >
                            <CustomButton
                              label="-"
                              sx={{
                                borderRadius: "0 15px 15px 0",
                                minWidth: 10,
                              }}
                              onClick={() =>
                                handleCartClick({
                                  itemId: item?.relevant_item_id,
                                  defaultPrice: item?.relevant_item?.price,
                                  type: "dcr",
                                  size: item?.ItemSize?.size,
                                })
                              }
                              textColor="black"
                            />
                            <CustomButton
                              label={totalQuantity}
                              textColor="black"
                            />
                            <CustomButton
                              label="+"
                              sx={{
                                borderRadius: "15px 0px 0px 15px",
                                "& :hover": {
                                  backgroundColor: "white",
                                },
                              }}
                              onClick={() => {
                                handleCartClick({
                                  itemId: item?.relevant_item_id,
                                  defaultPrice: item?.relevant_item?.price,
                                  type: "inc",
                                  size: item?.ItemSize?.size,
                                });
                              }}
                              textColor="black"
                            />
                          </ButtonGroup>
                        ) : (
                          <CustomButton
                            label="Add"
                            variant="contained"
                            size="medium"
                            onClick={() =>
                              handleCartClick({
                                itemId: item?.relevant_item_id,
                                defaultPrice: item?.relevant_item?.price,
                                type: "inc",
                                // size: item?.ItemSize?.size,
                              })
                            }
                            sx={{
                              borderRadius: "15px",
                              boxShadow: "none",
                              backgroundColor: "primary",
                              color: "#fff",
                              whiteSpace: "nowrap",
                              minWidth: 10,
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                );
              }
            })}
          </Box>
        </div>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            alignItems: "center",
            padding: "10px",
            color: "#fff",
          }}
        ></Box>
      </CustomizedDialog>
      <CustomizedDialog
        handleClose={() => {
          setSizeDialogOpen(false);
        }}
        open={sizedialogOpen}
        onClick={() => {}}
        onCancel={() => {
          setSizeDialogOpen(false);
        }}
        contentStyle={{ maxWidth: "700px", minWidth: "500px" }}
        className="item-detail-section"
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            alignItems: "center",
            mb: "10px",
            padding: "10px",
            color: "#fff",
          }}
          className="custom-dialog-header"
        >
          <Typography variant="h6" fontWeight={600}>
            {itemSizeValue?.itemName}
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            width: "100%",
            alignItems: "center",
            padding: "20px",
          }}
          className="dialog-items-details"
        >
          <Box ml={2}>
            <Box>
              <Typography variant="subtitle2">
                Price: ${itemSizeValue?.price}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  marginBottom: "10px",
                  overflowWrap: "break-word",
                }}
              >
                Item Desc: {itemSizeValue?.itemDescription}
              </Typography>
            </Box>
            <Box>
              <FormLabel
                id="demo-row-radio-buttons-group-label"
                sx={{
                  margin: "10px 0px",
                  fontWeight: "700",
                  padding: "5px",
                  borderRadius: "10px",
                  backgroundColor: (theme) => theme.palette.primary.light,
                }}
              >
                Choose Your Size
              </FormLabel>

              {itemSizeValue?.ItemSizePrices?.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <Typography variant="subtitle2" sx={{ marginRight: "10px" }}>
                    {`${option?.ItemSize?.size} - $${option?.price}`}
                  </Typography>

                  {cart?.find(
                    (itemId) =>
                      itemId.defaultPrice === option?.price &&
                      itemId.itemId === option.item_id
                  ) ? (
                    <ButtonGroup
                      variant="contained"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                      }}
                    >
                      <CustomButton
                        label="-"
                        sx={{
                          borderRadius: "15px 0 0 15px",
                          // backgroundColor: "white",
                          minWidth: 10,
                        }}
                        onClick={() =>
                          handleCartClick({
                            itemId: option?.item_id,
                            defaultPrice: option?.price,
                            type: "dcr",
                            size_price_id: option?.size_id,
                            size: option?.ItemSize?.size,
                          })
                        }
                        textColor="black"
                      />
                      <CustomButton
                        label={option?.quantity}
                        sx={{
                          // backgroundColor: "white",
                          color: "black",
                        }}
                        textColor="black"
                      />
                      <CustomButton
                        label="+"
                        sx={{
                          borderRadius: "0 15px 15px 0",
                        }}
                        onClick={() => {
                          handleCartClick({
                            itemId: option?.item_id,
                            defaultPrice: option?.price,
                            size_price_id: option?.size_id,
                            size: option.ItemSize.size,
                            type: "inc",
                            // size: item?.ItemSize?.size,
                          });
                        }}
                        textColor="black"
                      />
                    </ButtonGroup>
                  ) : (
                    <CustomButton
                      label="Add Price"
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        handleCartClick({
                          itemId: option?.item_id,
                          defaultPrice: option?.price,
                          size_price_id: option?.size_id,
                          type: "inc",
                          size: option.ItemSize.size,
                        });
                      }}
                      sx={{
                        borderRadius: "15px",
                        boxShadow: "none",
                        backgroundColor: "primary",
                        color: "#fff",
                        whiteSpace: "nowrap",
                        minWidth: 10,
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
            {/* <Box sx={{ margin: "20px 0px", textAlign: "center" }}>
                    <CustomButton
                      label="Add SizePrice"
                      variant="contained"
                      size="medium"
                      onClick={() =>
                        handleCartClick({
                          itemId: itemSizeValue?.id,
                          defaultPrice: itemSizeValue?.price,
                          type: "inc",
                          size: itemSizeValue?.ItemSize?.size,
                        })
                      }
                      sx={{
                        borderRadius: "15px",
                        boxShadow: "none",
                        backgroundColor: "primary",
                        color: "#fff",
                        whiteSpace: "nowrap",
                        minWidth: 10,
                      }}
                    />
                  </Box> */}
          </Box>
        </Box>
      </CustomizedDialog>
      
    </PageWrapper>
  );
};

export default Item;
