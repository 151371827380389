import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const calDeliveryCharges = async (durection) => {
  try {
    const response = await api.post(endPoints.CAL_DELIVERY_CHARGES,durection);
    return response?.data?.data || [];
  } catch (error) {
    throw Error(error);
  }
};