import React from "react";
import Section1 from "pages/about-us/components/Section1";
import Section2 from "pages/about-us/components/Section2";
// import Section3 from "pages/about-us/components/Section3";

const AboutUs = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
    </>
  );
};

export default AboutUs;
