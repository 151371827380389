import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  List,
  Typography,
} from "@mui/material";
import CustomButton from "components/ui/CustomButton";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import RoomIcon from "@mui/icons-material/Room";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getAddressList } from "store/address/actions";

const AddressDialog = (props) => {
  const { deliveryAddressID, setAddressID, handleDialogClose } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.AuthReducer.user.data);
  const addressReducer = useSelector((state) => state.AddressReducer);

  const [addressList, setAddressList] = useState([]);

  const handleAddressChange = (id) => {
    setAddressID(id);
    localStorage.setItem("address_id", id);
    handleDialogClose();
  };

  useEffect(() => {
    if (!addressReducer.addressList.length) {
      dispatch(getAddressList());
    }
    setAddressList(addressReducer.addressList);
  }, [addressReducer.addressList]);

  return (
    <Grid
      container
      component="main"
      sx={{
        padding: 1,
        backgroundColor: (theme) => theme.palette.secondary.main,
      }}
      justifyContent="center"
      className="delivery-address"
    >
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: "6%",
              zIndex: 1,
              bgcolor: (theme) => theme.palette.secondary.main,
              mb: "10%",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              mr="auto"
              ml="auto"
              display="flex"
              justifyContent="center"
            >
              Select Delivery Address
            </Typography>
          </Box>

          <List
            dense
            sx={{
              width: "90%",
              maxWidth: 360,
              bgcolor: (theme) => {
                return theme.palette.secondary.main;
              },
              mr: "auto",
              ml: "auto",
              // pt: "8%",
            }}
          >
            {addressReducer?.loading ? (
              <Grid
                display="flex"
                justifyContent="center"
                overflow="hidden"
                mt={10}
                mb={10}
              >
                <CircularProgress />
              </Grid>
            ) : addressList?.length ? (
              addressList?.map((address) => (
                <Card
                  sx={{
                    mb: 2,
                    overflow: "auto",
                  }}
                  key={address?.id}
                >
                  <Box display={"flex"} justifyContent="space-between">
                    <Box width={"100%"}>
                      <CardContent
                        sx={{
                          p: 0,
                          pl: 2,
                          pt: 1,
                          paddingBottom: "8px !important",
                        }}
                      >
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Box display={"flex"} alignItems="center">
                            {address?.address_type === "Home" ? (
                              <HomeIcon />
                            ) : address?.address_type === "Work" ? (
                              <BusinessIcon />
                            ) : (
                              <RoomIcon />
                            )}
                            <Typography
                              variant="h6"
                              component="div"
                              fontWeight={"bold"}
                              ml={1}
                            >
                              {address?.address_type}
                            </Typography>
                            {user?.address_id === address?.id ? (
                              <Chip
                                label="Defalut"
                                variant="outlined"
                                size="small"
                                sx={{
                                  borderColor: (theme) =>
                                    theme.palette.primary.main,
                                  ml: 1,
                                }}
                                className="default-address-chip"
                              />
                            ) : null}
                          </Box>
                        </Box>
                        <Typography variant="body1" pl={"4%"}>
                          {address?.address_one}, {address?.address_two},{" "}
                          {address?.zipcode}
                        </Typography>
                      </CardContent>
                    </Box>
                    <Box>
                      <CardActions>
                        <CustomButton
                          label={
                            deliveryAddressID === address?.id
                              ? `Selected`
                              : `Select`
                          }
                          size="small"
                          disabled={deliveryAddressID === address?.id}
                          variant="contained"
                          sx={{
                            ml: "auto",
                          }}
                          onClick={() => handleAddressChange(address?.id)}
                        />
                      </CardActions>
                    </Box>
                  </Box>
                </Card>
              ))
            ) : (
              <Typography textAlign={"center"}>No Addresses found !</Typography>
            )}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

AddressDialog.propTypes = {
  setAddressID: PropTypes.func,
  handleDialogClose: PropTypes.func,
  deliveryAddressID: PropTypes.number,
};

export default AddressDialog;
