import {
  Box,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import AddressSection from "./AddressSection";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";

import DiscountDialog from "./DiscountDialog";
import CustomizedDialog from "components/ui/Dialog";
import CustomButton from "components/ui/CustomButton";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import {
  getDefaultPromoCode,
  getPromoByID,
  verifyPromoCode,
} from "services/DiscountService";
import Authentication from "components/authentication/Authentication";
import LoyaltyPointDialog from "./LoyaltyPointDialog";
import { calculateCashback } from "services/LoyaltyPointService";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { calDeliveryCharges } from "services/DeliveryService";

const CartSummary = (props) => {
  const { totalPrice } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addressId = useSelector((state) => state.AddressReducer);
  const authenticatedUser = useSelector(
    (state) => state?.AuthReducer?.user?.data
  );
  const token = useSelector((state) => state.AuthReducer.token);
  const [isCheckout, setIsCheckout] = useState(
    addressId?.addressList?.length < 1
  );
  const [error, setError] = useState(null);
  const [cashback, setCashback] = useState(0);
  const [promoID, setPromoID] = useState(null);
  const [minAmount, setMinAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [dialogOpenAuth, setDialogOpenAuth] = useState(false);
  const [appliedPromoCode, setAppliedPromoCode] = useState({});
  const [usedLoyaltyPoints, setUsedLoyaltyPoints] = useState(0);
  const [discountType, setDiscountType] = useState({type: "flat", value: 0});
  const [distance, setDistance] = useState({ distance: 0, duration: 0 });
  const [dialogOpenLoyaltyPoint, setdialogOpenLoyaltyPoint] = useState(false);

  useEffect(() => {
    if (totalPrice && !promoID) {
      getDefaultPromoCode({ total_amount: totalPrice }).then((res) => {
        setAppliedPromoCode(res?.data[0]?.promocode);
        setPromoID(res?.data[0]?.id);
      });
    }
  }, [totalPrice]);

  const verifyAppliedPromo = () => {
    if (promoID) {
      if (totalPrice < minAmount) {
        verifyPromoCode({
          payload: {
            promocode: appliedPromoCode.promocode,
            total_amount: totalPrice,
          },
        })
          .then(({ data }) => {
            setDiscountValue(parseInt(data.value));
            setCashback(0);
            setError(false);
            dispatch(
              snackbarOpen({
                open: true,
                message: "Promo Code Applied Successfully !",
                type: SNACKBAR_MESSAGE_TYPE.success,
              })
            );
          })
          .catch(() => {
            setError(true);
            setPromoID(null);
            localStorage.removeItem("promoID");
            setDiscountValue(0);
            setAppliedPromoCode({});
            dispatch(
              snackbarOpen({
                open: true,
                message: "Failed to verify Promo Code !",
                type: SNACKBAR_MESSAGE_TYPE.error,
              })
            );
          });
      }
    }
  };

  //Only one and first time run method
  useEffect(() => {
    localStorage.removeItem("appliedLoyaltyPoints");
  }, []);

  useEffect(() => {
    if (promoID) {
      getPromoByID({ id: promoID })
        .then(({ data }) => {
          setError(false);
          setAppliedPromoCode(data);
          setMinAmount(data?.min_order_amount);
          setDiscountValue(data?.values);
          if (data?.value_type === "Percentage") {
            setDiscountType({type: "Percentage", value: data?.values})
            setDiscountValue((totalPrice * data?.values) / 100);
          }
        })
        .catch((error) => {
          setError(true);
          setPromoID(null);
          setAppliedPromoCode({});
          localStorage.removeItem("promoID");
          dispatch(
            snackbarOpen({
              open: true,
              message: error,
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
  }, [promoID, token]);

  useEffect(() => {
    !promoID && setPromoID(parseInt(localStorage.getItem("promoID")) || null);
    verifyAppliedPromo();
  }, [promoID, totalPrice, token, minAmount]);

  useEffect(() => {
    // add totalPrice if loyalty points needs to be added depending upon conditions
    const points = localStorage.getItem("appliedLoyaltyPoints");

    if (parseInt(points) && (!promoID || promoID != null)) {
      setUsedLoyaltyPoints(parseInt(points));
    } else {
      setUsedLoyaltyPoints(0);
    }

    if (usedLoyaltyPoints) {
      calculateCashback({
        loyalty_point: usedLoyaltyPoints,
        total_amount: totalPrice,
      })
        .then(({ data, message }) => {
          setCashback(data.cashback);
          setDiscountValue(0);
          setAppliedPromoCode({});
          dispatch(
            snackbarOpen({
              open: true,
              message: message,
              type: SNACKBAR_MESSAGE_TYPE.success,
            })
          );
        })
        .catch(() => {
          localStorage.setItem("appliedLoyaltyPoints", 0);
          setUsedLoyaltyPoints(0);
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while applying loyalty points !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    } else {
      setCashback(0);
    }
  }, [usedLoyaltyPoints, token]);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    if (token) {
      setDialogOpen(true);
    } else {
      setDialogOpenAuth(true);
    }
  };

  const handleRemovePromo = () => {
    setDiscountValue(0);
    setAppliedPromoCode({});
    setPromoID(null);
    setError(null);
    localStorage.removeItem("promoID");
  };

  const handleCheckout = () => {
    if (token) {
      const addressID =
        localStorage.getItem("my_address_id") ||
        localStorage.getItem("address_id");
      if (addressID) {
        navigate("/checkout", { state: { fromCart: true } });

        const checkoutData = {
          subTotal: totalPrice,
          discount: Math.max(discountValue, cashback),
          taxCharges:
            ((totalPrice - Math.max(discountValue, cashback) + deliveryCharge) *
              13) /
            100,
          deliveryCharge: deliveryCharge,
          grandTotal: grandTotal,
          // loyaltyPoint: usedLoyaltyPoints,
        };
        localStorage.setItem("checkoutData", JSON.stringify(checkoutData));
      } else {
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong with the address !",
            type: SNACKBAR_MESSAGE_TYPE.warning,
          })
        );
      }
    } else {
      setDialogOpenAuth(true);
    }
  };

  const handleDialogCloseNew = () => {
    setDialogOpenAuth(false);
  };

  const handleCloseLoyaltyPointDialog = () => {
    setdialogOpenLoyaltyPoint(false);
  };

  useEffect(() => {
    if (authenticatedUser) {
      setDialogOpenAuth(false);
    }
  }, [authenticatedUser]);

  const calculateTotal = () => {
    const discountedPrice = totalPrice - Math.max(discountValue, cashback);
    const tax = ((discountedPrice + deliveryCharge) * 13) / 100;
    const total = tax + discountedPrice + deliveryCharge;
    setGrandTotal(total);
  };

  useEffect(() => {
    calculateTotal();
  }, [discountValue, totalPrice, cashback, deliveryCharge]);

  const useLoyaltyPoints = () => {
    setdialogOpenLoyaltyPoint(true);
  };

  const removeLoyaltyPoints = () => {
    setUsedLoyaltyPoints(0);
    localStorage.setItem("appliedLoyaltyPoints", 0);
  };

  useEffect(() => {
    if (distance?.duration) {
      // API for Getting Charge
      calDeliveryCharges({
        time: parseInt((distance?.duration / 60)?.toFixed(0)),
      })
        .then((data) => {
          if (grandTotal > data.MinOrderValue) {
            setDeliveryCharge(0);
          } else {
            setDeliveryCharge(data.charges);
          }
        })
        .catch(() => {
          setDeliveryCharge(0);
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong with the address !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    } else {
      setDeliveryCharge(0);
    }
  }, [distance.duration, grandTotal]);

  useEffect(() => {
    setIsCheckout(addressId?.addressList?.length < 1);
  }, [addressId?.addressList]);

  return (
    <Grid
      container
      display={"flex"}
      sx={{ flexDirection: { xs: "column", md: "row" } }}
      className="cart-summary-container"
    >
      <Grid md={8}>
        <AddressSection setDistance={setDistance} distance={distance} />
      </Grid>
      <Grid
        md={4}
        item
        alignItems="end"
        display="flex"
        flex="auto"
        sx={{ flexDirection: "column" }}
        // width="100%"
      >
        <Box whiteSpace="nowrap">
          {!promoID && authenticatedUser?.loyaltypoint ? (
            <CustomButton
              label="Use Loyalty Points"
              variant="contained"
              size="small"
              sx={{ my: 1.5, fontSize: "75%" }}
              onClick={useLoyaltyPoints}
              className="promo-btn"
            />
          ) : null}

          {!promoID && usedLoyaltyPoints ? (
            <CustomButton
              label="Remove Loyalty Points"
              variant="contained"
              size="small"
              sx={{
                my: 1.5,
                ml: 1.5,
                fontSize: "75%",
              }}
              onClick={removeLoyaltyPoints}
              className="promo-btn"
            />
          ) : null}

          {!usedLoyaltyPoints ? (
            <CustomButton
              label={discountValue && !error ? "Change Promo" : "Apply Promo"}
              size="small"
              onClick={handleDialogOpen}
              variant="contained"
              sx={{ my: 1.5, ml: 1.5, fontSize: "75%" }}
              className="promo-btn"
            />
          ) : null}

          {promoID ? (
            <CustomButton
              label="Remove"
              size="small"
              variant="text"
              sx={{ my: 1.5, ml: 1.5, fontSize: "75%" }}
              textColor="#faa148"
              onClick={handleRemovePromo}
            />
          ) : null}
        </Box>

        {appliedPromoCode?.id && !cashback && !error ? (
          <Box display={"flex"} mb={2} width="100%" justifyContent={"end"}>
            <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
            <Typography fontSize={14}>Promo Code</Typography>&nbsp;
            <Typography fontSize={14} fontWeight="bold">
              {appliedPromoCode?.promocode}
            </Typography>
            &nbsp;
            <Typography>Applied !</Typography>
          </Box>
        ) : null}

        <CustomizedDialog
          overflow="auto"
          open={dialogOpen}
          onCancel={handleDialogClose}
          handleClose={handleDialogClose}
        >
          <DiscountDialog
            setError={setError}
            subTotal={totalPrice}
            totalPrice={totalPrice}
            setPromoID={setPromoID}
            setDialogOpen={setDialogOpen}
            setAppliedPromoCode={setAppliedPromoCode}
          />
        </CustomizedDialog>
        <Table
          sx={{
            backgroundColor: "#f2f2f2",
            width: { xs: "100%", md: "300px" },
            borderRadius: "7px",
            padding: 2,
          }}
        >
          <tbody>
            <TableRow sx={{ border: "0px 1px" }}>
              <TableCell
                sx={{ textAlign: "center" }}
                display="flex"
                alignItems="center"
                // justifyContent="flex-start"
              >
                Subtotal
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                ${totalPrice?.toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                border: "0px 1px",
                display: discountValue && !error ? "" : "none",
              }}
            >
              <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                <Box display={"flex"} alignItems={"center"}>
                  Discount -{" "}
                  <Typography fontSize={11}>
                    ({appliedPromoCode?.promocode})
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                - { discountType?.type == "flat" ? `$${discountValue}` : `$${discountValue} (${discountType?.value}%)` }
              </TableCell>
            </TableRow>

            <TableRow
              sx={{
                border: "0px 1px",
                display: cashback && !error ? "" : "none",
              }}
            >
              <TableCell
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexDirection="column"
                >
                  Cashback -{" "}
                  <Typography fontSize={11}>
                    Loyalty Points used ({usedLoyaltyPoints})
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>${cashback}</TableCell>
            </TableRow>

            <TableRow
              sx={{
                border: "0px 1px",
                display: deliveryCharge ? "" : "none",
              }}
            >
              <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                <Box display={"flex"} alignItems={"center"}>
                  Delivery Charges
                </Box>
              </TableCell>
              <TableCell
                sx={{ textAlign: "center", whiteSpace: "break-spaces" }}
              >
                ${deliveryCharge}
              </TableCell>
            </TableRow>

            <TableRow sx={{ border: "0px " }}>
              <TableCell
                sx={{ textAlign: "center" }}
                display="flex"
                alignItems="center"
                // justifyContent="flex-start"
              >
                Tax Rate (13%)
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                $
                {(
                  ((totalPrice -
                    Math.max(discountValue, cashback) +
                    deliveryCharge) *
                    13) /
                  100
                )?.toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow sx={{ border: "0px " }}>
              <TableCell
                sx={{ textAlign: "center" }}
                display="flex"
                alignItems="center"
                // justifyContent="flex-start"
              >
                Grand Total
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                ${grandTotal.toFixed(2)}
              </TableCell>
            </TableRow>

            <TableRow sx={{ textAlign: "center", "& td": { border: 0 } }}>
              <TableCell colSpan={2}>
                <CustomButton
                  variant="contained"
                  label="Checkout"
                  sx={{
                    height: "54px",
                    borderRadius: "50px",
                    fontSize: 15,
                  }}
                  disabled={isCheckout}
                  fullWidth={true}
                  onClick={handleCheckout}
                  className="checkout-btn"
                />
              </TableCell>
            </TableRow>
          </tbody>
        </Table>
      </Grid>
      <CustomizedDialog
        handleClose={handleDialogCloseNew}
        open={dialogOpenAuth}
        onClick={() => {}}
        onCancel={handleDialogCloseNew}
      >
        <Authentication handleDialogClose={handleDialogCloseNew} />
      </CustomizedDialog>
      <CustomizedDialog
        handleClose={handleCloseLoyaltyPointDialog}
        open={dialogOpenLoyaltyPoint}
        onClick={() => {}}
        onCancel={handleCloseLoyaltyPointDialog}
      >
        <LoyaltyPointDialog
          totalPrice={totalPrice}
          usedLoyaltyPoints={usedLoyaltyPoints}
          setUsedLoyaltyPoints={setUsedLoyaltyPoints}
          authenticatedUser={authenticatedUser}
          handleDialogClose={handleCloseLoyaltyPointDialog}
        />
      </CustomizedDialog>
    </Grid>
  );
};

CartSummary.propTypes = {
  totalPrice: PropTypes.number,
};

export default CartSummary;
