import {
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { getDiscounts } from "services/DiscountService";
import { verifyPromoCode } from "services/DiscountService";
import CustomLoadingButton from "components/ui/CustomLoadingButton";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "30px",
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
  "& .MuiFormLabel-root": {
    zIndex: 0,
  },
}));

const DiscountDialog = (props) => {
  const {
    subTotal,
    setError,
    setPromoID,
    totalPrice,
    setDialogOpen,
    setAppliedPromoCode,
  } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [discountList, setDiscountList] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState();
  // const apiCall = useRef(false);

  useEffect(() => {
    const promoID = parseInt(localStorage.getItem("promoID"));

    if (!isNaN(promoID)) {
      setAppliedCoupon(promoID);
    }

    // TODO optimize code block (renders twice)
    // if (apiCall.current) {
    getDiscounts()
      .then((res) => {
        setDiscountList(res.data);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching discounts !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
        setDialogOpen(false);
      });
    // }
    // apiCall.current = true;
  }, []);

  const handlePromoCode = (event) => {
    setPromoCode(event.target.value);
  };

  const verifyPromo = (discount) => {
    setAppliedCoupon(discount?.id);
    if (!loading) {
      setLoading(true);
      const promoData = {
        promocode: discount.promocode ? discount.promocode : promoCode,
        total_amount: subTotal,
      };

      verifyPromoCode({ payload: promoData })
        .then(({ data, message }) => {
          setLoading(false);
          setDialogOpen(false);
          setError(false);
          setPromoID(data.discount_id);
          localStorage.setItem("promoID", data.discount_id);
          setAppliedPromoCode(discount);
          dispatch(
            snackbarOpen({
              open: true,
              message: message,
              type: SNACKBAR_MESSAGE_TYPE.success,
            })
          );
        })
        .catch(({message}) => {
          setError(true);
          setLoading(false);
          setPromoID(null);
          setDialogOpen(false);
          setAppliedPromoCode({});
          localStorage.removeItem("promoID");
          dispatch(
            snackbarOpen({
              open: true,
              message: message,
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        padding: 1,
        backgroundColor: (theme) => {
          return theme.palette.secondary.main;
        },
      }}
    >
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ position: "relative" }}
        className="apply-discount"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: "4%",
              zIndex: 1,
              bgcolor: (theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              mx="auto"
              display="flex"
              justifyContent="center"
            >
              Apply Discount
            </Typography>

            <Grid container justifyContent="center">
              <CustomTextField
                margin="normal"
                id="firstName"
                label="Coupon Code"
                name="first_name"
                autoComplete="firstname"
                onChange={handlePromoCode}
                // sx={{ zIndex: -1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CustomLoadingButton
                        label="Apply"
                        variant="contained"
                        sx={{
                          height: "54px",
                          // width: "120px",
                          borderRadius: "30px",
                          mr: "-13px",
                        }}
                        onClick={() => verifyPromo({})}
                        loading={loading}
                        className="apply-promo-btn"
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Box>

          <List
            // dense
            sx={{
              width: "90%",
              maxWidth: 360,
              bgcolor: (theme) => {
                return theme.palette.secondary.main;
              },
              mx: "auto",
              top: "1%",
            }}
          >
            {loading ? (
              <Grid
                display="flex"
                justifyContent="center"
                overflow="hidden"
                mt={10}
                mb={10}
              >
                <CircularProgress />
              </Grid>
            ) : discountList ? (
              discountList?.map((discount) => (
                <Card
                  sx={{
                    mb: 2,
                    // overflow: "auto",
                  }}
                  key={discount?.id}
                >
                  <Grid container>
                    <Grid item md={8}>
                      <CardContent sx={{ p: 0, pl: 2, pt: 1 }}>
                        <Typography variant="h5" component="div" className="promo-title">
                          {discount?.promocode}
                        </Typography>
                        <Typography variant="body2">
                          {discount?.description}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 12 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          {discount?.value_type === "Flat" &&
                            discount?.value_type + " $" + discount?.values}{" "}
                          discount upon minimum order of $
                          {discount?.min_order_amount}
                        </Typography>
                      </CardContent>
                    </Grid>
                    <Grid item md={4}>
                      <CardActions>
                        <CustomLoadingButton
                          label={
                            appliedCoupon === discount.id ? "Applied" : "Apply"
                          }
                          disabled={
                            appliedCoupon === discount.id ||
                            discount.min_order_amount > totalPrice
                          }
                          variant="contained"
                          sx={{
                            ml: "auto",
                          }}
                          onClick={() => verifyPromo(discount)}
                          loading={loading}
                          className="apply-promo-list-btn"
                        />
                      </CardActions>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Typography>
                There are no available discounts currently !
              </Typography>
            )}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

DiscountDialog.propTypes = {
  setError: PropTypes.func,
  subTotal: PropTypes.number,
  setPromoID: PropTypes.func,
  totalPrice: PropTypes.number,
  setDialogOpen: PropTypes.func,
  setAppliedPromoCode: PropTypes.func,
};

export default DiscountDialog;
