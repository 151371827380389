import React from "react";
import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";

const CustomLoadingButton = (props) => {
  const {
    sx,
    type,
    size,
    // hover,
    label,
    variant,
    onClick,
    fullWidth,
    disabled = false,
    textColor = "white",
    loading,
    ...extra
  } = props;

  return (
    <LoadingButton
      loading={loading}
      onClick={onClick}
      variant={variant}
      type={type}
      fullWidth={fullWidth}
      disabled={disabled}
      size={size}
      sx={{
        ...sx,
        color: textColor,
        boxShadow: "none",
        whiteSpace: "nowrap",
        ":hover": {
          bgcolor: (theme) => theme.palette.primary.main,
        },
      }}
      {...extra}
    >
      {label}
    </LoadingButton>
  );
};

CustomLoadingButton.propTypes = {
  label: PropTypes.any,
  sx: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.string,
  hover: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  loading: PropTypes.bool,
};

export default CustomLoadingButton;
