import React from "react";
import { Grid, Box, Container } from "@mui/material";
import './contact-us.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import EmailIcon from '@mui/icons-material/Email';

const Section2 = () => {
  return (
    <div className="contact-detail" >
    <Container maxWidth="xl">
      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="cd-main-box">
              <div className="cd-icon">
                <LocationOnIcon />
              </div>
              <div className="cd-detail">
                <h2>Location</h2>
                <div className="cd-detail-inner">
                  <p><span>259 Wellington St W<br /> </span><span>Toronto, ON<br /> M5V 3E4</span></p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="cd-main-box">
              <div className="cd-icon">
                <WatchLaterIcon />
              </div>
              <div className="cd-detail">
                <h2>Hours</h2>
                <div className="cd-detail-inner">
                  <p><b>Lunch:</b><br /> Mon-Fri 11:30am to 2:30pm<br /> Sat &amp; Sun 11:30am to 3:30pm<br /> <b>Dinner:</b><br /> Sun-Thurs 5:00 to 10:00pm<br /> Fri &amp; Sat 5:00 to 11:00pm</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="cd-main-box">
              <div className="cd-icon">
                <StayCurrentPortraitIcon />
              </div>
              <div className="cd-detail">
                <h2>Phone</h2>
                <div className="cd-detail-inner">
                  <p>Office:<br /> <a href="tel:14165990513">1 (416) 599-0513</a><br /> Call for Reservations:<br /> <a href="14165990513">1 (416) 599-0513</a></p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <div className="cd-main-box">
              <div className="cd-icon">
                <EmailIcon />
              </div>
              <div className="cd-detail">
                <h2>Email</h2>
                <div className="cd-detail-inner">
                  <a href="mailto:admin@aanch.ca"></a>Reservations :<br />
                  <a href="mailto:reservation@aanch.ca">reservation@aanch.ca</a><br />
                  <a href="mailto:admin@aanch.ca"></a>Media Inquiries :<br />
                  <a href="mailto:media@aanch.ca">media@aanch.ca</a></div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

    </Container>
    </div>
  );
};

export default Section2;
