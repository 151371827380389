import React, { useState } from "react";
import { CardMedia, Box, Grid } from "@mui/material";
import LoginSVG from "../../assets/images/Login.svg";
import OTP from "./OTP";
import Login from "./Login";
import Signup from "./Signup";
import PropTypes from "prop-types";

const Authentication = (props) => {
  const { handleDialogClose } = props;
  const [loginMode, setLoginMode] = useState(true);
  const [signupMode, setSignupMode] = useState(true);

  return (
    <Grid
      container
      component="main"
      width="100%"
      sx={{
        padding: 2,
        backgroundColor: (theme) => {
          return theme.palette.secondary.main;
        },
        // height: "700px",
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={5}
        px={2}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <CardMedia
          component="img"
          src={LoginSVG}
          alt="Live from space album cover"
          sx={{
            width: "70%",
            display: { xs: "none", sm: "block" },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={8}
        md={7}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="left"
      >
        <Box
          sx={{
            my: 1,
            mx: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            {loginMode ? (
              <Login
                setLoginMode={setLoginMode}
                setSignupMode={setSignupMode}
                handleDialogClose={handleDialogClose}
              />
            ) : signupMode ? (
              <Signup
                setSignupMode={setSignupMode}
                setLoginMode={setLoginMode}
              />
            ) : (
              <OTP setSignupMode={setSignupMode} setLoginMode={setLoginMode} />
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

Authentication.propTypes = {
  handleDialogClose: PropTypes.func,
};

export default Authentication;
