import React from "react";

import PageWrapper from "../../components/wrapper/PageWrapper";
import Map from "./components/Map";
import OrderDetail from "./components/OrderDetail";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getOrder } from "services/OrderService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocketOrder } from "store/socket/socketAction";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";

const TrackOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const ref = useRef(false);

  const userData = useSelector((state) => state.AuthReducer.user.data);

  const [orderData, setOrderData] = useState({});
  const [distance, setDistance] = useState({ distance: 0, duration: 0 });

  if (!userData) {
    navigate("/");
  }

  useEffect(() => {
    if (id && userData) {
      getOrder(id)
        .then(({ data }) => {
          localStorage.setItem("orderID", data?.id);
          if (data?.user_id === userData?.id) {
            setOrderData(data);
          } else {
            setOrderData({});
            navigate("/");
            dispatch(
              snackbarOpen({
                open: true,
                message: "Something went wrong while fetching order !",
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
          }
          dispatch(getSocketOrder({ data }));
        })
        .catch(() => {
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while fetching order data !",
              type: SNACKBAR_MESSAGE_TYPE.warning,
            })
          );
          navigate("/");
        });
    }
    // ref.current = true;
  }, [id, userData]);

  return (
    <PageWrapper>
      <Map setDistance={setDistance} orderData={orderData} distance={distance} />
      <OrderDetail orderData={orderData} />
    </PageWrapper>
  );
};

export default TrackOrder;
