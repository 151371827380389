import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  DialogActions,
  Dialog,
  DialogTitle,
  Slide,
  Box,
  Chip,
  Skeleton,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteAddress, getAddressList } from "store/address/actions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const skeletonCount = [1, 2, 3];

const AddressList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const apiCall = useRef(false);

  const user = useSelector((state) => state?.AuthReducer?.user?.data);
  const addressReducer = useSelector((state) => state?.AddressReducer);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [sortedAddress, setSortedAddress] = useState([]);
  const [delAddressID, setDelAddressID] = useState(null);

  useEffect(() => {
    if (addressReducer?.addressList?.length && user) {
      const toBeSortArr = addressReducer?.addressList || [];

      const findDefault = toBeSortArr?.find(
        (address) => address?.id === user?.address_id
      );

      const findDefaultIndex = toBeSortArr?.findIndex(
        (address) => address?.id === user?.address_id
      );
      if (findDefaultIndex && findDefault) {
        toBeSortArr?.splice(findDefaultIndex, 1);
        toBeSortArr?.unshift(findDefault);
        setSortedAddress(toBeSortArr);
      } else {
        setSortedAddress(addressReducer?.addressList);
      }
    }
    else if (addressReducer?.addressList.length == 0) {
      setSortedAddress(addressReducer?.addressList);
    }
  }, [addressReducer?.addressList, user]);

  const handleRedirect = (address) => {
    navigate(`/manage-address/${address.id}`);
  };

  // Set first address as default if existing default address is deleted
  const handleDeleteAddress = (id) => {
    dispatch(
      deleteAddress({
        id: id,
        callback: ({ error }) => {
          if (!error) {
            dispatch(
              snackbarOpen({
                open: true,
                message: "Address has been deleted successfully !",
                type: SNACKBAR_MESSAGE_TYPE.success,
              })
            );
          } else {
            dispatch(
              snackbarOpen({
                open: true,
                message: "Something went wrong while deleting address !",
                type: SNACKBAR_MESSAGE_TYPE.error,
              })
            );
          }
        },
      })
    );
    setDialogOpen(false);
  };

  const handleDialogOpen = (id) => {
    setDelAddressID(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // if (apiCall.current) {
    if (!addressReducer?.addressList?.length && user) {
      dispatch(
        getAddressList({
          callback: ({ error, status }) => {
            if (error && status != 404) {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "Something went wrong while fetching address list !",
                  type: SNACKBAR_MESSAGE_TYPE.error,
                })
              );
            }
          },
        })
      );
    }
    // }
    // apiCall.current = true;
  }, [addressReducer?.addressList, user]);

  return (
    <>
      {addressReducer?.loading ? (
        skeletonCount?.map((index) => (
          <Skeleton
            width="100%"
            height={280}
            sx={{
              justifyContent: "center",
              border: "0.5px solid #d7d7d7",
              borderRadius: "5px",
            }}
            key={index}
          />
        ))
      ) : sortedAddress?.length ? (
        sortedAddress?.map((address, index) => (
          <Card
            sx={{
              width: "100%",
              backgroundColor: "#f8f8f8",
              justifyContent: "center",
              m: 2,
              boxShadow: "none",
            }}
            key={index}
            className="address-card"
          >
            <CardContent
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                  {address?.address_type}
                </Typography>

                <Typography sx={{ fontSize: "17px" }}>
                  {address?.address_one}
                </Typography>

                <Typography sx={{ fontSize: "17px" }}>
                  {address?.address_two}
                </Typography>

                <Typography sx={{ fontSize: "17px" }}>
                  {address?.zipcode}
                </Typography>
              </Box>


              <CardActions sx={{ justifyContent: "flex-sart", alignItems: "start" }}>
                <Box>
                  {user?.address_id === address?.id ? (
                    <Chip
                      label="Default"
                      variant="outlined"
                      sx={{ borderColor: (theme) => theme.palette.primary.main }}
                      className="default-chip"
                    />
                  ) : null}
                </Box>
                <IconButton
                  color="primary"
                  sx={{ width: "0.7rem", mr: 1 }}
                  onClick={() => handleRedirect(address)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  sx={{ width: "0.7rem" }}
                  onClick={() => handleDialogOpen(address?.id)}
                >
                  <DeleteIcon />
                </IconButton>

              </CardActions>
            </CardContent>


          </Card>
        ))
      ) : (
        <Typography m={10} textAlign="center" variant="h5">
          No Addresses found !
        </Typography>
      )}

      <Dialog
        TransitionComponent={Transition}
        keepMounted
        handleClose={handleDialogClose}
        open={dialogOpen}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are You sure Delete The Address?"}</DialogTitle>
        <DialogActions>
          <Button
            open={dialogOpen}
            onClick={() => handleDeleteAddress(delAddressID)}
          >
            Yes
          </Button>
          <Button onClick={handleDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressList;
