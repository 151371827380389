import React from "react";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import CustomButton from "./CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultFoodImg from "../../assets/images/default-food-img.png";
import nonVeg from "../../assets/images/nonveg.png";
import veg from "../../assets/images/veg.png";

const CustomCard = (props) => {
  const {
    id,
    onClick,
    image,
    title,
    isCategory = false,
    defaultPrice,
    handleCartClick,
    itemQuantity,
    multiplePrice,
    relevantItems,
    ItemSizePrices,
    description,
    is_package,
    currentPage = null,
    is_type,
    ...extra
  } = props;
  
  const restrictedPaths = ["/", "/categories"];

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Card
      sx={{
        minWidth: "100%",
        margin: 2,
        height: "auto",
        boxShadow: "none",
        textAlign: "center",
      }}
      onClick={onClick}
      {...extra}
    >
      <Box
        sx={{
          p: 1,
          width: currentPage === "menu" ? "100%" : null,
          mr: "auto",
          ml: "auto",
          position: "relative",
        }}
      >
        <div className="imageBox">
          <CardMedia
            component="img"
            alt={title}
            src={image}
            onError={(e) => (e.target.src = DefaultFoodImg)}
            sx={{
              borderRadius: "15px",
              objectFit: "cover",
              height: undefined,
              // border: "0.5px solid #d7d7d7",
              width:
                currentPage === "categories"
                  ? "100%"
                  : { xs: "100%", md: "100%" },
              aspectRatio: "1/1",
              maxHeight: "205px",
              marginLeft: currentPage === "categories" ? "60px" : null,
              mx: "auto",
            }}
            onClick={
              !restrictedPaths.includes(pathname)
                ? () => navigate(`/item/${id}`)
                : () => {}
            }
          />
          <img className="foodLogo" src={is_type == "Veg" ? veg : nonVeg} />
        </div>
        {/* <div className="">
        
        </div> */}
      </Box>
      <CardContent className="card-content" sx={{ whiteSpace: "nowrap" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", whiteSpace: "pre-wrap" }}
          maxWidth="100%"
        >
          {title}
        </Typography>
        <Typography
          variant="p"
          sx={{ whiteSpace: "pre-wrap" }}
          maxWidth="100%"
          paragraph={true}
        >
          {description}
        </Typography>
        {!isCategory ? (
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="box-price"
          >
            {`$ ${defaultPrice}`}
          </Typography>
        ) : null}
      </CardContent>

      {!isCategory ? (
        <CardActions sx={{ justifyContent: "center", mt: -2 }}>
          {itemQuantity && is_package == 0 && ItemSizePrices?.length === 0 ? (
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{
                backgroundColor: "white",
                borderRadius: "15px 15px 15px 15px",
              }}
            >
              <CustomButton
                label="-"
                textColor="black"
                variant="contained"
                sx={{
                  borderRadius: "0 15px 15px 0",
                  backgroundColor: "white",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() =>
                  handleCartClick({
                    itemId: id,
                    defaultPrice,
                    type: "dcr",
                    multiplePrice,
                    is_package,
                  })
                }
              />
              <CustomButton
                textColor="black"
                variant="contained"
                label={itemQuantity}
                sx={{
                  backgroundColor: "white",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }}
              />

              <CustomButton
                label="+"
                variant="contained"
                textColor="black"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "15px 0px 0px 15px",
                  // borderColor: "white",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() =>
                  handleCartClick({
                    itemId: id,
                    defaultPrice,
                    type: "inc",
                    multiplePrice,
                    is_package,
                  })
                }
              />
            </ButtonGroup>
          ) : (
            <CustomButton
              variant="contained"
              label="Add to Cart"
              size="large"
              onClick={() =>
                handleCartClick({
                  itemId: id,
                  defaultPrice,
                  type: "inc",
                  multiplePrice,
                  relevantItems,
                  is_package,
                  ItemSizePrices,
                })
              }
              sx={{
                borderRadius: "15px",
                backgroundColor: "primary",
                color: "#fff",
              }}
            />
          )}
        </CardActions>
      ) : null}
    </Card>
  );
};
CustomCard.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  image: PropTypes.string,
  isCategory: PropTypes.bool,
  currentPage: PropTypes.string,
  defaultPrice: PropTypes.string,
  itemQuantity: PropTypes.string,
  multiplePrice: PropTypes.array,
  relevantItems: PropTypes.array,
  ItemSizePrices: PropTypes.array,
  handleCartClick: PropTypes.func,
  description: PropTypes.string,
  is_package: PropTypes.number,
  is_type: PropTypes.string,
  // description: PropTypes.string
};
export default CustomCard;
