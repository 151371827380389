import * as React from "react";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";
import CustomButton from "components/ui/CustomButton";

const HelpCard = () => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
        border: "1px solid #d3d3d3",
        py: 4,
        px: 2,
        backgroundColor: "#f2f2f2",
        textAlign: "initial",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ color: "#000000", fontWeight: "bold", mb: 1 }}
        >
          Need Help?
        </Typography>
        <Typography variant="h6" sx={{ color: "#000000", fontSize: "14px" }}>
          If you have more question please let me know. I will answer as soon as
          possible.
        </Typography>
      </CardContent>

      <CardActions sx={{ px: 0 }}>
        <CustomButton
          label="Contact Us"
          variant="contained"
          sx={{
            borderRadius: "30px",
            py: 1,
            ml: 2,
            color: "white",
          }}
        />
      </CardActions>
    </Card>
  );
};

export default HelpCard;
