import React from "react";
import { Box, Typography } from "@mui/material";
import PageWrapper from "../components/wrapper/PageWrapper";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/ui/CustomButton";
import Banner from "../../src/assets/images/Banner1.png";

import "./error.css";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Box
        sx={{
          height: "450px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mx: "auto" }}>
          <Box
            sx={{
              color: "#faa148",
              fontFamily: "Nunito Sans, sans-serif",
              fontSize: "11rem",
            }}
          >
            4
          </Box>
          <Box
            sx={{
              fontSize: "8.5rem",
              mx: { xs: 0, md: 5 },
            }}
          >
            <img className="rotate" src={Banner} alt="" width="120" />
          </Box>
          <Box
            sx={{
              color: "#faa148",
              fontFamily: "Nunito Sans, sans-serif",
              fontSize: "11rem",
            }}
          >
            4
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "2.2rem",
            fontWeight: "bold",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            OOPS!!
          </Typography>
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            The Page You Requested Could Not Be Found.
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontFamily: "Nunito Sans, sans-serif",
            fontSize: "1.6rem",
          }}
        >
          <CustomButton
            onClick={() => navigate("/")}
            label="Go to Home"
            variant="contained"
            sx={{
              borderRadius: "30px",
              py: 1,
              ml: 2,
              color: "white",
              marginTop: "20px",
              zIndex: "90",
              marginBottom: "2px",
            }}
          />
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default Error404;
