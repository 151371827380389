import React, { useEffect } from "react";
import { CardMedia, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Food from "../../../assets/images/national.jpg";
import { makeStyles } from "@material-ui/core/styles";
import './contact-us.css';

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  font: {
    content: "",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
}));

const Section1 = () => {
  const classes = useStyles();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => scrollToTop(), []);

  return (
    <Box
      className="banner-contact"
      sx={{
        display: "flex !important",
        justifyContent: "center",
        height: { xs: "auto", md: window.innerHeight - 120 },

        // opacity: "50%",
        // backgroundColor: "#faa148",
      }}
    >
      <CardMedia
        component="img"
        image={Food}
        width="80%"
        alt="Live from space album cover"
      />
      <Box className={classes.font}>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            marginTop: "305px",
            color: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Contact Us
        </Typography>
        <Typography
          sx={{
            top: "35%",
            fontSize: "40px",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          Have a question or need assistance ?
        </Typography>
        <Typography
          sx={{
            top: "45%",
            fontSize: "40px",
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          We are here to help you with anything you need !
        </Typography>
      </Box>
    </Box>
  );
};

export default Section1;
