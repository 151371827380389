import {
  ADD_ADDRESS,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILED,
  GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_SUCCESS,
  GET_ADDRESS_LIST_FAILED,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILED,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_FAILED,
  UPDATE_ADDRESS_SUCCESS,
  CLEAR_ADDRESS,
  CLEAR_ADDRESS_SUCCESS,
} from "store/actionTypes";

const initialState = {
  address: {},
  addressList: [],
  loading: false,
  error: {
    message: "",
    type: "",
  },
};

const AddressReducer = (state = initialState, action) => {
  let returnObj = state;

  if (action.type === ADD_ADDRESS) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === ADD_ADDRESS_SUCCESS) {
    const addressList = state?.addressList;
    returnObj = {
      ...state,
      addressList: [...addressList, action?.payload?.response?.data?.data],
      loading: false,
    };
  }

  if (action.type === ADD_ADDRESS_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error?.data },
    };
  }
  if (action.type === GET_ADDRESS_LIST) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === GET_ADDRESS_LIST_SUCCESS) {
    returnObj = {
      ...state,
      addressList: action?.payload?.data,
      loading: false,
    };
  }

  if (action.type === GET_ADDRESS_LIST_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error},
    };
  }

  if (action.type === DELETE_ADDRESS) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === DELETE_ADDRESS_SUCCESS) {
    returnObj = {
      ...state,
      addressList: state?.addressList?.filter(
        (address) => address?.id !== action?.payload
      ),
      loading: false,
    };
  }

  if (action.type === DELETE_ADDRESS_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error?.data },
    };
  }

  if (action.type === GET_ADDRESS) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === GET_ADDRESS_SUCCESS) {
    returnObj = {
      ...state,
      address: action?.payload,
      loading: false,
    };
  }

  if (action.type === GET_ADDRESS_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.data?.message },
    };
  }

  if (action.type === UPDATE_ADDRESS) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === UPDATE_ADDRESS_SUCCESS) {
    const updated = state?.addressList?.map((address) => {
      return address?.id === action?.payload?.payload?.id
        ? action?.payload?.payload
        : address;
    });

    returnObj = {
      ...state,
      addressList: [...updated],
      loading: false,
    };
  }

  if (action.type === UPDATE_ADDRESS_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error?.data },
    };
  }

  if (action.type === CLEAR_ADDRESS) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === CLEAR_ADDRESS_SUCCESS) {
    returnObj = {
      ...state,
      addressList: [],
      address: {},
      loading: false,
    };
  }

  return returnObj;
};

export default AddressReducer;
