import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, styled, Typography, Box, Grid } from "@mui/material";

import InputMask from "react-input-mask";
import * as Yup from "yup";
import { useFormik, FormikProvider, Form } from "formik";
import { passwordForgot, resetError } from "../../store/auth/actions";
import ConfirmPassword from "./ConfirmPassword";
import PageWrapper from "components/wrapper/PageWrapper";
import CustomLoadingButton from "components/ui/CustomLoadingButton";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    width: "90%",
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
}));

const initialState = {
  mob_no: "",
};

const ForgotPassword = () => {
  const [initFormData] = useState(initialState);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isConfirmPass, setConfirmPass] = useState(false);

  const validationSchema = Yup.object({
    mob_no: Yup.string()
      .required("Mobile no is required")
      .min(10)
      .label("Mobile Number"),
  });

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const onSubmit = (formData) => {
    setLoading(true);

    const userData = {
      mobile: formData.mob_no.replaceAll(/\s/g, ""),
    };

    dispatch(
      passwordForgot({
        data: userData,
        callback: ({ error, message }) => {
          if (error) {
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.error,
              })
            );
          } else {
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.success,
              })
            );
            setConfirmPass(true);
          }
          setLoading(false);
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    formik;

  return (
    <PageWrapper>
      <Grid item pr={1} className="psw-chage">
        <Typography
          component="h1"
          variant="h5"
          sx={{
            margin: "auto",
            display: "flex",
            width: "85%",
            marginLeft: "38px",
          }}
        >
          Forgot Password
        </Typography>

        {isConfirmPass ? (
          <ConfirmPassword />
        ) : (
          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="forgot-passoord">
              <InputMask
                mask="9999 999 999"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mob_no}
                maskChar=""
              >
                {() => (
                  <CustomTextField
                    id="mob_no"
                    label="Mobile no"
                    name="mob_no"
                    autoComplete="mobile-no"
                    margin="normal"
                    // autoFocus
                    fullWidth
                    error={errors.mob_no && touched.mob_no}
                    helperText={
                      errors.mob_no && touched.mob_no ? errors.mob_no : null
                    }
                  />
                )}
              </InputMask>

              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomLoadingButton
                  variant="contained"
                  loading={loading}
                  label="Submit"
                  type="submit"
                  onClick={handleSubmit}
                  sx={{
                    mt: 2,
                    mb: 2,
                    px: 3,
                    py: 1,
                    color: "#fff",
                    boxShadow: "none",
                  }}
                />
              </Box>
            </Form>
          </FormikProvider>
        )}
      </Grid>
    </PageWrapper>
  );
};

export default ForgotPassword;
