import { SNACKBAR_OPEN } from "store/actionTypes";

const INIT_STATE = {
  snackbarMessage: "",
  snackbarOpen: false,
  snackbarType: "",
};

const SnackbarReducer = (state = INIT_STATE, action) => {
  let returnObj = state;

  if (action.type === SNACKBAR_OPEN) {
    returnObj = {
      ...state,
      snackbarMessage: action.data.message,
      snackbarOpen: action.data.open,
      snackbarType: action.data.type,
    };
  }
  return returnObj;
};

export default SnackbarReducer;
