import React from "react";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = (props) => {
  const {
    sx,
    type,
    size,
    label,
    variant,
    onClick,
    fullWidth,
    disabled = false,
    textColor = "white",
    className,
  } = props;

  return (
    <Button
      type={type}
      variant={variant}
      sx={{
        ...sx,
        color: textColor,
        boxShadow: "none",
        whiteSpace: "nowrap",
      }}
      onClick={onClick}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      className={className}
    >
      {label}
    </Button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.any,
  sx: PropTypes.object,
  type: PropTypes.string,
  size: PropTypes.string,
  hover: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
};

export default CustomButton;
