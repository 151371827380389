import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  // Menu,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Drawer,
  TextField,
  InputAdornment,
} from "@mui/material";

import { getItemsByCat, getPopularPairing } from "services/ItemsService";
import { getLoginUserOrderHistory } from "services/OrderService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "utils/api";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import Cookies from "js-cookie";

const searchCats = ["Item", "Popular Pairing", "History"];
const searchCatss = ["Item", "Popular Pairing"];

const SearchBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [itemList, setItemList] = useState([]);
  const [inputText, setInputText] = useState("");
  const [activeState, setActiveState] = useState("Item");
  const [showDrawer, setShowDrawer] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [popularPairingList, setPopularPairingList] = useState([]);
  const Token= Cookies.get('token')
  useEffect(() => {
    if (showDrawer) {
      setActiveState("Item");
    }
  }, [showDrawer]);

  const inputHandler = (e) => {
    setInputText(e.target.value);
  };

  const loggedUserID = useSelector(
    (state) => state?.AuthReducer?.user?.data?.id
  );

  useEffect(() => {
    if (showDrawer && Token) {
      Promise.all([
        getItemsByCat(),
        getLoginUserOrderHistory(),
        getPopularPairing(true),
      ])
        .then(([itemList, { data }, res]) => {
          setPopularPairingList(res.data);
          setItemList(itemList);
          setOrderHistory(data);
        })
        .catch(() =>
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while fetching item data !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          )
        );
    }else{
      Promise.all([
        getItemsByCat(),
        getPopularPairing(true),
      ])
        .then(([itemList, res]) => {
          setPopularPairingList(res.data);
          setItemList(itemList);
        })
        .catch(() =>
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while fetching item data !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          )
        );
}
  }, [loggedUserID, activeState, showDrawer,Token]);

  const handleItemClick = (id) => {
    navigate(`/item/${id}`);
  };

  const filteredItems = itemList?.filter((item) => {
    const itemName = item?.name?.toLowerCase();

    if (!inputText) {
      return itemName;
    } else {
      return itemName?.includes(inputText.toLowerCase());
    }
  });

    const filteredHistoryData =  orderHistory?.filter((order) => {
      const lwrName = order?.name?.toLowerCase();
      if (!inputText) {
        return lwrName;
      } else {
        return (lwrName?.includes(inputText?.toLowerCase()))
      }
    });
 
  const filteredPopularPairing = popularPairingList?.filter((item) => {
    const lwrName = item?.name?.toLowerCase();

    if (!inputText) {
      return lwrName;
    } else {
      return lwrName?.includes(inputText?.toLowerCase());
    }
  });

 
  useEffect(()=>{
    if(activeState === "Item" && searchedData.length === 0 && showDrawer === true)
    {
      setSearchedData(filteredItems);
    }
  },[showDrawer,searchedData])
 
  useEffect(() => {
    if (activeState === "Item") {
      setSearchedData(filteredItems);
    } else if (activeState === "History") {
      setSearchedData(filteredHistoryData);
    } else if (activeState === "Popular Pairing") {
      setSearchedData(filteredPopularPairing);
    } else {
      setSearchedData([]);
    }
  }, [activeState, inputText ]);

  const handleDrawerClose = (id) => {
    setSearchedData([]);
    setInputText("");
    id && navigate(`/item/${id}`);
    setShowDrawer(false);
  };


  return (
    <>
      <Drawer
        anchor="top"
        open={showDrawer}
        onClose={() => handleDrawerClose()}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "85%", md: "40%" },
            margin: "auto",
            padding: "1rem",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            position: "relative",
            my: "auto",
            overflow: "hidden",
            marginTop: "10px",
            borderRadius: 1,
          },
        }}
        className="drawer-container"
      >
          <IconButton   sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      }}
      className="icon-close-btn"
        onClick={()=>setShowDrawer(false)} size="small">
                  <CloseIcon sx={{ color: "black" }}  />
                </IconButton>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            position: "sticky",
            zIndex: 10,
            bgcolor: "white",
          }}
         
        >
          <TextField
            placeholder="Search here"
            fullWidth
            autoFocus
            onChange={inputHandler}
            value={inputText}
            sx={{
              borderRadius: "15px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment className="clear-icon-btn">
                  <IconButton onClick={() => setInputText("")} size="small"  >
                    <CloseIcon sx={{ color: "black" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            position: "relative",
            overflow: "overlay",
            maxHeight: "430px",
            overflowX: "hidden",
            "::-webkit-scrollbar": { display: "none" },
          }}
          
        >
           <Box
            sx={{
              zIndex: 10,
              display: "flex",
              top: "0px",
              position: "sticky",
              width: { xs: "86%", md: "92%" },
            }}
            className="seach-popup-btn"
            >
            {Token ? ( 
              <>
            {searchCats?.map((data) => (
            <Box
                key={data}
                p={1}
                sx={{
                  bgcolor: "white",
                  width: "33%",
                  minWidth: "33%",
                  justifyContent: "center",
                  display: "flex",
                }}
                
                >
                <Chip
                  variant={activeState === data ? "filled" : "outlined"}
                  label={data}
                  textColor="black"
                  onClick={() => setActiveState(data)}
                  sx={{
                    border: 0,
                    borderRadius: "5px",
                    transformOrigin: "298px 0px",
                    width: "100%",
                    bgcolor:
                    activeState === data
                    ? (theme) => theme.palette.primary.main
                    : (theme) => theme.palette.tertiary.main,
                    ":hover": {
                      bgcolor:
                      activeState === data
                      ? (theme) => theme.palette.primary.main
                      : (theme) => theme.palette.tertiary.main,
                    },
                  }}
                 
                  />
              </Box>
              ))}
              </>
          ) : (  
            <>
            {searchCatss?.map((data) => (
              <Box
              key={data}
                p={1}
                sx={{
                  bgcolor: "white",
                  width: "33%",
                  minWidth: "33%",
                  justifyContent: "center",
                  display: "flex",
                }}
                className="item-search"
                
                >
                <Chip
                  variant={activeState === data ? "filled" : "outlined"}
                  label={data}
                  textColor="black"
                  onClick={() => setActiveState(data)}
                  sx={{
                    border: 0,
                    borderRadius: "5px",
                    transformOrigin: "298px 0px",
                    width: "100%",
                    bgcolor:
                    activeState === data
                    ? (theme) => theme.palette.primary.main
                    : (theme) => theme.palette.tertiary.main,
                    ":hover": {
                      bgcolor:
                      activeState === data
                      ? (theme) => theme.palette.primary.main
                      : (theme) => theme.palette.tertiary.main,
                    },
                  }}
                  className="search-cat-chips"
                  />
              </Box>
              ))}
              </>
              )}
          </Box>

          <Box sx={{ padding: { xs: 0, md: "1rem" } }} className='mainItem'>
          <ul>
            {searchedData?.length
              ? searchedData?.map(({ name, index, id, images }) => (
                  <MenuItem key={index} onClick={() => handleDrawerClose(id)}>
                  
                  <Box display={"flex"}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "32px",
                          width: "32px",
                        }}
                      >
                        <Avatar
                          src={`${IMAGE_URL}/items/${
                            JSON.parse(images || "{}")[0]?.filename
                          }`}
                          sx={{
                            width: "95%",
                            height: "95%",
                          }}
                        />
                      </Box>
                      <Box display={"flex"} alignItems="center">
                        <Typography
                          onClick={() => handleItemClick(id)}
                          sx={{ ml: 1, fontSize: 12 }}
                        >
                          {name}
                        </Typography>
                      </Box>
                    </Box>
                    </MenuItem>
                    ))
                    : ""}
                    </ul>
          </Box>
        </Box>
      </Drawer>

      <IconButton
        aria-label="show 4 new mails"
        color="inherit"
        aria-controls="search-menu"
        aria-haspopup="true"
        onClick={() => setShowDrawer(true)}
        size="large"
        className="header-search-btn"
      >
        <SearchIcon sx={{ p: 0 }}  className="search" />
      </IconButton>
    </>
  );
};

export default SearchBar;
