import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Button,
  TextField,
  styled,
  Typography,
  Box,
  Grid,
  Chip,
  Container,
  Checkbox,
} from "@mui/material";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import * as Yup from "yup";
import { useFormik, FormikProvider, Form } from "formik";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { upadateUser } from "services/UserService";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  addAddress,
  getAddress,
  getAddressList,
  updateAddress,
} from "store/address/actions";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import AddressMap from "./AddressMap";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getProfile } from "store/auth/actions";
import "../manageaddress.css";

const addressTypes = ["Home", "Work", "Other"];

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: 5,
    width: "100%",
    borderColor: (theme) => theme.palette.secondary.main,
  },
}));

const initialState = {
  address_type: "",
  address_one: "",
  address_two: "",
  zipcode: "",
};

const ManageAddress = (props) => {
  const { mode } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const apiCall = useRef(false);
  const inputRef = useRef(initialState);
  const user = useSelector((state) => state?.AuthReducer?.user?.data);
  const userAddress = useSelector((state) => state?.AddressReducer?.address);
  const addressReducer = useSelector((state) => state?.AddressReducer);

  const [defaultChecked, setDefaultChecked] = useState(false);
  const [initFormData, setInitFormData] = useState(initialState);
  setInitFormData;
  const [addressType, setAddressType] = useState(addressTypes[0]);
  const [cords, setCords] = useState({
    lat: 23.044246,
    lng: 72.4820587,
  });

  const validationSchema = Yup.object({
    address_one: Yup.string().required("Address is required "),
    zipcode: Yup.string()
      .required("Zipcode is required ")
      .min(6)
      .label("Zipcode "),
  });

  const handleAddressType = (type) => {
    // addressType.current = type;
    setAddressType(type);
  };

  useEffect(() => {
    // if (apiCall.current) {
    if (id) {
      dispatch(
        getAddress({
          id: id,
          callback: ({ error }) => error && navigate("/address"),
        })
      );
    }
    // }
    // apiCall.current = true;
  }, [id]);

  const selectedAddress = (address) => {
    const addressArr = address[0]?.address_components || [];
    setInitFormData({
      ...initFormData,
      address_one: addressArr[0].long_name + addressArr[1].long_name,
      address_two: addressArr[addressArr.length - 4].long_name,
      zipcode: addressArr[addressArr.length - 1].long_name,
    });
  };

  useEffect(() => {
    inputRef.current.address_one.focus();
    inputRef.current.address_one.value = "";
    inputRef.current.address_two.value = "";
    inputRef.current.zipcode.value = "";
  }, []);
  useEffect(() => {
    if (
      initFormData.address_one?.value != "" &&
      initFormData.address_two?.value != "" &&
      initFormData.zipcode?.value != ""
    ) {
      inputRef.current.address_one.value = initFormData.address_one;
      inputRef.current.address_two.value = initFormData.address_two;
      inputRef.current.zipcode.value = initFormData.zipcode;
    }
  }, [initFormData]);
  useEffect(() => {
    if (id) {
      const user = {
        address_one: userAddress?.address_one,
        address_two: userAddress?.address_two,
        zipcode: userAddress?.zipcode,
      };

      // addressType.current = userAddress?.address_type;
      setAddressType(userAddress?.address_type);
      setInitFormData(user);

      setCords({
        lat: parseFloat(userAddress?.lat),
        lng: parseFloat(userAddress?.long),
      });
    }
  }, [userAddress]);

  useEffect(() => {
    if (
      user?.address_id &&
      userAddress?.id &&
      user?.address_id === userAddress?.id
    ) {
      setDefaultChecked(true);
    } else {
      setDefaultChecked(false);
    }
  }, [userAddress?.id, user?.id]);

  const cookieToken = document?.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith("token="))
    ?.split("=")[1];

  const onSubmit = () => {
    const data = {
      address_type: addressType,
      address_one: inputRef.current.address_one.value,
      address_two: inputRef.current.address_two.value,
      zipcode: inputRef.current.zipcode.value,
      lat: cords?.lat,
      long: cords?.lng,
    };

    if (!id && mode === "Add" && user.id) {
      dispatch(
        addAddress({
          data,
          callback: ({ res, error }) => {
            if (!error) {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "New address has been added !",
                  type: SNACKBAR_MESSAGE_TYPE.success,
                })
              );
              if (defaultChecked) {
                const formData = new FormData();
                formData.append("address_id", res?.id);
                upadateUser({ formData, id: user?.id }).then(() => {
                  dispatch(getProfile({ token: cookieToken }));
                });
              }
              navigate("/address");
            } else {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "Something went wrong while adding new address !",
                  type: SNACKBAR_MESSAGE_TYPE.error,
                })
              );
            }
          },
        })
      );
    } else {
      dispatch(
        updateAddress({
          data,
          id,
          callback: ({ error }) => {
            if (!error) {
              if (defaultChecked) {
                const formData = new FormData();
                formData.append("address_id", id);
                upadateUser({ formData, id: user?.id }).then(() => {
                  dispatch(getProfile({ token: cookieToken }));
                  navigate("/address");
                });
              } else {
                navigate("/address");
                dispatch(
                  snackbarOpen({
                    open: true,
                    message: "Adderess has been updated !",
                    type: SNACKBAR_MESSAGE_TYPE.success,
                  })
                );
              }
            } else {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "Something went wrong while updating address !",
                  type: SNACKBAR_MESSAGE_TYPE.error,
                })
              );
            }
          },
        })
      );
    }

    setDefaultChecked(false);
  };

  const handleBlur = async () => {
    const str = `${inputRef.current.address_one.value},${inputRef.current.address_two.value},${inputRef.current.zipcode.value} `;
    const results = await getGeocode({ address: str });
    if (results.length > 0) {
      setCords(getLatLng(results[0]));
    }
  };

  useEffect(() => {
    // if (apiCall.current) {
    if (!addressReducer?.addressList?.length && user) {
      dispatch(
        getAddressList({
          callback: ({ error, status }) => {
            if (error && status != 404) {
              dispatch(
                snackbarOpen({
                  open: true,
                  message: "Something went wrong while fetching address list !",
                  type: SNACKBAR_MESSAGE_TYPE.error,
                })
              );
            }
          },
        })
      );
    }
    // }
    // apiCall.current = true;
  }, [addressReducer?.addressList, user]);

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: () => onSubmit(),
  });

  const { handleSubmit, errors, touched } = formik;

  const handleChecked = () => {
    setDefaultChecked(!defaultChecked);
  };

  // GET CURRENT LOCATION

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(function ({ coords }) {
  //   });
  // }, []);

  const AddressMapComponent = useMemo(
    () => (
      <AddressMap
        setCords={setCords}
        cords={cords}
        selectedAddress={selectedAddress}
      />
    ),
    [cords]
  );

  return (
    <Box mt={5} mb={6}>
      <Container maxWidth="sm" className="address-detail">
        <Grid
          item
          md={6}
          pr={1}
          m={3}
          sx={{ display: "grid", justifyContent: "flex-end" }}
        >
          <Typography component="h1" variant="h5" textAlign={"center"}>
            {mode === "Add" ? "Add new address" : "Update Address"}
          </Typography>

          <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} className="update-address">
              <Grid container spacing={3}>
                <Grid item md={6} xs={12} sm={12} lg={6} xl={6}>
                  {AddressMapComponent}
                </Grid>
                <Grid item md={6} xs={12} sm={12} lg={6} xl={6}>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Grid mt={2}>
                        {addressTypes?.map((address) => (
                          <Chip
                            onClick={() => handleAddressType(address)}
                            variant={
                              addressType === address ? "filled" : "outlined"
                            }
                            label={address}
                            key={address}
                            color="primary"
                            sx={{ mr: 1 }}
                            className={addressType === address ? "address-type-btn active" : "address-type-btn"}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <CustomTextField
                    margin="normal"
                    id="address_one"
                    label="Address One"
                    name="address_one"
                    autoComplete="address_one"
                    required
                    fullWidth
                    inputRef={(ref) => (inputRef.current.address_one = ref)}
                    onBlur={handleBlur}
                    error={errors.address_one && touched.address_one}
                    helperText={
                      errors.address_one && touched.address_one
                        ? errors.address_one
                        : null
                    }
                  />

                  <CustomTextField
                    margin="normal"
                    id="address_two"
                    label="Address Two"
                    name="address_two"
                    autoComplete="address_two"
                    fullWidth
                    inputRef={(ref) => (inputRef.current.address_two = ref)}
                    onBlur={handleBlur}
                    error={errors.address_two && touched.address_two}
                    helperText={
                      errors.address_two && touched.address_two
                        ? errors.address_two
                        : null
                    }
                  />

                  <CustomTextField
                    margin="normal"
                    id="zipcode"
                    label="ZipCode"
                    name="zipcode"
                    autoComplete="zipcode"
                    required
                    fullWidth
                    inputRef={(ref) => (inputRef.current.zipcode = ref)}
                    onBlur={handleBlur}
                    error={errors.zipcode && touched.zipcode}
                    helperText={
                      errors.zipcode && touched.zipcode ? errors.zipcode : null
                    }
                  />

                  {user?.address_id !== userAddress?.id ? (
                    <Box display={"flex"} alignItems="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleChecked}
                              checked={defaultChecked}
                            />
                          }
                          label="Set as default address"
                        />
                      </FormGroup>
                    </Box>
                  ) : null}

                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderRadius: 1,
                      px: 3,
                      py: 1,
                      color: "#fff",
                      boxShadow: "none",
                      width: "105px",
                    }}
                    className="update-address-btn"
                  >
                    {mode === "Add" ? "Add" : "Update"}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => navigate("/address")}
                    sx={{
                      mt: 2,
                      mb: 2,
                      borderRadius: 1,
                      px: 3,
                      py: 1,
                      color: "#fff",
                      boxShadow: "none",
                      ml: 2,
                    }}
                    className="update-address-btn"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Grid>
      </Container>
    </Box>
  );
};

ManageAddress.propTypes = {
  mode: PropTypes.string,
};

export default ManageAddress;
