import Cookies from "js-cookie";
import { call, put, takeLatest } from "redux-saga/effects";

import { api } from "../../utils/api";
import { endPoints } from "../../utils/endPoints";
import {
  GET_PROFILE,
  GOOGLE_LOGIN_VERIFICATION,
  LOGIN_USER,
  LOGOUT_USER,
  REQ_REGISTER,
  REQ_RESEND_OTP,
  REQ_VERIFY_OTP,
  PASSWORD_FORGOT,
  CONFIRM_PASSWORD,
  CHANGE_PASSWORD,
  FACEBOOK_LOGIN_VERIFICATION,
} from "../actionTypes";
import {
  getProfileFailed,
  getProfileSuccess,
  googleLoginVerificationFailed,
  googleLoginVerificationSuccess,
  loginUserFailed,
  loginUserSuccess,
  logoutUserFailed,
  logoutUserSuccess,
  registerFailed,
  registerSuccess,
  resendOTPFailed,
  resendOTPSuccess,
  verifyOTPFailed,
  verifyOTPSuccess,
  passwordForgotFailed,
  passwordForgotSuccess,
  confirmPasswordFailed,
  confirmPasswordSuccess,
  changePasswordFailed,
  changePasswordSuccess,
  facebookLoginVerificationFailed,
  facebookLoginVerificationSuccess,
} from "./actions";
import { addToCart, clearCartSuccess } from "store/cart/actions";

const TOKEN = "token";

function* loginUser({ payload }) {
  try {
    const response = yield call(api.post, endPoints.USER_LOGIN, payload.data);
    Cookies.set(TOKEN, response.data.access_token, { path: "/" });
    let cartData = JSON.parse(localStorage.getItem('cart'));
    yield put(loginUserSuccess(response.data));
    yield put(addToCart({ data: cartData, isLoggedIn: 'true' }))
  } catch (error) {
    yield put(
      loginUserFailed({ error: error.response, callback: payload.callback })
    );
  }
}

function* getProfile({ payload }) {
  try {
    const response = yield call(api.get, endPoints.GET_PROFILE, payload);
    yield put(getProfileSuccess(response.data));
  } catch (error) {
    yield put(getProfileFailed(error));
  }
}

function* googleLoginVerification(payload) {
  try {
    const response = yield call(
      api.post,
      endPoints.googleRefreshToken,
      payload.payload
    );
    Cookies.set(TOKEN, response.data.data.access_token, { path: "/" });
    yield put(googleLoginVerificationSuccess(response.data));
  } catch (error) {
    yield put(googleLoginVerificationFailed(error));
  }
}

function* facebookLoginVerification(payload) {
  try {
    const response = yield call(
      api.post,
      endPoints.facebookLoginVerification,
      { 'access_token': payload.payload.access_token }
    );
    Cookies.set(TOKEN, response.data.data.access_token, { path: "/" });
    yield put(facebookLoginVerificationSuccess(response.data));
  } catch (error) {
    yield put(facebookLoginVerificationFailed(error));
  }
}

function* register({ payload }) {
  try {
    const response = yield call(api.post, endPoints.REGISTER, payload.data);

    yield put(
      registerSuccess({ response: response.data, callback: payload.callback })
    );
  } catch (error) {
    yield put(
      registerFailed({ error: error.response, callback: payload.callback })
    );
  }
}

function* verifyOTP({ payload }) {
  try {
    const response = yield call(api.post, endPoints.VERIFY_OTP, payload.data);

    yield put(
      verifyOTPSuccess({ response: response.data, callback: payload.callback })
    );
  } catch (error) {
    yield put(
      verifyOTPFailed({ error: error.response, callback: payload.callback })
    );
  }
}

function* resendOTP({ payload }) {
  try {
    const response = yield call(api.post, endPoints.RESEND_OTP, payload.data);

    yield put(
      resendOTPSuccess({ response: response.data, callback: payload.callback })
    );
  } catch (error) {
    yield put(
      resendOTPFailed({
        error: error?.response?.data,
        callback: payload?.callback,
      })
    );
  }
}

function* logoutUser() {
  try {
    const response = yield call(api.post, endPoints.USER_LOGOUT);
    Cookies.remove(TOKEN, { path: "/" });
    localStorage.removeItem("promoID");
    localStorage.removeItem("addressID");
    localStorage.removeItem("my_address_id");
    yield put(logoutUserSuccess(response.data));
    yield put(clearCartSuccess())
  } catch (error) {
    yield put(logoutUserFailed({ error: error.response }));
  }
}

function* passwordForgot({ payload }) {
  try {
    const response = yield call(
      api.post,
      endPoints.FORGOT_PASSWORD,
      payload.data
    );

    if (response) {
      yield put(
        passwordForgotSuccess({
          response: response.data,
          callback: payload.callback,
        })
      );
      yield put(
        resendOTPSuccess({
          response: response.data,
          callback: payload.callback,
        })
      );
    }
  } catch (error) {
    yield put(
      passwordForgotFailed({
        error: error.response,
        callback: payload.callback,
      })
    );
  }
}

function* confirmPassword({ payload }) {
  try {
    const response = yield call(
      api.post,
      endPoints.CONFIRM_PASSWORD,
      payload.data
    );

    yield put(
      confirmPasswordSuccess({
        response: response.data,
        callback: payload.callback,
      })
    );
  } catch (error) {
    yield put(
      confirmPasswordFailed({
        error: error.response.data,
        callback: payload.callback,
      })
    );
  }
}

function* changePassword({ payload }) {
  try {
    const response = yield call(
      api.post,
      endPoints.CHANGE_PASSWORD,
      payload.data
    );

    if (response) {
      yield put(
        changePasswordSuccess({
          response: response.data,
          callback: payload.callback,
        })
      );
    }
  } catch (error) {
    yield put(
      changePasswordFailed({
        error: error.response,
        callback: payload.callback,
      })
    );
  }
}

function* AuthSaga() {
  yield takeLatest(GOOGLE_LOGIN_VERIFICATION, googleLoginVerification);
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(GET_PROFILE, getProfile);
  yield takeLatest(REQ_REGISTER, register);
  yield takeLatest(REQ_VERIFY_OTP, verifyOTP);
  yield takeLatest(REQ_RESEND_OTP, resendOTP);
  yield takeLatest(LOGOUT_USER, logoutUser);
  yield takeLatest(PASSWORD_FORGOT, passwordForgot);
  yield takeLatest(CONFIRM_PASSWORD, confirmPassword);
  yield takeLatest(CHANGE_PASSWORD, changePassword);
  yield takeLatest(FACEBOOK_LOGIN_VERIFICATION, facebookLoginVerification);
}

export default AuthSaga;
