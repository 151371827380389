import React from "react";
import PropTypes from "prop-types";
import Geocode from "react-geocode";
import {
  Marker,
  GoogleMap,
  withScriptjs,
  // InfoWindow,
  withGoogleMap,
} from "react-google-maps";

import { Skeleton } from "@mui/material";
import { useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";

const GoogleMapsAPI = process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyCQ7NpAn-3OKyjhvW4dZn33-uuFXZQSlKM';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

const Map = (props) => {
  const { cords, zoom, setCords } = props;

  const dispatch = useDispatch();

  // const initialValues = {
  //   address: "",
  //   city: "",
  //   area: "",
  //   state: "",
  // };

  // const [markerInfo, setMarkerInfo] = useState(initialValues);

  // useEffect(() => {
  //   Geocode.fromLatLng(cords?.lat, cords?.lng)
  //     .then(({ results }) => {
  //       const address = results[0].formatted_address,
  //         addressArray = results[0].address_components,
  //         city = getCity(addressArray),
  //         area = getArea(addressArray),
  //         state = getState(addressArray),
  //         lat = results[0]?.geometry?.location?.lat,
  //         lng = results[0]?.geometry?.location?.lng;

  //       setCords({ lat: lat, lng: lng });

  //       setMarkerInfo(() => ({
  //         address: address ? address : "",
  //         area: area ? area : "",
  //         city: city ? city : "",
  //         state: state ? state : "",
  //       }));
  //     })
  //     .catch(() =>
  //       dispatch(
  //         snackbarOpen({
  //           open: true,
  //           message: "Provided coordinates are invalid !",
  //           type: SNACKBAR_MESSAGE_TYPE.error,
  //         })
  //       )
  //     );
  // }, []);

  // const getCity = (addressArray) => {
  //   let city = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     if (
  //       addressArray[i].types[0] &&
  //       "administrative_area_level_2" === addressArray[i].types[0]
  //     ) {
  //       city = addressArray[i].long_name;
  //       return city;
  //     }
  //   }
  // };

  // const getArea = (addressArray) => {
  //   let area = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     if (addressArray[i].types[0]) {
  //       for (let j = 0; j < addressArray[i].types.length; j++) {
  //         if (
  //           "sublocality_level_1" === addressArray[i].types[j] ||
  //           "locality" === addressArray[i].types[j]
  //         ) {
  //           area = addressArray[i].long_name;
  //           return area;
  //         }
  //       }
  //     }
  //   }
  // };

  // const getState = (addressArray) => {
  //   let state = "";
  //   for (let i = 0; i < addressArray.length; i++) {
  //     for (let i = 0; i < addressArray.length; i++) {
  //       if (
  //         addressArray[i].types[0] &&
  //         "administrative_area_level_1" === addressArray[i].types[0]
  //       ) {
  //         state = addressArray[i].long_name;
  //         return state;
  //       }
  //     }
  //   }
  // };

  // const onInfoWindowClose = (event) => {
  // };

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng)
      .then(({ results }) => {
        const // address = results[0].formatted_address,
          //   addressArray = results[0].address_components,
          //   city = getCity(addressArray),
          //   area = getArea(addressArray),
          //   state = getState(addressArray),
          lat = results[0]?.geometry?.location?.lat,
          lng = results[0]?.geometry?.location?.lng;

        setCords({ lat: lat, lng: lng });

        // setMarkerInfo({
        //   address: address ? address : "",
        //   area: area ? area : "",
        //   city: city ? city : "",
        //   state: state ? state : "",
        // });
      })
      .catch(() =>
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching cords",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        )
      );
  };

  // const onPlaceSelected = (place) => {
  //   const address = place.formatted_address,
  //     addressArray = place.address_components,
  //     city = getCity(addressArray),
  //     area = getArea(addressArray),
  //     state = getState(addressArray),
  //     latValue = place.geometry.location.lat(),
  //     lngValue = place.geometry.location.lng();

  //   setMarkerInfo({
  //     address: address ? address : "",
  //     area: area ? area : "",
  //     city: city ? city : "",
  //     state: state ? state : "",
  //     markerPosition: {
  //       lat: latValue ? latValue : cords.lat,
  //       lng: lngValue ? lngValue : cords.lng,
  //     },
  //     mapPosition: {
  //       lat: latValue ? latValue : cords.lat,
  //       lng: lngValue ? lngValue : cords.lng,
  //     },
  //   });
  // };

  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={zoom}
        mapContainerStyle={{ width: "100vh", height: "100vh" }}
        defaultCenter={{
          lat: cords?.lat,
          lng: cords?.lng,
        }}
        options={{
          // zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        }}
      >
        {/* <InfoWindow
          onClose={onInfoWindowClose}
          position={{
            lat: mapProps?.markerPosition?.lat,
            lng: mapProps?.markerPosition?.lng,
          }}
        >
          <span
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            {mapProps?.address}
          </span>
        </InfoWindow> */}

        <Marker
          draggable={true}
          onDragEnd={onMarkerDragEnd}
          position={{
            lat: cords.lat,
            lng: cords.lng,
          }}
        />
      </GoogleMap>
    ))
  );

  let map;
  if (cords?.lat !== undefined) {
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
        loadingElement={
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="auto"
            height={400}
          />
        }
        containerElement={<div style={{ width: "auto", height: 400 }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    );
  } else {
    map = (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="auto"
        height={400}
      />
    );
  }
  return map;
};

Map.propTypes = {
  zoom: PropTypes.number,
  cords: PropTypes.object,
  setCords: PropTypes.func,
};

export default Map;
