import React, { useEffect, useState } from "react";
import { CardMedia, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { IMAGE_URL } from "utils/api";
import { PropTypes } from "prop-types";
import { getBanner } from "services/BannerService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DefaultImg from "../../../assets/images/defaultimages.jpg";
// import Banner from "../../../assets/images/Banner1.png";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardDoubleArrowRightIcon
      color="primary"
      className={className}
      sx={{
        width: "6rem",
        fontSize: "3rem",
        top: "50%",
        "&:hover": { color: "#000" },
      }}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardDoubleArrowLeftIcon
      color="primary"
      className={className}
      sx={{
        width: "6rem",
        fontSize: "3rem",
        zIndex: 9,
        top: "50%",
        "&:hover": { color: "#000" },
      }}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

var settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  autoplay: false,
  autoplaySpeed: 4000,
  variableWidth: false,
  adaptiveHeight: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Section0 = () => {
  const [loading, setLoading] = useState(true);
  const [bannerList, setBannerList] = useState([]);
  // const renderAfterCalled = useRef(false);

  useEffect(() => {
    // if (!renderAfterCalled.current) {
    getBanner()
      .then((res) => {
        setLoading(false);
        setBannerList(res);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
    // }
    // renderAfterCalled.current = true;
  }, []);

  return (
    <Box className="home-box">
      <Slider {...settings}>
        {loading ? (
          <Box
            sx={{
              display: "flex !important",
              justifyContent: "center",
            }}
          >
            <CircularProgress sx={{ py: { xs: 10, md: 30 } }} />
          </Box>
        ) : bannerList?.length ? (
          bannerList?.map((banner) =>
            banner?.status === "Active" ? (
              <Box
                sx={{
                  display: "flex !important",
                  justifyContent: "center",
                  height: { xs: "auto", md: window.innerHeight - 120 },
                }}
                key={banner?.id}
              >
                <CardMedia
                  component="img"
                  image={
                    banner?.image
                      ? `${IMAGE_URL}/banner/${banner.image}`
                      : DefaultImg
                  }
                  width="80%"
                  alt="Live from space album cover"
                />
              </Box>
            ) : null
          )
        ) : null}
      </Slider>
    </Box>
  );
};

export default Section0;
