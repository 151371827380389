import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBHXFLXKjJgf1F_zjXWldvYMaUxHEnanTI',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'fir-push-notification-be99d.firebaseapp.com',
  projectId: process.env.REACT_APP_FIREBASE_PORJECT_ID || 'fir-push-notification-be99d',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'fir-push-notification-be99d.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID || '199506466108',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:199506466108:web:666488684f714aa260ba39',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-M7HDYKRBQE',
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY || 'BHr1BnJENpa23tih9mpF7MIOmBG4Li6Pu2Hqs6gp7cnEOnlL6_rVCc3vn7O0MlTjZJo93OuG1jQsrPBhF4-hKXg',
    });

    return currentToken;
  } catch (err) {
    console.error("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
