
import React from "react";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { experimentalStyled } from "@mui/material";
import Footer from "../components/ui/Footer";
import Navbar from "../components/ui/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/auth/actions";


const MainWrapper = experimentalStyled("div")(() => ({
  // minHeight: "100vh",
  minHeight: "calc(100vh - 40vh)",
  overflow: "hidden",
  width: "100%",
}));

const PageWrapper = experimentalStyled("div")(() => ({
  display: "block",
  flex: "1 1 auto",
  overflow: "hidden",
  marginTop: "80px",
  width: "100%",
}));

const pvtRoutes = [
  "/user-account",
  "/checkout",
  "/order-history",
  "/address",
  "/manage-address",
  "/loyality-point",
];

const protectedRoutes = ["/forgot-password"];

const Layout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const apiCall = useRef(false);

  const cookieToken = document?.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith("token="))
    ?.split("=")[1];

  const token = useSelector((state) => state?.AuthReducer?.token);

  useEffect(() => {
    // if (!apiCall.current) {
    if (cookieToken) {
      dispatch(getProfile({ token: cookieToken }));
    }
    // }
    // apiCall.current = true;
  }, [cookieToken]);

  if (!token && !cookieToken && pvtRoutes.includes(pathname)) {
    // window.history.go("/");
    return <Navigate to="/" replace />;
  }
  if (token && cookieToken && protectedRoutes.includes(pathname)) {
    // window.history.go("/");
    return <Navigate to="/" replace />;
  }

  const location = useLocation();

  return (
    <React.Fragment>
      <MainWrapper className="main-header-up">
        <Navbar />
        <PageWrapper sx={{ mt: location?.pathname === "/" ? "0px" : "103px" }} className=
          "main-body-content body-custom">
          <Outlet />
        </PageWrapper>
      </MainWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
