import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART,
  CLEAR_CART_SUCCESS,
  DELETE_CART_ITEM,
  GET_CART_DATA,
  GET_CART_DATA_SUCCESS,
} from "store/actionTypes";

const initialState = {
  cart: JSON.parse(localStorage.getItem("cart")) || [],
  loading: false,
  error: {
    message: "",
    type: "",
  },
};

const CartReducer = (state = initialState, action) => {
  let returnObj = state;

  if (action.type === ADD_TO_CART) {
    returnObj = { ...state, loading: true };
  }
  if (action.type === ADD_TO_CART_SUCCESS) {
    returnObj = { ...state, cart: action?.payload, loading: false };
  }
  if (action.type === CLEAR_CART) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === CLEAR_CART_SUCCESS) {
    returnObj = { ...state, cart: [], loading: false };
  }

  if (action.type === GET_CART_DATA) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === GET_CART_DATA_SUCCESS) {
    returnObj = { ...state, cart: action?.payload, loading: false };
  }

  if (action.type === DELETE_CART_ITEM) {
    console.log("🚀 ~ CartReducer ~ action.payload.data:", action.payload.data);
    console.log("🚀 ~ CartReducer ~ state.cart:", state.cart);
    if (localStorage.getItem("isLoggedIn") === "true") {
      let updatedCart = state.cart.filter(
        (item) => item.id !== action.payload.data
      );
      returnObj = { ...state, cart: updatedCart, loading: true };
    } else {
      let updatedCart = state.cart.filter(
        (item) => item?.size_price_id !== action?.payload?.data?.size_price_id
      );
      console.log("🚀 ~ CartReducer ~ updatedCart:", updatedCart);
      returnObj = { ...state, cart: updatedCart, loading: true };
    }
  }

  return returnObj;
};

export default CartReducer;
