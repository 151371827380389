import React from "react";
import { Box, Card, CardMedia, Typography, CardContent } from "@mui/material";
import FoodImage1 from "../../../assets/images/foodappnotification1.png";
import FoodImage2 from "../../../assets/images/foodappnotification2.png";
import FoodImage3 from "../../../assets/images/foodappnotification3.png";

const NotificationList = () => {
  return (
    <>
      <Box>
        <Card sx={{ display: "flex", boxShadow: "none" }}>
          <Box>
            <CardMedia
              component="img"
              height="100px"
              sx={{
                width: "100%",
                bgcolor: " #faa148",
                marginTop: "15px",
                marginLeft: "5px",
              }}
              src={FoodImage1}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                Order
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Order Accpeted by restaurant
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              marginInline: "auto",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              September,14 2016
            </Typography>
          </Box>
        </Card>

        <Card sx={{ display: "flex", mt: 2, boxShadow: "none" }}>
          <Box>
            <CardMedia
              component="img"
              height="100px"
              sx={{
                width: "90%",
                bgcolor: " #faa148",
                marginTop: "15px",
                marginLeft: "5px",
              }}
              src={FoodImage2}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                Offer
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Get voucher upto 20% by restuarant. Get Now!
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              marginInline: "auto",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              September,14 2016
            </Typography>
          </Box>
        </Card>

        <Card sx={{ display: "flex", mt: 2, boxShadow: "none" }}>
          <Box>
            <CardMedia
              component="img"
              height="100px"
              sx={{
                width: "100%",
                bgcolor: " #faa148",
                marginTop: "15px",
                marginLeft: "5px",
              }}
              src={FoodImage3}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                Update
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Weekly maintenance schedule
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              marginInline: "auto",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              September,14 2016
            </Typography>
          </Box>
        </Card>

        <Card sx={{ display: "flex", mt: 2, boxShadow: "none" }}>
          <Box>
            <CardMedia
              component="img"
              height="100px"
              sx={{
                width: "100%",
                bgcolor: " #faa148",
                marginTop: "15px",
                marginLeft: "5px",
              }}
              src={FoodImage1}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                Order
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Order Accpeted by restaurant
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </Typography>
            </CardContent>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              marginInline: "auto",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              September,14 2016
            </Typography>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default NotificationList;
