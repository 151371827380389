import React from "react";
import Map from "./Map";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";

const AddressMap = (props) => {
  const { cords, setCords, selectedAddress } = props;

  const PlacesAutocomplete = ({ setCords }) => {
    const {
      // ready status false on refresh
      ready,
      value,
      setValue,
      suggestions: { data },
      clearSuggestions,
    } = usePlacesAutocomplete();

    const placeSuggestions = data?.map(({ place_id, description }) => ({
      id: place_id,
      label: description,
    }));

    const handleSelect = async (e, { label }) => {
      setValue(label);
      clearSuggestions();
      const results = await getGeocode({ address: label });
      selectedAddress(results);
      const { lat, lng } = getLatLng(results[0]);
      setCords({ lat, lng });
    };

    // const handleDragMap = (result) =>{

    // }

    const handleChange = (e) => {
      setValue(e.target.value)
    }
    return (
      <Autocomplete
        onChange={(e, address) => handleSelect(e, address)}
        options={placeSuggestions}
        disabled={!ready}
        renderInput={(params) => (
          <TextField
            value={value}
            {...params}
            label="Search Address"
            onChange={handleChange}
            className="auto-field"
          />
        )}
        sx={{ mb: 2, mt: 1 }}
      />
    );
  };

  PlacesAutocomplete.propTypes = {
    setCords: PropTypes.any,
  };

  return (
    <div>                  
      <PlacesAutocomplete setCords={setCords} />
      <Map cords={cords} setCords={setCords} zoom={15} />
    </div>
  );
};

AddressMap.propTypes = {
  setCords: PropTypes.func,
  cords: PropTypes.object,
  selectedAddress: PropTypes.func
};

export default AddressMap;
