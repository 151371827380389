import React, { useEffect, useState } from "react";
import {
  CardActionArea,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import LoyalityPoint1 from "../../../assets/images/Loyalitypoint1.png";
import LoyalityPoints from "../../../assets/images/Loyaltypoints.png";
import LoyalityPoint2 from "../../../assets/images/Loyaltypoints2.png";
import { getLoyalityPoint } from "services/LoyaltyPointService";

const ManageLoyalityPoint = () => {
  const [loyalityPoint, setLoyalityPoint] = useState([]);
  const userData = useSelector((state) => state?.AuthReducer?.user?.data?.id);

  useEffect(() => {
    if (userData) {
      getLoyalityPoint(userData)
        .then(({ data }) => {
          setLoyalityPoint(data);
        })
        .catch((err) => {
          return err;
        });
    }
  }, [userData]);

  return (
    <Box>
      <Box>
        {loyalityPoint?.map((data, index) => (
          <Box key={index}>
            <Card
              sx={{
                maxWidth: 600,
                height: 200,
                marginInline: " auto",
                mt: 2,
                backgroundColor: "#fbf0e5",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="100px"
                  src={LoyalityPoint1}
                  sx={{ width: "90%", marginTop: "10px", marginLeft: "30px" }}
                />
                <Box alignItems="center" justifyContent="center" display="flex">
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      Total Earn Points
                    </Typography>
                    <Grid>
                      <Typography
                        variant="body2"
                        mt={1}
                        ml={10}
                        sx={{ fontWeight: "bold", fontSize: "20px" }}
                      >
                        {data?.earn_point}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Box>
                ;
              </CardActionArea>
            </Card>

            <Card
              sx={{
                maxWidth: 600,
                height: 200,
                marginInline: " auto",
                mt: 2,
                backgroundColor: "#fbf0e5",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="100"
                  src={LoyalityPoint2}
                  sx={{ width: "90%", marginTop: "10px", marginLeft: "30px" }}
                />
                <Box alignItems="center" justifyContent="center" display="flex">
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      Reddem Point
                    </Typography>
                    <Typography
                      variant="body2"
                      mt={1}
                      ml={8}
                      sx={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      0{/* {data?.redeem_point} */}
                    </Typography>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
            <Card
              sx={{
                maxWidth: 600,
                height: 200,
                marginInline: " auto",
                mt: 2,
                backgroundColor: "#fbf0e5",
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="100"
                  src={LoyalityPoints}
                  sx={{ width: "90%", marginTop: "10px", marginLeft: "30px" }}
                />
                <Box alignItems="center" justifyContent="center" display="flex">
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      Remaining Point
                    </Typography>
                    <Typography
                      variant="body2"
                      mt={1}
                      ml={8}
                      sx={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      0{/* {data?.remaining_point} */}
                    </Typography>
                  </CardContent>
                </Box>
              </CardActionArea>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ManageLoyalityPoint;
