import React from "react";
import { Box } from "@mui/material";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";

const ContactUs = () => {
  return (
    <Box sx={{ padding: 0, width: "100%" }} className="contact-us">
      <Section1 />
      <Section2 />
    </Box>
  );
};

export default ContactUs;
  