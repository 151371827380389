import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// const StyledDialogTitle = (props) => {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };

// StyledDialogTitle.propTypes = {
//   children: PropTypes.node,
//   onClose: PropTypes.func.isRequired,
// };

const CustomizedDialog = (props) => {
  const { handleClose, open, children, contentStyle, overflow } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
      className="list-items"
      sx={{ m: 0 }}
    >
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 1,
            top: 1,
            color: "grey",
            zIndex: 999,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <DialogContent
        sx={{
          padding: 0,
          overflow: overflow,
          ...contentStyle,
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

CustomizedDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  overflow: PropTypes.string,
  handleClose: PropTypes.func,
  contentStyle: PropTypes.object,
};

export default CustomizedDialog;
