import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const getRestraunts = async () => {
  try {
    const { data } = await api.get(endPoints.GET_RESTRAUNTS);

    return data.data;
  } catch (error) {
    throw Error(error);
  }
};
