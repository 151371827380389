import { put, takeLatest } from "redux-saga/effects";
import { SOCKET_GET_DRIVE_POSITION, SOCKET_GET_ORDER } from "store/actionTypes";
import { getSocketDriverPositionFail, getSocketDriverPositionSuccess, getSocketOrderFail, getSocketOrderSuccess } from "./socketAction";

function* getSocketOrder({ data }) {
  try {
    yield put(getSocketOrderSuccess(data));
  } catch (error) {
    yield put(getSocketOrderFail(error));
  }
}

function* getSocketDriverPosition( {data} ) {
  try {
    yield put(getSocketDriverPositionSuccess(data));
  } catch (error) {
    yield put(getSocketDriverPositionFail(error));
  }
}

function* SocketSaga() {
  yield takeLatest(SOCKET_GET_ORDER, getSocketOrder);
  yield takeLatest(SOCKET_GET_DRIVE_POSITION, getSocketDriverPosition);
}

export default SocketSaga;
