import { Box, Snackbar, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { getSocketOrder } from "store/socket/socketAction";
import { useEffect } from "react";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { getOrder } from "services/OrderService";
import { orderStatusMobile } from "utils/objectMapping";

const disallowedRoutes = ["/menu", "/cart"];
const orderStatus = ["Delivered", "Cancelled"];

const TrackingSnackbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const orderID = parseInt(localStorage.getItem("orderID"));

  const socketOrder = useSelector((state) => state.SocketReducer.order);
  const userData = useSelector((state) => state.AuthReducer.user.data);

  const [open, setOpen] = useState(true);
  const [orderData, setOrderData] = useState({});

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOrderData(socketOrder);
  }, [socketOrder]);

  useEffect(() => {
    if (orderID && userData && !socketOrder?.id) {
      getOrder(orderID)
        .then(({ data }) => {
          if (
            data?.user_id === userData?.id &&
            !orderStatus?.includes(data?.order_status)
          ) {
            setOrderData(data);
          } else {
            setOrderData({});
            localStorage.removeItem("orderID");
            // dispatch(
            //   snackbarOpen({
            //     open: true,
            //     message: "Something went wrong while fetching order data !",
            //     type: SNACKBAR_MESSAGE_TYPE.warning,
            //   })
            // );
          }
          dispatch(getSocketOrder({ data }));
        })
        .catch(() => {
          setOrderData({});
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while tracking order !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
  }, [orderID, userData]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      sx={{
        display: {
          xs:
            userData?.id &&
            orderData?.id &&
            !disallowedRoutes.includes(pathname) &&
            pathname !== `/track-order/${orderData?.id}` &&
            !orderStatus?.includes(orderData?.order_status)
              ? "block"
              : "none",
          md: "none",
        },
        width: "100%",
        left: 0,
        right: 0,
        bottom: 0,
      }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          p: 1,
          height: 30,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>{orderData?.Restaurant?.name}</Typography>
          {orderStatusMobile(orderData?.order_status)}
        </Box>
        <KeyboardArrowRightIcon
          onClick={() => navigate(`/track-order/${orderID}`)}
        />
      </Box>
    </Snackbar>
  );
};

export default TrackingSnackbar;
