import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, styled, TextField, Box, Alert } from "@mui/material";
import PropTypes from "prop-types";

import { otpTimer } from "utils/constants";
import { resendOTP, verifyOTP } from "store/auth/actions";
import CustomLoadingButton from "components/ui/CustomLoadingButton";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    width: "30px",
    height: "50px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13.5px 14px",
    textAlign: "center",
    maxWidth: "18px",
  },
}));

const OTP = (props) => {
  const regEmail = useSelector((state) => state?.AuthReducer?.user?.email);
  const { setSignupMode, setLoginMode, type } = props;

  const dispatch = useDispatch();

  const [OTP, setOTP] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(otpTimer);

  const handleChange = (event) => {
    setOTP((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const digit1 = useRef(null);
  const digit2 = useRef(null);
  const digit3 = useRef(null);
  const digit4 = useRef(null);
  const digit5 = useRef(null);
  const digit6 = useRef(null);

  const handleOTP = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "digit2") {
        digit1.current.focus();
      }
      if (e.target.name === "digit3") {
        digit2.current.focus();
      }
      if (e.target.name === "digit4") {
        digit3.current.focus();
      }
      if (e.target.name === "digit5") {
        digit4.current.focus();
      }
      if (e.target.name === "digit6") {
        digit5.current.focus();
      }
    } else {
      if (e.target.name === "digit1" && e.target.value) {
        digit2.current.focus();
      }
      if (e.target.name === "digit2" && e.target.value) {
        digit3.current.focus();
      }
      if (e.target.name === "digit3" && e.target.value) {
        digit4.current.focus();
      }
      if (e.target.name === "digit4" && e.target.value) {
        digit5.current.focus();
      }
      if (e.target.name === "digit5" && e.target.value) {
        digit6.current.focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const userOTP =
      OTP?.digit1 +
      OTP?.digit2 +
      OTP?.digit3 +
      OTP?.digit4 +
      OTP?.digit5 +
      OTP?.digit6;

    if (userOTP) {
      setLoading(true);
      const data = { email: regEmail, otp: userOTP };

      dispatch(
        verifyOTP({
          data,
          callback: (response) => {
            setLoading(false);
            if (!response.error) {
              setLoginMode(!response.error);
              setSignupMode(response.error);
            } else {
              setError(response.message);
              setSuccess("");
            }
          },
        })
      );
    } else {
      setError("Please enter valid OTP !");
      setSuccess();
    }
  };

  const handleResendOTP = (event) => {
    setLoading(true);
    event.preventDefault();

    const data = { email: regEmail, otp_type: type ? type : "REGISTER" };

    dispatch(
      resendOTP({
        data,
        callback: (response) => {
          setLoading(false);
          setCounter(otpTimer);
          if (!response.error) {
            setSuccess(response.message);
            setError("");
          } else {
            setError(response.message);
            setSuccess("");
          }
        },
      })
    );
  };

  return (
    <>
      <Typography sx={{ fontSize: { xs: 18, md: "large" } }} variant="h5">
        Enter OTP recieved on email address provided by you.
      </Typography>
      <Grid>
        <Box display="flex" sx={{ width: "60%" }}>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit1"
              name="digit1"
              type="text"
              autoFocus
              inputRef={digit1}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit2"
              name="digit2"
              inputRef={digit2}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit3"
              name="digit3"
              inputRef={digit3}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit4"
              name="digit4"
              inputRef={digit4}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit5"
              name="digit5"
              inputRef={digit5}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
          <Box>
            <CustomTextField
              margin="normal"
              required
              fullWidth
              id="digit6"
              name="digit6"
              inputRef={digit6}
              onChange={handleChange}
              onKeyUp={handleOTP}
              inputProps={{ maxLength: 1 }}
              sx={{ mr: 5 }}
            />
          </Box>
        </Box>

        <Grid>
          <CustomLoadingButton
            label="Submit"
            variant="contained"
            type="submit"
            loading={loading}
            onClick={handleSubmit}
            sx={{
              my: 2,
              borderRadius: 1,
              mr: 1,
              px: 3,
              py: 1,
              color: "#fff",
              boxShadow: "none",
            }}
          />

          <CustomLoadingButton
            label={
              counter
                ? counter % 60 < 10
                  ? `${Math.floor(counter / 60)} : ` + "0" + (counter % 60)
                  : `${Math.floor(counter / 60)} : ` + (counter % 60)
                : "Resend OTP"
            }
            variant="contained"
            disabled={counter}
            type="submit"
            loading={loading}
            onClick={handleResendOTP}
            sx={{
              my: 2,
              borderRadius: 1,
              px: 3,
              py: 1,
              color: "#fff",
              boxShadow: "none",
              // display: counter ? "none" : "inline-block",
            }}
          />
        </Grid>

        <Box sx={{ minHeight: "70px", width: "100%" }}>
          {error ? <Alert severity="error">{error}</Alert> : null}
          {success ? <Alert severity="success">{success}</Alert> : null}
        </Box>
      </Grid>
    </>
  );
};

OTP.propTypes = {
  setSignupMode: PropTypes.func,
  setLoginMode: PropTypes.func,
  type: PropTypes.string,
};

export default OTP;
