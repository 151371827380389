import {
  ADD_TO_CART,
  ADD_TO_CART_SUCCESS,
  CLEAR_CART,
  CLEAR_CART_SUCCESS,
  DELETE_CART_ITEM,
  GET_CART_DATA,
  GET_CART_DATA_SUCCESS,
} from "../actionTypes";

export const addToCart = (data) => {
  return {
    payload: data,
    type: ADD_TO_CART,
  };
};

export const addToCartSuccess = (data) => {
  localStorage.setItem("cart", JSON.stringify(data));
  return {
    payload: data,
    type: ADD_TO_CART_SUCCESS,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};

export const clearCartSuccess = () => {
  localStorage.setItem("cart", JSON.stringify([]));
  return {
    type: CLEAR_CART_SUCCESS,
  };
};

export const getCartData = () => {
  return {
    type: GET_CART_DATA,
  };
};

export const getCartDataSuccess = (data) => {
  localStorage.setItem("cart", JSON.stringify(data));
  return {
    payload: data,
    type: GET_CART_DATA_SUCCESS,
  };
};

export const deleteCartItem = (data) => {
  if (localStorage.getItem("isLoggedIn") === "true") {
    let cartData = JSON.parse(localStorage.getItem("cart"));
    let updatedCart = cartData.filter((item) => item.id != data.data);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  } else {
    let cartData = JSON.parse(localStorage.getItem("cart"));
    let updatedCart = cartData.filter(
      (item) => item.size_price_id !== data?.data?.size_price_id
    );
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  }
  return {
    payload: data,
    type: DELETE_CART_ITEM,
  };
};
