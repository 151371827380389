import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  styled,
  Alert,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useFormik, FormikProvider, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { otpTimer } from "utils/constants";

import { Box } from "@mui/system";
import { confirmPassword } from "store/auth/actions";
import CustomizedDialog from "components/ui/Dialog";
import Authentication from "./Authentication";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import CustomLoadingButton from "components/ui/CustomLoadingButton";

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    backgroundColor: (theme) => theme.palette.secondary.main,
    border: "3px solid  #ded5cc",
    width: "100%",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      border: "3px solid #faa148",
    },
  },
}));

const CustomTextFields = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    width: "17px",
    height: "45px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13.5px 8px",
    textAlign: "center",
    maxWidth: "18px",
  },
}));

const initialState = {
  password: "",
  otp: "",
  email: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required()
    .min(8)
    .label("Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$$/,
      "Password should contains atleast 8 charaters and containing uppercase,lowercase, numbers and special character !"
    ),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password doest not match")
    .label("Confirm password"),
});

const ConfirmPassword = () => {
  const regEmail = useSelector((state) => state?.AuthReducer?.user?.email);

  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");

  const [OTP, setOTP] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initFormData] = useState(initialState);
  const [dialogOpenAuth, setDialogOpenAuth] = useState(false);
  const [showRegPassword, setShowRegPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const digit1 = useRef(null);
  const digit2 = useRef(null);
  const digit3 = useRef(null);
  const digit4 = useRef(null);
  const digit5 = useRef(null);
  const digit6 = useRef(null);

  const handleOTP = (e) => {
    if (e.key === "Backspace") {
      if (e.target.name === "digit2") {
        digit1.current.focus();
      }
      if (e.target.name === "digit3") {
        digit2.current.focus();
      }
      if (e.target.name === "digit4") {
        digit3.current.focus();
      }
      if (e.target.name === "digit5") {
        digit4.current.focus();
      }
      if (e.target.name === "digit6") {
        digit5.current.focus();
      }
    } else {
      if (e.target.name === "digit1" && e.target.value) {
        digit2.current.focus();
      }
      if (e.target.name === "digit2" && e.target.value) {
        digit3.current.focus();
      }
      if (e.target.name === "digit3" && e.target.value) {
        digit4.current.focus();
      }
      if (e.target.name === "digit4" && e.target.value) {
        digit5.current.focus();
      }
      if (e.target.name === "digit5" && e.target.value) {
        digit6.current.focus();
      }
    }
  };

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    formik;

  const onSubmit = (values) => {
    setLoading(true);

    const userOTP =
      OTP?.digit1 +
      OTP?.digit2 +
      OTP?.digit3 +
      OTP?.digit4 +
      OTP?.digit5 +
      OTP?.digit6;

    values.email = regEmail;
    const data = {
      otp: userOTP,
      password: values.password,
      email: values.email,
    };

    // use foreach

    dispatch(
      confirmPassword({
        data: data,
        callback: ({ error, message }) => {
          setLoading(false);
          if (error) {
            dispatch(
              snackbarOpen({
                open: true,
                message: message,
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
          } else {
            dispatch(
              snackbarOpen({
                open: true,
                message: "Password has been changed successfully !",
                type: SNACKBAR_MESSAGE_TYPE.success,
              })
            );
            setDialogOpenAuth(true);
          }
        },
      })
    );
  };

  const handleClickShowRegPassword = () => {
    setShowRegPassword(!showRegPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangesOTP = (event) => {
    setOTP((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDialogCloseNew = () => {
    setDialogOpenAuth(false);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item md={12} pr={1}>
              <Box
                display="flex"
                sx={{ width: "80%", margin: "auto", display: "flex" }}
              >
                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    id="digit1"
                    name="digit1"
                    type="text"
                    inputRef={digit1}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{
                      maxLength: 1,
                    }}
                    sx={{
                      marginRight: 5,
                      textAlign: "center",
                      width: "25px",
                    }}
                  />
                </Box>

                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    fullWidth
                    id="digit2"
                    name="digit2"
                    inputRef={digit2}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{ maxLength: 1 }}
                    sx={{ marginRight: 5, textAlign: "center" }}
                  />
                </Box>

                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    fullWidth
                    id="digit3"
                    name="digit3"
                    inputRef={digit3}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{ maxLength: 1 }}
                    sx={{ marginRight: 5, textAlign: "center" }}
                  />
                </Box>

                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    fullWidth
                    id="digit4"
                    name="digit4"
                    inputRef={digit4}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{ maxLength: 1 }}
                    sx={{ marginRight: 5, textAlign: "center" }}
                  />
                </Box>

                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    fullWidth
                    id="digit5"
                    name="digit5"
                    inputRef={digit5}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{ maxLength: 1 }}
                    sx={{ marginRight: 5, textAlign: "center" }}
                  />
                </Box>

                <Box>
                  <CustomTextFields
                    margin="normal"
                    required
                    autoComplete="off"
                    fullWidth
                    id="digit6"
                    name="digit6"
                    inputRef={digit6}
                    onChange={handleChangesOTP}
                    onKeyUp={handleOTP}
                    inputProps={{ maxLength: 1 }}
                    sx={{ marginRight: 5, textAlign: "center" }}
                  />
                </Box>
              </Box>

              <Box sx={{ width: "80%", margin: "auto", marginLeft: "30px" }}>
                <CustomTextField
                  id="outlined-end-adornment"
                  name="password"
                  value={values.password}
                  label="Password"
                  type={showRegPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRegPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showRegPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </Box>

              <Box sx={{ width: "80%", margin: "auto", marginLeft: "30px" }}>
                <CustomTextField
                  id="outlined-end-adornment"
                  name="confirm_password"
                  label="Confrim Password"
                  type={showConfirmPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.confirm_password && touched.confirm_password}
                  helperText={
                    errors.confirm_password && touched.confirm_password
                      ? errors.confirm_password
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {matches ? (
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CustomLoadingButton
                    variant="contained"
                    label="Submit"
                    loading={loading}
                    type="submit"
                    sx={{
                      mb: 2,
                      borderRadius: 1,
                      px: 3,
                      py: 1,
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    onClick={handleSubmit}
                  />
                </Box>
              ) : (
                <Box sx={{ margin: "auto", width: "85%", marginLeft: "32px" }}>
                  <CustomLoadingButton
                    variant="contained"
                    label="Submit"
                    loading={loading}
                    type="submit"
                    sx={{
                      mb: 2,
                      borderRadius: 1,
                      px: 3,
                      py: 1,
                      color: "#fff",
                      boxShadow: "none",
                    }}
                    onClick={handleSubmit}
                  />
                </Box>
              )}

              <Box sx={{ minHeight: "70px", width: "100%" }}>
                {errors.password && touched.password ? (
                  <Alert severity="error">
                    <ErrorMessage name="password" />
                  </Alert>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>

      <CustomizedDialog
        handleClose={handleDialogCloseNew}
        open={dialogOpenAuth}
        onClick={() => {}}
        onCancel={handleDialogCloseNew}
      >
        <Authentication handleDialogClose={handleDialogCloseNew} />
      </CustomizedDialog>
    </>
  );
};

export default ConfirmPassword;
