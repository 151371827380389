import React, { useEffect, useState } from "react";
import {
  CardMedia,
  Divider,

  Typography,
  Box,
  Button,
  Container,
} from "@mui/material";
import Banner from "../../../assets/images/Banner1.png";
import { useNavigate } from "react-router-dom";
import { getbestdiscountofday } from "services/DiscountService";
import { useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import '../home.css';

const Section1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const renderAfterCalled = useRef(false);
  const [discount, setDiscount] = useState({});

  useEffect(() => {
    // if (!renderAfterCalled.current) {
    getbestdiscountofday()
      .then((data) => {
        setDiscount(data);
      })
      .catch(() => {
        dispatch(
          snackbarOpen({
            open: true,
            message:
              "Something went wrong while fetching best deals of the day !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
      });
    // }
    // renderAfterCalled.current = true;
  }, [setDiscount]);

  return (
    <Box >
      <Box
        sx={{ width: "100%" }}
        container
        align="center"
        justify="center"
        className="p-80 main-discount-section"
      >
        <Container maxWidth="xl">
          <Box
            item
            xs={12}
            md={6}
            justifyContent="space-between"  
            display="flex"
            alignItems="center"       
            sx={{ height: "600px" }}
            className=""
          >
              <Box alignItems="center" sx={{ padding: 1 }} className="discount-c">
                {discount?.message ? (
                  <>
                    <Typography variant="h2">{discount?.message}</Typography>
                    <Typography variant="h3">
                      {discount?.data?.description}
                    </Typography>
                    
                    <Typography
                      sx={{ color: "#bf4e49", fontWeight: "bold", mt: 1 }}
                    >
                      <span>Promocode :</span>&nbsp; 
                        {discount?.data?.promocode}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h4">Are you hungry ?</Typography>
                    <Divider />

                    <Typography variant="h6">
                      LETS START TO ORDER FOOD NOW
                    </Typography>
                  </>
                )}

                {/* <Divider sx={{ mt: 4 }} />  
                {discount?.data?.description ? (
                ) : (
                )} */}

                <Typography variant="h5">Order Now</Typography>

              
                <Box>
                  <Button
                    sx={{ backgroundColor: "#faa148" }}
                    variant="contained"
                    onClick={() => navigate("/menu")}
                  >
                    VIEW MENU
                  </Button>
                  {/* <Button
                    sx={{ margin: 1, backgroundColor: "#fff", color: "#000" }}
                    variant="contained"
                  >
                    ORDER NOW
                  </Button> */}
                </Box>
              </Box>
              <Box
              item
              xs={12}
              md={6}
              justifyContent="center"
              display="flex"
              alignItems="center"
              sx={{ height: "600px" }}
            >
              <Box className="food-img bounce" alignItems="center" sx={{ padding: 1 }}>
                <CardMedia
                  component="img"
                  src={Banner}
                  alt="Live from space album cover"
                  sx={{ width: "80%" }}
                />
              </Box>
              </Box>
          </Box>
        </Container> 
      </Box>
    </Box>
  );
};

export default Section1;
