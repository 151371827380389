import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Slide, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";

const SnackbarNotification = () => {
  const snackbar = useSelector((state) => state.SnackbarReducer);
  const dispatch = useDispatch();

  const matches = useMediaQuery("(max-width:600px)");

  const xsSnackbar = { vertical: "top", horizontal: "center" };
  const mdSnackbar = { vertical: "bottom", horizontal: "right" };

  const handleClose = () => {
    dispatch(
      snackbarOpen({ open: false, message: "", type: snackbar.snackbarType })
    );
  };

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  return (
    <Snackbar
      anchorOrigin={matches ? xsSnackbar : mdSnackbar}
      open={snackbar.snackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      sx={{
        display: snackbar.snackbarMessage ? "block" : "none",
      }}
      TransitionComponent={matches ? TransitionDown : TransitionUp}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={snackbar.snackbarType === "" ? "info" : snackbar.snackbarType}
        onClose={handleClose}
      >
        {snackbar.snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
export default SnackbarNotification;
