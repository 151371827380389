import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "./components/DataTable";
import PageWrapper from "../../components/wrapper/PageWrapper";
import CartSummary from "./components/CartSummary";
import { getCartData } from "store/cart/actions";

const Cart = () => {
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem('isLoggedIn') || 'false'

  useEffect(() => {
    if (isLoggedIn == 'true') {
      dispatch(getCartData());
    }
  }, [isLoggedIn])

  const cartItems = useSelector((state) => state?.CartReducer?.cart)
  console.log("🚀 ~ Cart ~ cartItems:", cartItems)

  var totalPrice = cartItems?.reduce((acc, current) => {
    return acc?.defaultPrice
      ? acc?.defaultPrice
      : acc + current?.defaultPrice * current.quantity;
  }, 0);

  return (
    <PageWrapper className="cart-table">
      <Typography variant="h3">Cart</Typography>
      <Grid container align="center" justifyContent="center">
        <Grid md={12} mt={3} width="100%">
          <DataTable cartItems={cartItems} />
        </Grid>
      </Grid>
      {cartItems?.length ? <CartSummary totalPrice={totalPrice} /> : null}
    </PageWrapper>
  );
};

export default Cart;