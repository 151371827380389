const {
  SOCKET_GET_ORDER,
  SOCKET_GET_ORDER_SUCCESS,
  SOCKET_GET_ORDER_FAILED,
  SOCKET_GET_DRIVE_POSITION,
  SOCKET_GET_DRIVE_POSITION_SUCCESS,
  SOCKET_GET_DRIVE_POSITION_FAILED,
} = require("store/actionTypes");

const initialState = { order: {}, loading: false, error: "" };

const SocketReducer = (state = initialState, action) => {
  let returnObj = state;

  if (action.type === SOCKET_GET_ORDER) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === SOCKET_GET_ORDER_SUCCESS) {
    returnObj = { ...state, order: action.payload, loading: false, error: "" };
  }

  if (action.type === SOCKET_GET_ORDER_FAILED) {
    returnObj = { ...state, loading: false, error: action.error };
  }

  if (action.type === SOCKET_GET_DRIVE_POSITION) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === SOCKET_GET_DRIVE_POSITION_SUCCESS) {
    returnObj = { ...state, trackData: action.payload, loading: false, error: "" };
  }

  if (action.type === SOCKET_GET_DRIVE_POSITION_FAILED) {
    returnObj = { ...state, loading: false, error: action.error };
  }

  

  return returnObj;
};

export default SocketReducer;
