import React, { useEffect, useState } from "react";
import PageWrapper from "components/wrapper/PageWrapper";
import {
  OutlinedInput,
  Grid,
  Box,
  InputAdornment,
  Typography,
  ButtonGroup,
  CardMedia,
  useMediaQuery,
  Chip,
  IconButton,
  Tab,
  Tabs,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  FormLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

import { IMAGE_URL } from "utils/api";
import CartBar from "./components/CartBar";
import { addToCart, getCartData } from "store/cart/actions";
import CustomCard from "components/ui/CustomCard";
import CustomizedDialog from "components/ui/Dialog";
import CardSkeleton from "components/ui/CardSkeleton";
import { getItemsByCat, getAllCat } from "services/ItemsService";
import { addItemToCart, addItemToCartWithSize } from "utils/core-utils";
import CustomButton from "components/ui/CustomButton";
import Slider from "react-slick";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import DefaultFoodImg from "../../assets/images/default-food-img.png";

var settings = {
  infinite: true,
  adaptiveHeight: true,
  speed: 1000,
  slidesToShow: 12,
  slidesToScroll: 5,
  autoplaySpeed: 4000,
  nextArrow: false,
  prevArrow: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 9,
        infinite: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

const Menu = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state?.CartReducer?.cart);
  const [totalPriceOfpakage, setTotalPriceOfpakage] = useState(0);
  const [items, setItems] = useState([]);
  const [allItem, setAllItem] = useState([]);
  const [visible, setVisible] = useState(false);
  const [inputText, setInputText] = useState("");
  const [promptItem, setPromtItem] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sizedialogOpen, setSizeDialogOpen] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedOptinalItem, setSelectedOptionItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [openSubAccordion, setOpenSubAccordion] = useState(null);
  const [itemSizeValue, setItemSizeValue] = useState([]);
  const [sizeValue, setSizeValue] = useState("");
  const matches = useMediaQuery("(max-width:899px)");
  const array = [1, 2, 3, 4, 5, 6];

  const handleChangeForRelevantRadioBtn = (itemDetails, groupId) => {
    if (selectedOptinalItem.length > 0) {
      const index = selectedOptinalItem.findIndex(
        (item) => item.groupId === groupId
      );

      if (index !== -1) {
        selectedOptinalItem.splice(index, 1);
      }
    }
    setSelectedOptionItem((prevState) => [
      ...prevState,
      {
        itemDetails,
        groupId,
      },
    ]);
  };

  useEffect(() => {
    if (selectedItem) {
      let selectedItemData = items?.find((ele) => ele?.id == selectedItem);
      let totalPrice = 0;
      if (selectedOptinalItem?.length == 0) {
        if (selectedItemData?.is_package !== 0) {
          selectedItemData?.relevantitems?.map((ele, index) => {
            if (index != 0) {
              ele?.map((item, itemIndex) => {
                if (item?.price != null) {
                  let price = itemIndex == 0 ? item?.price : 0;
                  totalPrice = totalPrice + price;
                }
              });
            }
          });
          setTotalPriceOfpakage(totalPrice);
        }
      } else {
        let findTheSelectedItem = items?.find((ele) => ele.id == selectedItem);
        let initialItems = findTheSelectedItem?.relevantitems
          ?.map((ele, index) => {
            if (index != 0) {
              return {
                groupId: index,
                itemDetails: ele[0],
              };
            } else {
              return {
                groupId: index,
                itemDetails: ele,
              };
            }
          })
          .filter((item) => item !== undefined);
        let joinArr = [];
        if (selectedOptinalItem?.length == 0) {
          joinArr.push(initialItems);
        } else {
          let newArr = [];
          let selectedGroupId = selectedOptinalItem?.map(
            (item) => item.groupId
          );

          initialItems?.forEach((item) => {
            if (!selectedGroupId.includes(item.groupId)) {
              newArr.push(item);
            }
          });

          selectedOptinalItem?.forEach((item) => {
            if (!initialItems?.find((obj) => obj.groupId === item.groupId)) {
              newArr.push(item);
            }
          });
          if (newArr?.length > 0) {
            joinArr = [...selectedOptinalItem, ...newArr];
          }
        }
        let newArr = selectedOptinalItem?.length > 0 ? joinArr : joinArr[0];
        newArr?.filter((ele) => {
          if (ele.groupId != 0 && ele.itemDetails.price != null) {
            totalPrice = totalPrice + ele.itemDetails.price;
          }
        });
        setTotalPriceOfpakage(totalPrice);
      }
    }
  }, [selectedItem, selectedOptinalItem]);

  const inputHandler = (e) => {
    setInputText(e.target.value);
  };

  const getData = () => {
    Promise.all([getItemsByCat(id)])
      .then(([items]) => {
        setItems(items);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching item data !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
        return err;
      });
  };

  useEffect(() => {
    getData();
    Promise.all([getItemsByCat()]).then((data) => {
      setAllItem(...data);
    });
    Promise.all([getAllCat()]).then((data) => {
      setSubCategoryList(...data);
    });
  }, [id]);

  let isLoggedIn = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    if (isLoggedIn == "true") {
      dispatch(getCartData());
    }
  }, [isLoggedIn]);

  const onSubCategoryClick = (categoryId) => {
    setIsLoading(true);
    getItemsByCat(categoryId)
      .then((res) => {
        setItems(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });

    getAllCat().then((res) => {
      setSubCategoryList(res);
    });
  };
  useEffect(() => {
    const updateQty = {
      ...itemSizeValue,
      ItemSizePrices: itemSizeValue?.ItemSizePrices?.map((items) => {
        const carFind = cart?.find((item) => {
          return item.itemId === items.item_id &&
            item.size_price_id === items.size_id
            ? item
            : "";
        });
        if (
          carFind?.size_price_id === items.size_id &&
          carFind.itemId === items.item_id
        ) {
          return { ...items, quantity: carFind.quantity };
        }
        return items;
      }),
    };
    setItemSizeValue(updateQty);
  }, [cart, sizedialogOpen, selectedOptinalItem]);

  useEffect(() => scrollToTop(), [categoryId]);

  const handleCartClick = ({
    itemId,
    defaultPrice,
    type,
    size,
    relevantItems,
    ItemSizePrices,
    is_package,
    size_price_id,
  }) => {
    const cartItems = [...cart];
    setSelectedItem(itemId);
    setTotalPriceOfpakage(0);
    setSelectedOptionItem([]);
    localStorage.setItem("is_package", is_package);

    if (relevantItems?.length) {
      const item = items?.find(({ id }) => id === itemId);
      setPromtItem({
        id: item?.id,
        price: item?.price,
        itemName: item?.name,
        itemDescription: item?.description,
        relItems: item.relevantitems,
        ItemSizePrices: item?.ItemSizePrices,
      });
      setItemSizeValue({
        id: item?.id,
        price: item?.price,
        itemName: item?.name,
        itemDescription: item?.description,
        relItems: item.relevantitems,
        ItemSizePrices: item?.ItemSizePrices,
      });
      return setDialogOpen(true);
    }

    if (ItemSizePrices?.length) {
      const item = items?.find(({ id }) => id === itemId);
      setItemSizeValue({
        id: item?.id,
        price: item?.price,
        itemName: item?.name,
        itemDescription: item?.description,
        relItems: item.relevantitems,
        ItemSizePrices: item?.ItemSizePrices,
      });
      setPromtItem({
        id: item?.id,
        price: item?.price,
        itemName: item?.name,
        itemDescription: item?.description,
        relItems: item.relevantitems,
        ItemSizePrices: item?.ItemSizePrices,
      });
      return setSizeDialogOpen(true);
    }

    if (size) {
      const addedItems = addItemToCartWithSize(
        cartItems,
        itemId,
        size,
        defaultPrice,
        type,
        size_price_id
      );
      const addDetails = addedItems?.map((item) => {
        const findItem = allItem?.find(
          (current) => item?.itemId === current?.id
        );
        return {
          ...item,
          itemName: findItem?.name,
          itemDescription: findItem?.description,
          is_package: 0,
          itemImage: findItem?.images,
        };
      });

      dispatch(
        addToCart({
          data: addDetails
            .map((ele) => {
              if (ele.itemId == itemId) {
                return ele;
              }
            })
            .filter((ele) => ele != undefined),
          isLoggedIn: isLoggedIn,
          isCart: true,
        })
      );
    } else {
      const addedItems = addItemToCart(cartItems, itemId, defaultPrice, type);
      const addDetails = addedItems?.map((item) => {
        const findItem = allItem?.find(
          (current) => item?.itemId === current?.id
        );
        return {
          ...item,
          itemName: findItem?.name,
          itemDescription: findItem?.description,
          itemImage: findItem?.images,
          size_price_id:
            size_price_id === undefined ? defaultPrice : size_price_id,
          defaultPrice: sizeValue ? sizeValue?.price : defaultPrice,
        };
      });

      dispatch(
        addToCart({
          data: addDetails
            .map((ele) => {
              if (ele.itemId == itemId) {
                return ele;
              }
            })
            .filter((ele) => ele != undefined),
          isLoggedIn: isLoggedIn,
        })
      );
    }
  };

  const handleItemSize = (data) => {
    setSizeValue(data);
  };

  const handleCardClickForPackage = (item) => {
    let findTheSelectedItem = items?.find((ele) => ele.id == item.id);
    let initialItems = findTheSelectedItem?.relevantitems
      ?.map((ele, index) => {
        if (index != 0) {
          return {
            groupId: index,
            itemDetails: ele[0],
          };
        } else {
          return {
            groupId: index,
            itemDetails: ele,
          };
        }
      })
      .filter((item) => item !== undefined);
    let joinArr = [];
    if (selectedOptinalItem?.length == 0) {
      joinArr.push(initialItems);
      setSelectedOptionItem(initialItems);
    } else {
      let newArr = [];
      let selectedGroupId = selectedOptinalItem.map((item) => item.groupId);

      initialItems.forEach((item) => {
        if (!selectedGroupId.includes(item.groupId)) {
          newArr.push(item);
        }
      });

      selectedOptinalItem.forEach((item) => {
        if (!initialItems.find((obj) => obj.groupId === item.groupId)) {
          newArr.push(item);
        }
      });
      if (newArr.length > 0) {
        joinArr = [...selectedOptinalItem, ...newArr];
      }
      setSelectedOptionItem(...joinArr);
    }
    item.relItems = joinArr;
    const cartItems = [...cart];
    const addedItems = addItemToCart(cartItems, item.id, item.price, item.type);
    let newArr = selectedOptinalItem.length > 0 ? joinArr : joinArr[0];
    let relevant_item_ids = [[], []];
    for (let item of newArr) {
      if (Array.isArray(item.itemDetails)) {
        for (let detail of item.itemDetails) {
          relevant_item_ids[0].push(detail.id);
        }
      } else {
        relevant_item_ids[1].push(item.itemDetails.id);
      }
    }

    const addDetails = addedItems?.map((itemEle) => {
      const findItem = allItem?.find(
        (current) => itemEle.itemId === current?.id
      );
      return {
        ...itemEle,
        defaultPrice: findItem.price + totalPriceOfpakage,
        itemName: findItem?.name,
        itemDescription: findItem?.description,
        itemImage: findItem?.images,
        is_package: 1,
        relevantItems: relevant_item_ids,
        quantity: 1,
      };
    });
    let add_to_package_details = addDetails
      .map((ele) => {
        if (ele.itemId == item.id) {
          return ele;
        }
      })
      .filter((ele) => ele != undefined);
    if (add_to_package_details.length > 0 && add_to_package_details[0]?.id) {
      delete add_to_package_details[0].id;
    }
    dispatch(
      addToCart({
        data:
          add_to_package_details && add_to_package_details.length > 0
            ? [add_to_package_details[0]]
            : [],
        isLoggedIn: isLoggedIn,
      })
    );
    setPromtItem({
      id: item?.id,
      price: item?.price,
      itemName: item?.name,
      itemDescription: item?.description,
      relItems: [],
    });
    setSelectedOptionItem([]);
    return setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const totalQuantity = cart?.find(
    ({ itemId }) => itemId === promptItem?.id
  )?.quantity;

  const filteredData = items?.filter((item) => {
    const itemName = item?.name?.toLowerCase();
    const itemPrice = item?.price?.toString();

    if (!inputText) {
      return item;
    } else {
      return (
        itemName?.includes(inputText.toLowerCase()) ||
        itemPrice?.includes(inputText.toString())
      );
    }
  });

  const toggleVisible = () => {
    if (document.documentElement.scrollTop > 200) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <PageWrapper>
      <Grid
        container
        spacing={2}
        sx={{ mt: "100px" }}
        className="left-sidebar-section"
      >
        <Grid
          elevation={3}
          item
          md={4}
          lg={3}
          xl={3}
          xs={12}
          display={subCategoryList?.length ? "block" : "none"}
          className="left-s"
        >
          {matches ? (
            <Grid
              sx={{
                position: "fixed",
                zIndex: 50,
                backgroundColor: "white",
                marginTop: { xs: "-90px", md: "0px" },
              }}
              display={subCategoryList?.length ? "flex" : "none"}
              alignItems={"center"}
              width="90%"
              className="slider-m"
            >
              {subCategoryList?.length ? (
                <>
                  <Chip
                    label="All"
                    onClick={() => {
                      getData();
                      setCategoryId("");
                    }}
                    variant={!categoryId ? "contained" : "outlined"}
                    sx={{
                      bgcolor: !categoryId
                        ? (theme) => theme.palette.primary.main
                        : "",
                      fontSize: "15px",
                      mr: 0.5,
                    }}
                  />
                  <Slider {...settings} className="slick-slider-custom">
                    {subCategoryList?.map((category) => (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                        key={category?.id}
                      >
                        <Chip
                          label={category?.name}
                          variant={
                            categoryId === category?.id
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            onSubCategoryClick(category?.id);
                            setCategoryId(category?.id);
                          }}
                          sx={{
                            bgcolor:
                              categoryId === category?.id
                                ? (theme) => theme.palette.primary.main
                                : null,
                            m: 1,
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    ))}
                  </Slider>
                </>
              ) : null}
            </Grid>
          ) : (
            <Box
              sx={{
                position: "fixed",
                maxHeight: "78%",
                top: "22%",
                bottom: "0%",
              }}
              display={subCategoryList?.length ? "flex" : "none"}
              className="itemMenu"
            >
              {subCategoryList?.length ? (
                <Tabs orientation="vertical" variant="scrollable">
                  <Tab
                    label="All"
                    onClick={() => {
                      getData();
                      setCategoryId("");
                      setOpenAccordion(null);
                      setOpenSubAccordion(null);
                    }}
                    sx={{
                      bgcolor: !categoryId
                        ? (theme) => theme.palette.primary.main
                        : "",
                      borderRadius: 1,
                      fontWeight: "bold",
                    }}
                    className={
                      categoryId == "" ? "active allClass" : "deactive allClass"
                    }
                  />

                  {subCategoryList?.map((mainMenu) => (
                    <Accordion
                      key={mainMenu.id}
                      expanded={openAccordion === mainMenu.id}
                      className="mainAccordion"
                    >
                      <AccordionSummary
                        aria-controls={`panel${mainMenu.id}a-content`}
                        id={`panel${mainMenu.id}a-header`}
                        expandIcon={
                          mainMenu?.children.length > 0 ? (
                            <ExpandMoreIcon />
                          ) : null
                        }
                        className={
                          categoryId == mainMenu.id
                            ? "active accordionSummaryMain"
                            : "deactive accordionSummaryMain"
                        }
                        onClick={() => {
                          setOpenAccordion(
                            openAccordion === mainMenu.id ? null : mainMenu.id
                          );
                          setOpenSubAccordion(null);
                        }}
                      >
                        <Typography
                          onClick={() => {
                            onSubCategoryClick(mainMenu.id);
                            setCategoryId(mainMenu.id);
                          }}
                          className="accordionSummary"
                        >
                          {mainMenu.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container direction="column">
                          {mainMenu?.children?.map((subMenu) => (
                            <>
                              <Accordion
                                button
                                key={subMenu.id}
                                className="subAccordion"
                                expanded={openSubAccordion === subMenu.id}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    subMenu?.children.length > 0 ? (
                                      <ExpandMoreIcon />
                                    ) : null
                                  }
                                  onClick={() => {
                                    setOpenSubAccordion(
                                      openSubAccordion === subMenu.id
                                        ? null
                                        : subMenu.id
                                    );
                                  }}
                                >
                                  <Typography
                                    onClick={() => {
                                      onSubCategoryClick(subMenu.id);
                                      setCategoryId(subMenu.id);
                                    }}
                                    className={
                                      categoryId == subMenu.id
                                        ? "active innerAccordion"
                                        : "deactive innerAccordion"
                                    }
                                  >
                                    {subMenu.name}
                                  </Typography>
                                </AccordionSummary>
                                {subMenu?.children?.map((subSubMenu) => (
                                  <ListItem key={subSubMenu.id}>
                                    <Typography
                                      onClick={() => {
                                        onSubCategoryClick(subSubMenu.id);
                                        setCategoryId(subSubMenu.id);
                                      }}
                                      className={
                                        categoryId == subSubMenu.id
                                          ? "active subSubAccordion"
                                          : "deactive subSubAccordion"
                                      }
                                    >
                                      {subSubMenu.name}
                                    </Typography>
                                  </ListItem>
                                ))}
                              </Accordion>
                            </>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Tabs>
              ) : null}
            </Box>
          )}
        </Grid>

        <Grid
          item
          md={subCategoryList?.length ? 8 : 12}
          xs={12}
          lg={9}
          xl={9}
          className="item-list-detail"
        >
          <Grid container>
            <Grid item xl={12} lg={12}>
              <OutlinedInput
                placeholder="Search"
                onChange={inputHandler}
                className="search-option"
                sx={{
                  borderRadius: "30px",
                  marginBottom: {
                    xs: "20px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                  marginLeft: { xs: "6px", md: "40px", lg: "0px", xl: "0px" },
                  justifyContent: "flex-end",
                  display: "flex",
                  width: "280px",
                  float: "right",
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            {isLoading ? (
              array.map((index) => <CardSkeleton key={index} />)
            ) : filteredData?.length ? (
              filteredData?.map((item) => {
                const images = JSON.parse(item?.images || "{}");
                const itemImage = images?.length && images[0]?.filename;
                return (
                  <Grid
                    item
                    justifyContent="center"
                    display="flex"
                    key={item.id}
                    md={6}
                    sm={6}
                    xs={12}
                    lg={4}
                    xl={3}
                    className="custon-card-section"
                  >
                    <CustomCard
                      id={item?.id}
                      image={
                        itemImage
                          ? `${IMAGE_URL}/items/${itemImage}`
                          : DefaultFoodImg
                      }
                      title={item?.name}
                      defaultPrice={item?.price}
                      description={item?.description}
                      ItemSizePrices={item?.ItemSizePrices}
                      relevantItems={item?.relevantitems}
                      is_package={item?.is_package}
                      is_type={item?.type}
                      isCategory={false}
                      handleCartClick={handleCartClick}
                      currentPage="menu"
                      itemQuantity={
                        cart?.find(({ itemId }) => itemId === item?.id)
                          ?.quantity
                      }
                      className="menu-box"
                    />
                  </Grid>
                );
              })
            ) : (
              <Typography
                m={10}
                sx={{ marginInline: "auto" }}
                textAlign="center"
                variant="h5"
              >
                No data found !
              </Typography>
            )}

            <CustomizedDialog
              handleClose={handleDialogClose}
              open={dialogOpen}
              onClick={() => {}}
              onCancel={handleDialogClose}
              contentStyle={{ maxWidth: "700px" }}
              className="item-detail-section"
            >
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  alignItems: "center",
                  mb: "10px",
                  padding: "10px",
                  color: "#fff",
                }}
                className="custom-dialog-header"
              >
                <Typography variant="h6">{promptItem?.itemName}</Typography>
              </Box>
              <Box
                sx={{
                
               
                  width: "100%",
                  alignItems: "center",
                }}
                className="dialog-items-details"
              >
                <Box ml={2}>
                 
                  <Typography variant="h6" style={{ fontSize: "15px" }}>
                    Price: ${promptItem?.price + totalPriceOfpakage}
                  </Typography>

                  <Typography variant="h6" style={{ fontSize: "15px" }}>
                   Description : {promptItem?.itemDescription}
                  </Typography>
                </Box>
                <Box>
                  {promptItem?.ItemSizePrices?.length === 0 && (
                    <Box p={1}>
                      {totalQuantity && items.is_package == 0 ? (
                        <ButtonGroup
                          variant="contained"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "15px 15px 15px 15px",
                          }}
                        >
                          <CustomButton
                            label="-"
                            sx={{
                              borderRadius: "0 15px 15px 0",
                              backgroundColor: "white",
                            }}
                            onClick={() =>
                              handleCartClick({
                                itemId: promptItem?.id,
                                defaultPrice: promptItem?.price,
                                type: "dcr",
                              })
                            }
                            textColor="black"
                          />
                          <CustomButton
                            label={totalQuantity}
                            sx={{
                              backgroundColor: "white",
                            }}
                            textColor="black"
                          />
                          <CustomButton
                            label="+"
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "15px 0px 0px 15px",
                              "& :hover": {
                                backgroundColor: "white",
                              },
                            }}
                            onClick={() => {
                              handleCartClick({
                                itemId: promptItem?.id,
                                defaultPrice: promptItem?.price,
                                type: "inc",
                              });
                            }}
                            textColor="black"
                          />
                        </ButtonGroup>
                      ) : (
                        <CustomButton
                          label="Add"
                          variant="contained"
                          size="medium"
                          onClick={() => {
                            let is_package = localStorage.getItem("is_package");
                            is_package == 1
                              ? handleCardClickForPackage(promptItem, items)
                              : handleCartClick({
                                  itemId: promptItem?.id,
                                  defaultPrice: promptItem?.price,
                                  type: "inc",
                                });
                          }}
                          sx={{
                            borderRadius: "15px",
                            boxShadow: "none",
                            backgroundColor: "primary",
                            color: "#fff",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              {itemSizeValue?.ItemSizePrices?.length > 0 && (
                <Box m={3}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      margin: "10px 0px",
                      fontWeight: "700",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: (theme) => theme.palette.primary.light,
                    }}
                  >
                    Choose Your Size
                  </FormLabel>

                  {itemSizeValue?.ItemSizePrices?.map((option, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ marginRight: "10px" }}
                      >
                        {`${option?.ItemSize?.size} - $${option?.price}`}
                      </Typography>

                      {cart?.find(
                        (itemId) =>
                          itemId.defaultPrice === option?.price &&
                          itemId.itemId === option.item_id
                      ) ? (
                        <ButtonGroup
                          variant="contained"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <CustomButton
                            label="-"
                            sx={{
                              borderRadius: "5px 0 0 5px",
                              color: "white",
                              minWidth: 10,
                            }}
                            onClick={() =>
                              handleCartClick({
                                itemId: option?.item_id,
                                defaultPrice: option?.price,
                                type: "dcr",
                                size_price_id: option?.size_id,
                                size: option?.ItemSize?.size,
                              })
                            }
                            textColor="black"
                          />
                          <CustomButton
                            label={option?.quantity}
                            sx={{
                              color: "white",
                            }}
                            textColor="black"
                          />
                          <CustomButton
                            label="+"
                            sx={{
                              borderRadius: "0 5px 5px 0",
                              color: "white",
                            }}
                            onClick={() => {
                              handleCartClick({
                                itemId: option?.item_id,
                                defaultPrice: option?.price,
                                size_price_id: option?.size_id,
                                size: option.ItemSize.size,
                                type: "inc",
                                // size: item?.ItemSize?.size,
                              });
                            }}
                            textColor="black"
                          />
                        </ButtonGroup>
                      ) : (
                        <CustomButton
                          label="Add"
                          variant="contained"
                          size="medium"
                          onChange={handleItemSize}
                          onClick={() => {
                            handleCartClick({
                              itemId: option?.item_id,
                              defaultPrice: option?.price,
                              size_price_id: option?.size_id,
                              type: "inc",
                              size: option.ItemSize.size,
                            });
                          }}
                          sx={{
                            borderRadius: "5px",
                            boxShadow: "none",
                            backgroundColor: "primary",
                            color: "#fff",
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            minWidth: 10,
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              )}
              <div className="thali_m">
                <Box>
                  {promptItem?.relItems?.map((item, index) => {
                    let is_package = localStorage.getItem("is_package");
                    if (is_package == 1) {
                      return (
                        <>
                          {index == 0 ? (
                            <h4>Fixed Itemsssss</h4>
                          ) : (
                            <h4>Optional</h4>
                          )}
                          <Grid
                            container
                            spacing={1}
                            key={index}
                            className="food-item-detail"
                          >
                            {item &&
                              item?.map((itemElement) => (
                                <>
                                  {index == 0 ? (
                                    <>
                                      <Grid className="first_s" item md={2}>
                                        <CardMedia
                                          component="img"
                                          alt="title"
                                          src={`${IMAGE_URL}/items/${
                                            JSON.parse(
                                              itemElement?.images || "{}"
                                            )[0]?.filename
                                          }`}
                                          sx={{
                                            borderRadius: "5px",
                                            objectFit: "cover",
                                            height: "4rem",
                                            border: "0.5px solid #d7d7d7",
                                            width: "4rem",
                                            m: 2,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={8}
                                        className="item-details first_s"
                                      >
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            display="flex"
                                            alignItems="center"
                                            flex="auto"
                                          >
                                            {itemElement?.name}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        className="first_s"
                                        item
                                        md={2}
                                      ></Grid>
                                    </>
                                  ) : (
                                    <>
                                      <Grid item md={1}>
                                        <input
                                          type="radio"
                                          name={index}
                                          value={itemElement.id}
                                          defaultChecked={
                                            item[0].id == itemElement.id
                                          }
                                          onClick={() =>
                                            handleChangeForRelevantRadioBtn(
                                              itemElement,
                                              index
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item md={2}>
                                        <CardMedia
                                          component="img"
                                          alt="title"
                                          src={`${IMAGE_URL}/items/${
                                            JSON.parse(
                                              itemElement?.images || "{}"
                                            )[0]?.filename
                                          }`}
                                          sx={{
                                            borderRadius: "5px",
                                            objectFit: "cover",
                                            height: "4rem",
                                            border: "0.5px solid #d7d7d7",
                                            width: "4rem",
                                            m: 2,
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        md={7}
                                        className="item-details item_price"
                                      >
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            display="flex"
                                            alignItems="center"
                                            flex="auto"
                                          >
                                            {itemElement?.name}
                                          </Typography>
                                          <Typography
                                            paragraph={true}
                                            display="flex"
                                            alignItems="center"
                                            flex="auto"
                                          >
                                            $ {itemElement?.price}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item md={2} className="item_price">
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          flex="end"
                                          mr={2}
                                          ml={1}
                                          className="btn-box"
                                        ></Box>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              ))}
                          </Grid>
                        </>
                      );
                    } else {
                      const totalQuantity = cart?.find(
                        ({ itemId }) => itemId === item?.relevant_item_id
                      )?.quantity;
                      return (
                        <Grid
                          container
                          spacing={2}
                          key={index}
                          className="food-item-detail"
                          style={{ padding: "0px" }}
                        >
                          <Grid item md={2}>
                            <CardMedia
                              component="img"
                              alt="title"
                              src={`${IMAGE_URL}/items/${
                                JSON.parse(
                                  item?.relevant_item?.images || "{}"
                                )[0]?.filename
                              }`}
                              sx={{
                                borderRadius: "5px",
                                objectFit: "cover",
                                height: "4rem",
                                border: "0.5px solid #d7d7d7",
                                width: "4rem",
                                m: 2,
                              }}
                            />
                          </Grid>
                          <Grid item md={7} className="item-details">
                            <Box>
                              <Typography
                                variant="h6"
                                display="flex"
                                alignItems="center"
                                flex="auto"
                              >
                                {item?.relevant_item?.name}
                              </Typography>
                              <Typography
                                paragraph={true}
                                display="flex"
                                alignItems="center"
                                flex="auto"
                              >
                                {item?.relevant_item?.description}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={3}>
                            <Box
                              display="flex"
                              alignItems="center"
                              flex="end"
                              margin={"0px"}
                              className="btn-box"
                            >
                              {totalQuantity ? (
                                <ButtonGroup
                                  variant="contained"
                                  sx={{
                                    borderRadius: "15px 15px 15px 15px",
                                  }}
                                >
                                  <CustomButton
                                    label="-"
                                    sx={{
                                      borderRadius: "0 15px 15px 0",
                                      minWidth: 10,
                                    }}
                                    onClick={() =>
                                      handleCartClick({
                                        itemId: item?.relevant_item_id,
                                        defaultPrice:
                                          item?.relevant_item?.price,
                                        type: "dcr",
                                        size: item?.ItemSize?.size,
                                      })
                                    }
                                    textColor="black"
                                  />
                                  <CustomButton
                                    label={totalQuantity}
                                    textColor="black"
                                  />
                                  <CustomButton
                                    label="+"
                                    sx={{
                                      borderRadius: "15px 0px 0px 15px",
                                      "& :hover": {
                                        backgroundColor: "white",
                                      },
                                    }}
                                    onClick={() => {
                                      handleCartClick({
                                        itemId: item?.relevant_item_id,
                                        defaultPrice:
                                          item?.relevant_item?.price,
                                        type: "inc",
                                        size: item?.ItemSize?.size,
                                      });
                                    }}
                                    textColor="black"
                                  />
                                </ButtonGroup>
                              ) : (
                                <CustomButton
                                  label="Add"
                                  variant="contained"
                                  size="medium"
                                  onClick={() =>
                                    handleCartClick({
                                      itemId: item?.relevant_item_id,
                                      defaultPrice: item?.relevant_item?.price,
                                      type: "inc",
                                      // size: item?.ItemSize?.size,
                                    })
                                  }
                                  sx={{
                                    borderRadius: "15px",
                                    boxShadow: "none",
                                    backgroundColor: "primary",
                                    color: "#fff",
                                    whiteSpace: "nowrap",
                                    minWidth: 10,
                                  }}
                                />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
                </Box>
              </div>
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  alignItems: "center",
                  padding: "10px",
                  color: "#fff",
                }}
              ></Box>
            </CustomizedDialog>
            {/***Handles size of item ***/}
            <CustomizedDialog
              handleClose={() => {
                setSizeDialogOpen(false);
              }}
              open={sizedialogOpen}
              onClick={() => {}}
              onCancel={() => {
                setSizeDialogOpen(false);
              }}
              contentStyle={{ maxWidth: "700px", minWidth: "500px" }}
              className="item-detail-section"
            >
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  alignItems: "center",
                  mb: "10px",
                  padding: "10px",
                  color: "#fff",
                }}
                className="custom-dialog-header"
              >
                <Typography variant="h6" fontWeight={600}>
                  {itemSizeValue?.itemName}
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  padding: "20px",
                }}
                className="dialog-items-details"
              >
                <Box ml={2}>
                  <Box
                   
                  >
                        <Typography variant="subtitle2">
                          Price: ${itemSizeValue?.price}
                        </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          marginBottom: "10px",
                          overflowWrap: "break-word",
                        }}
                      >
                        Item Desc: {itemSizeValue?.itemDescription}
                      </Typography>
                  </Box>
                  <Box>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        margin: "10px 0px",
                        fontWeight: "700",
                        padding: "5px",
                        borderRadius: "10px",
                        backgroundColor: (theme) => theme.palette.primary.light,
                      }}
                    >
                      Choose Your Size
                    </FormLabel>

                    {itemSizeValue?.ItemSizePrices?.map((option, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "5px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ marginRight: "10px" }}
                        >
                          {`${option?.ItemSize?.size} - $${option?.price}`}
                        </Typography>

                        {cart?.find(
                          (itemId) =>
                            itemId.defaultPrice === option?.price &&
                            itemId.itemId === option.item_id
                        ) ? (
                          <ButtonGroup
                            variant="contained"
                            sx={{
                              backgroundColor: "white",
                              borderRadius: "15px",
                            }}
                          >
                            <CustomButton
                              label="-"
                              sx={{
                                borderRadius: "15px 0 0 15px",
                                // backgroundColor: "white",
                                minWidth: 10,
                              }}
                              onClick={() =>
                                handleCartClick({
                                  itemId: option?.item_id,
                                  defaultPrice: option?.price,
                                  type: "dcr",
                                  size_price_id: option?.size_id,
                                  size: option?.ItemSize?.size,
                                })
                              }
                              textColor="black"
                            />
                            <CustomButton
                              label={option?.quantity}
                              sx={{
                                // backgroundColor: "white",
                                color: "black",
                              }}
                              textColor="black"
                            />
                            <CustomButton
                              label="+"
                              sx={{
                                borderRadius: "0 15px 15px 0",
                              }}
                              onClick={() => {
                                handleCartClick({
                                  itemId: option?.item_id,
                                  defaultPrice: option?.price,
                                  size_price_id: option?.size_id,
                                  size: option.ItemSize.size,
                                  type: "inc",
                                  // size: item?.ItemSize?.size,
                                });
                              }}
                              textColor="black"
                            />
                          </ButtonGroup>
                        ) : (
                          <CustomButton
                            label="Add Price"
                            variant="contained"
                            size="medium"
                            onChange={handleItemSize}
                            onClick={() => {
                              handleCartClick({
                                itemId: option?.item_id,
                                defaultPrice: option?.price,
                                size_price_id: option?.size_id,
                                type: "inc",
                                size: option.ItemSize.size,
                              });
                            }}
                            sx={{
                              borderRadius: "15px",
                              boxShadow: "none",
                              backgroundColor: "primary",
                              color: "#fff",
                              whiteSpace: "nowrap",
                              minWidth: 10,
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                  {/* <Box sx={{ margin: "20px 0px", textAlign: "center" }}>
                    <CustomButton
                      label="Add SizePrice"
                      variant="contained"
                      size="medium"
                      onClick={() =>
                        handleCartClick({
                          itemId: itemSizeValue?.id,
                          defaultPrice: itemSizeValue?.price,
                          type: "inc",
                          size: itemSizeValue?.ItemSize?.size,
                        })
                      }
                      sx={{
                        borderRadius: "15px",
                        boxShadow: "none",
                        backgroundColor: "primary",
                        color: "#fff",
                        whiteSpace: "nowrap",
                        minWidth: 10,
                      }}
                    />
                  </Box> */}
                </Box>
              </Box>
            </CustomizedDialog>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <CartBar cartData={cart} />

          <IconButton
            sx={{
              position: "fixed",
              right: "1%",
              bottom: { xs: cart?.length ? "8%" : "1.5%", md: "2%" },
              zIndex: 999,
              cursor: "pointer",
              color: "black",
              display: visible ? "block" : "none",
              background: "transparent",
            }}
          >
            <ArrowCircleUpIcon
              onClick={scrollToTop}
              sx={{
                fontSize: 30,
                borderRadius: "100%",
              }}
            />
          </IconButton>
        </Box>
      </Grid>
    </PageWrapper>
  );
};

export default Menu;
