import { call, takeLatest, put } from "redux-saga/effects";
import {
  ADD_TO_CART,
  CLEAR_CART,
  DELETE_CART_ITEM,
  GET_CART_DATA,
} from "store/actionTypes";
import {
  addToCartSuccess,
  clearCartSuccess,
  getCartDataSuccess,
} from "./actions";
import { endPoints } from "utils/endPoints";
import { api } from "utils/api";

function* addToCart({ payload }) {
  try {
    if (payload?.isLoggedIn == "true") {
      const response = yield call(
        api.post,
        `${endPoints.ADD_TO_CART}${
          payload.isCart != undefined ? `?is_cart=${true}` : ""
        }`,
        { cart_data: payload.data }
      );
      console.log("response", response);
      let joinData = response.data.getAllCartData.map((ele) => {
        return {
          id: ele.id,
          itemId: ele?.Item?.id,
          itemName: ele?.Item?.name,
          itemDescription: ele?.Item?.description,
          is_package: ele?.Item?.is_package,
          itemImage: ele?.Item?.images,
          quantity: ele?.quantity,
          defaultPrice: ele?.defaultPrice,
          // defaultPrice: ele.ItemSizePrice.length == 0 ? ele?.size_price_id : ele.defaultPrice,
          size_price_id: ele?.size_price_id ? ele?.size_price_id : null,
          relevantItems: ele?.items != null ? ele?.items : [],
          size: ele?.ItemSizePrice[0]?.ItemSize?.size ? ele?.ItemSizePrice[0]?.ItemSize?.size : "",
        };
      });
      
      // let isPackageCondition =
      //   payload.data[0].is_package == 0 ||
      //   payload.data[0].is_package == undefined;

      // let newArray = joinData.map((ele) => {
      //   if (
      //     isPackageCondition
      //       ? payload.data[0].itemId === ele.itemId
      //       : payload.data[0].id === ele.id
      //   ) {
      //     if (payload.data[0].quantity === 0) {
      //       return null;
      //     } else {
      //       return {
      //         ...ele,
      //         quantity: isPackageCondition
      //           ? payload.data[0].quantity
      //           : ele.id == payload.data[0].id
      //           ? payload.data[0].quantity
      //           : ele.quantity,
      //       };
      //     }
      //   } else {
      //     return {
      //       ...ele,
      //       quantity: ele.quantity,
      //     };
      //   }
      // });
      
      // newArray = newArray.filter((ele) => ele !== null);
      // const newItem = payload.data[0];
      // const existingItem = joinData.find(
      //   (ele) =>
      //     ele.itemId === newItem.itemId &&
      //   ele.defaultPrice === newItem.defaultPrice
      // );
      // // const cartMatch = joinData.find((ele) => ele.id == payload.data[0].id);
      // // if (newItem.is_package == 1 && payload.data[0].quantity == 1 && !cartMatch) {
      //   //   newArray.push(newItem);
      //   // }
        
      //   if (
      //     !existingItem &&
      //     newItem.quantity !== 0 &&
      //     newItem.is_package == undefined
      //   ) {
      //     newArray.push(newItem);
      //   }
        // console.log("🚀 ~ newArray ~ newArray:", newArray)
        yield put(addToCartSuccess([...joinData]));
      } else {
      let localCartData = JSON.parse(localStorage.getItem("cart"));
      if (localCartData) {
        let updatedCartData = localCartData.map((ele) => {
          console.log("🚀 ~ updatedCartData ~ ele:", ele);
          console.log("🚀 ~ cart ~ payload?.data:", payload?.data);
          const cart = payload?.data?.find((item) => {
            return (
              item.itemId === ele.itemId &&
              item.size_price_id === ele.size_price_id
            );
          });
          if (
            ele?.itemId === cart?.itemId &&
            cart?.size_price_id === ele?.size_price_id
          ) {
            return { ...ele, quantity: cart?.quantity };
          } else {
            return ele;
          }
        });

        // if (
        //   !localCartData.some((ele) => ele.itemId === payload.data[0].itemId)
        // ) {
        //   updatedCartData.push(payload.data[0]);
        // }

        payload?.data?.map((item) => {
          if (
            !localCartData.some(
              (ele) =>
                item?.itemId === ele?.itemId &&
                item?.size_price_id === ele?.size_price_id
            ) ||
            !localCartData.some((ele) => item.itemId === ele.itemId)
          ) {
            updatedCartData.push(item);
          }
        });
        updatedCartData = updatedCartData.filter((ele) => ele.quantity !== 0);

        yield put(addToCartSuccess(updatedCartData));
      } else {
        yield put(addToCartSuccess(payload?.data));
      }
    }
  } catch (error) {
    console.log("🚀 ~ file: saga.js:13 ~ function*addToCart ~ error:", error);
  }
}

function* clearCart() {
  yield put(clearCartSuccess());
}

function* deleteCartItem({ payload }) {
  try {
    if(localStorage.getItem("isLoggedIn") === "true"){

      yield call(api.delete, endPoints.DELETE_CART_ITEM, {
        urlParams: { id: payload.data },
      });
    }
  } catch (error) {
    console.log("🚀 ~ file: saga.js:13 ~ function*addToCart ~ error:", error);
  }
}

function* getCartData() {
  try {
    let response = yield call(api.get, endPoints.GET_CART_DATA);
    let responseArr = response?.data?.data?.map((ele) => {
      return {
        id: ele?.id,
        itemId: ele?.Item?.id,
        itemName: ele?.Item?.name,
        itemDescription: ele?.Item?.description,
        is_package: ele?.Item?.is_package,
        itemImage: ele?.Item?.images,
        quantity: ele?.quantity,
        defaultPrice: ele?.defaultPrice,
        // defaultPrice: ele.ItemSizePrices.length == 0 ? ele?.size_price_id : ele.defaultPrice,
        size: ele?.ItemSizePrices[0]?.ItemSize?.size,
        size_price_id: ele?.size_price_id ? ele?.size_price_id : null,
        relevantItems: ele?.items != null ? ele?.items : [],
      };
    });
    yield put(getCartDataSuccess(responseArr));
  } catch (error) {
    console.log("🚀 ~ file: saga.js:13 ~ function*addToCart ~ error:", error);
  }
}

function* CartSaga() {
  yield takeLatest(ADD_TO_CART, addToCart);
  yield takeLatest(CLEAR_CART, clearCart);
  yield takeLatest(DELETE_CART_ITEM, deleteCartItem);
  yield takeLatest(GET_CART_DATA, getCartData);
}

export default CartSaga;