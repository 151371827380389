import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const makePayment = async ({ payload }) => {
  try {
    const response = await api.post(endPoints.MAKE_PAYMENT, payload);

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getPaymentMethod = async () =>{
  try {
    const response = await api.get(`${endPoints.GET_SETTING}?key=payment_method`);
    return response.data;
  } catch (error) {
      throw Error(error)
  }
}
