import axios from "axios";

export const API_URL = 'https://aanchfoodbazaar.ca:8080/api/v1';
export const IMAGE_URL = 'https://aanchfoodbazaar.ca:8080/static';

// export const API_URL = "http://192.168.1.27:8080/api/v1";
// export const IMAGE_URL = "http://192.168.1.27:8080/static";

let store;

export const injectStore = (_store) => {
  store = _store;
};

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const auth = store.getState()?.AuthReducer || null;

  if (auth) {
    config.headers["access_token"] = "Bearer " + auth.token;
  }
  if (config?.urlParams && typeof config?.url?.replace === "function") {
    Object.entries(config.urlParams || {}).forEach(([key, value]) => {
      config.url = config.url.replace(`:${key}`, value); // encodeURIComponent()
    });
  }
  return config;
});
