import { useRoutes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import io from "socket.io-client";
import { Router } from "./routes";
import { baseTheme } from "./assets/global/theme-variable";
// import {  useState } from "react";
// import { messaging } from "./firebase";
import { onMessageListener } from "./firebase";
import SnackbarNotification from "components/ui/Snackbar";
import TrackingSnackbar from "components/ui/TrackingSnackbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSocketDriverPosition, getSocketOrder } from "store/socket/socketAction";
import './assets/css/global.css';
import { SocketContext } from "context/socketContext";

const socketURL = process.env.REACT_APP_BASE_URL || 'https://aanchfoodbazaar.ca:8080';
// const socketURL = process.env.REACT_APP_BASE_URL || 'http://192.168.1.27:8080' ;


const App = () => {
  const [socketId, setSocketId] = useState(null);

  const user = useSelector((state) => state?.AuthReducer?.user?.data?.id);
  const dispatch = useDispatch();

  const theme = createTheme(baseTheme);
  const routing = useRoutes(Router);

  onMessageListener()
    .then(() => {})
    .catch(() => {});

  useEffect(() => {
    if (socketId === null && user !== undefined) {
      setSocketId(io(socketURL, { auth: { user_id: user } }));
    }

    if (socketId && user) {
      socketId.on("connect", () => {
        socketId.on("order", (data) => {
          dispatch(getSocketOrder(data));
        });
        socketId.on("add-order", () => {});
        socketId.on("liveTracking", (data) => {

          dispatch(getSocketDriverPosition({ data }));
        })
      });
    }
  }, [socketId, user]);

  return (
    <ThemeProvider theme={theme}>
      <SocketContext.Provider value={socketId}>
        <div className="App">{routing}</div>
        <SnackbarNotification />
        <TrackingSnackbar />
      </SocketContext.Provider>
    </ThemeProvider>
  );
};

export default App;
