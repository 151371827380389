import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import PropTypes from "prop-types";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  DirectionsRenderer,
  Marker
} from "react-google-maps";
import { Skeleton } from "@mui/material";
import { getRestraunts } from "services/RestauntService";
import { useDispatch } from "react-redux";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { findNearest } from "geolib";
import DeliveryBoy from "../../../assets/images/delivery_man_marker.png"
import UserMarker from "../../../assets/images/user_marker.png"

const GoogleMapsAPI = process.env.REACT_APP_GOOGLE_MAP_API || 'AIzaSyCQ7NpAn-3OKyjhvW4dZn33-uuFXZQSlKM';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

const RouteMap = (props) => {
  const googles = window?.google;

  const { addressCords, zoom, setDistance } = props;

  const dispatch = useDispatch();

  const [directions, setDirections] = useState();
  const [restrauntCords, setRestrauntCords] = useState([]);
  const [origin, setOrigin] = useState(null)

  useEffect(() => {
    getRestraunts()
      .then((res) => {
        setRestrauntCords(
          res?.map(({ Address }) => ({
            lat: parseFloat(Address?.lat),
            lng: parseFloat(Address?.long),
          }))
        );
      })
      .catch(() =>
        dispatch(
          snackbarOpen({
            open: true,
            message: "Something went wrong while fetching restraunts data !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        )
      );
  }, []);

  useEffect(() => {
    // windows.google return undefined on refresh

    const googles = window?.google;
    if (googles) {
      const directionsService = new googles.maps.DirectionsService();

      const nearest = findNearest(addressCords, restrauntCords);
      const destination = { lat: parseFloat(addressCords?.lat), lng: parseFloat(addressCords?.lng) };
      if (nearest) {
        setOrigin({ lat: parseFloat(nearest.lat), lng: parseFloat(nearest.lng) });
        if (origin) {
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: googles.maps.TravelMode.DRIVING,
            },
            (result) => {
              if (result?.routes?.length) {
                setDistance({
                  distance: result?.routes?.[0]?.legs?.[0]?.distance?.value,
                  duration: result?.routes?.[0]?.legs?.[0]?.duration?.value,
                });
                setDirections(result);
              } else {
                setDistance({
                  distance: 0,
                  duration: 0,
                });
                dispatch(
                  snackbarOpen({
                    open: true,
                    message:
                      "Could not find any route for selected delivery address !",
                    type: SNACKBAR_MESSAGE_TYPE.warning,
                  })
                );
              }
            }
          );
        }

      }

    }
  }, [addressCords?.lat, restrauntCords, origin?.lat, origin?.lng]);

  const AsyncMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        suppressMarkers={true}
        defaultZoom={zoom}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        defaultCenter={{
          lat: parseFloat(addressCords?.lat),
          lng: parseFloat(addressCords?.lng),
        }}
        options={{
          // zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        <Marker
          position={origin}
          icon={{ url: DeliveryBoy, scaledSize: { height: 45, width: 30 } }}
          title="start"
        >
        </Marker>
        <Marker
          position={new googles.maps.LatLng(addressCords?.lat, addressCords?.lng)}
          icon={{ url: UserMarker, scaledSize: { height: 45, width: 30 } }}
          title="end"
        ></Marker>
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: "#1976D2",
                strokeWeight: 4,
              },
              suppressMarkers: true
            }}
          />
        )}
      </GoogleMap>
    ))
  );

  let map;
  if (addressCords?.lat !== undefined) {
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places&sensor=false`}
        loadingElement={
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="auto"
            height={400}
          />
        }
        containerElement={<div style={{ width: "auto", height: 400 }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    );
  } else {
    map = (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="auto"
        height={400}
      />
    );
  }
  return map;
};

RouteMap.propTypes = {
  zoom: PropTypes.number,
  setDistance: PropTypes.func,
  addressCords: PropTypes.object,
};

export default RouteMap;
