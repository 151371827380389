import { api } from "utils/api";
import { endPoints } from "utils/endPoints";

export const calculateCashback = async (payload) => {
  try {
    const response = await api.post(endPoints.CALCULATE_CASHBACK, payload);
    return response.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};

export const getLoyalityPoint = async (id) => {
  try {
    const response = await api.get(endPoints.GET_LOYALITY_POINTS, {
      urlParams: { id: id },
    });
    return response?.data;
  } catch (error) {
    const {
      response: { data },
    } = error;
    throw Error(data.message);
  }
};
