import React from "react";
import {  Container, Typography, Box, CardMedia, Button } from "@mui/material";
import SectionImage1 from "../../../assets/images/SectionImage1.png";
import SectionImage2 from "../../../assets/images/SectionImage2.png";
import SectionImage3 from "../../../assets/images/SectionImage3.png";
import '../home.css';

const Section2 = () => {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        zIndex: "-2",
      }}
      className="choose-enjoy common-header p-80"
      container
      align="center"
      justifyContent="center"
    >
      
      <Container maxWidth="xl">
      <Box sx={{  width: "70%" }} className="main-root">
        <Typography variant="h3"> CHOOSE & ENJOY</Typography>
        <Typography variant="h6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas ac
        </Typography>
      </Box>
      </Container>
      <Box
        sx={{
          padding: 4,
          width: "100%",
          backgroundColor: "#e8e6a9",
          position: "absolute",
          top: "54%",
          zIndex: "-1",
        }}
      />
       <Container maxWidth="xl">
      <Box
        item
        xs={12}
        md={4}
        justifyContent="center"
        display="flex"
        alignItems="center"
        sx={{  }}
      >
        <Box alignItems="center" sx={{ padding: 1 }} className="food-dish">
          <CardMedia
            component="img"
            src={SectionImage1}
            alt="Live from space album cover"
            sx={{ width: "80%" }}
            className="card-img"
          />
          <Typography variant="h5">SECTION ITEM 1</Typography>
          <Typography variant="body2">
            Locrem ipsum dolor sit amet, consectetur adipiscing elit,
          </Typography>
          <Button
            sx={{ margin: 1, backgroundColor: "#bf4e49" }}
            variant="contained"
            className="btn-order-now"
          >
            ORDER NOW
          </Button>
        </Box>
        <Box alignItems="center" sx={{ padding: 1 }} className="food-dish">
          <CardMedia
            component="img"
            src={SectionImage2}
            alt="Live from space album cover"
            sx={{ width: "80%" }}
            className="card-img"
          />
          <Typography variant="h5">SECTION ITEM 1</Typography>
          <Typography variant="body2">
            Locrem ipsum dolor sit amet, consectetur adipiscing elit,
          </Typography>
          <Button
            sx={{ margin: 1, backgroundColor: "#bf4e49" }}
            variant="contained"
            className="btn-order-now"
          >
            ORDER NOW
          </Button>
        </Box>
        <Box alignItems="center" sx={{ padding: 1 }} className="food-dish">
          <CardMedia
            component="img"
            src={SectionImage3}
            alt="Live from space album cover"
            sx={{ width: "80%" }}
            className="card-img"
          />
          <Typography variant="h5">SECTION ITEM 1</Typography>
          <Typography variant="body2">
            Locrem ipsum dolor sit amet, consectetur adipiscing elit,
          </Typography>
          <Button
            sx={{ margin: 1, backgroundColor: "#bf4e49" }}
            variant="contained"
            className="btn-order-now"
          >
            ORDER NOW
          </Button>
        </Box>
      </Box>
      {/* <Box
        item
        xs={12}
        md={4}
        justifyContent="center"
        display="flex"
        alignItems="center"
        sx={{ height: "600px" }}
      >
       
      </Box>
      <Box
        item
        xs={12}
        md={4}
        justifyContent="center"
        display="flex"
        alignItems="center"
        sx={{ height: "600px" }}
      >
        
      </Box> */}
      </Container>
    </Box>
  );
};

export default Section2;
