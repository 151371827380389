import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import {
  List,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  Toolbar,
  AppBar,
  Box,
  ListItemText,
  Badge,
  CardMedia,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import SearchBar from "./SearchBar";

import CustomizedDialog from "./Dialog";
import Authentication from "../authentication/Authentication";
import { IMAGE_URL } from "utils/api";
import { logoutUser } from "store/auth/actions";
import CustomButton from "./CustomButton";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Logo from "../../../src/assets/images/aanch_logo.png";
import { getOrder } from "services/OrderService";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { getSocketOrder } from "store/socket/socketAction";
import Shipping from "../../assets/gifs/delivery.gif";
import { clearAddress } from "store/address/actions";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
  {
    title: "Menu",
    path: "/menu",
  },
];

const orderStatus = ["Delivered", "Cancelled"];

const settings = [
  "Profile",
  "Address",
  "Order History",
  "Loyality Points",
  "Logout",
];

const Navbar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticatedUser = useSelector(
    (state) => state?.AuthReducer?.user?.data
  );

  let cart = useSelector((state) => state?.CartReducer?.cart);
  if (cart?.length == 0) {
    cart = JSON.parse(localStorage.getItem('cart')) || []
  }

  const socketOrder = useSelector((state) => state.SocketReducer.order);

  const orderID = parseInt(localStorage.getItem("orderID")) || null;

  const [orderData, setOrderData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const token = document?.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith("token="))
    ?.split("=")[1];

  useEffect(() => {
    if (authenticatedUser) {
      setDialogOpen(false);
    }
  }, [authenticatedUser]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setAnchorElNav(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);

    if (setting) {
      setting === "Profile"
        ? navigate("/user-account")
        : setting === "Address"
          ? navigate("/address")
          : setting === "Order History"
            ? navigate("/order-history")
            : setting === "Loyality Points"
              ? navigate("/loyality-point")
              : handleLogout();
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(clearAddress());
  };

  useEffect(() => {
    if (authenticatedUser?.id === socketOrder?.user_id) {
      setOrderData(socketOrder);
    } else {
      setOrderData({});
    }
  }, [socketOrder]);

  useEffect(() => {
    if (orderID && authenticatedUser && !socketOrder?.id) {
      getOrder(orderID)
        .then(({ data }) => {
          if (data?.user_id === authenticatedUser?.id) {
            setOrderData(data);
          } else {
            setOrderData({});
            dispatch(
              snackbarOpen({
                open: true,
                message: "Something went wrong while fetching order data !",
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
          }
          dispatch(getSocketOrder({ data }));
        })
        .catch(() => {
          setOrderData({});
          dispatch(
            snackbarOpen({
              open: true,
              message: "Something went wrong while tracking order !",
              type: SNACKBAR_MESSAGE_TYPE.error,
            })
          );
        });
    }
  }, [orderID, authenticatedUser]);

  return (
    <>
      <AppBar
        className={
          "main-header" +
          (pathname.replace("/", "") != "" ? "inner-header" : "")
        }
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <CardMedia
              component="img"
              src={Logo}
              alt=""
              sx={{
                width: { xs: "13%", md: "100px" },
              }}
              className="m-logo custom-logo"
            />

            <Box
              sx={{
                flexGrow: { xs: 1, md: 0 },
              }}
            >
              <SwipeableDrawer
                sx={{ display: { xs: "flex", md: "none" } }}
                open={anchorElNav}
                onClose={handleCloseNavMenu}
                onOpen={handleCloseNavMenu}
              >
                <Box
                  role="presentation"
                  onClick={handleCloseNavMenu}
                  onKeyDown={handleCloseNavMenu}
                  sx={{
                    width: "fit-content",
                    marginTop: 2,
                    minWidth: "11rem",
                  }}
                >
                  <List>
                    {pages.map(({ title, path }, index) => (
                      <ListItem
                        key={index}
                        disablePadding
                        sx={{
                          fontSize: 15,
                          fontWeight: "bold",
                          marginLeft: "12px",
                        }}
                      >
                        <ListItemButton onClick={() => handleNavigate(path)}>
                          <ListItemText primary={title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </SwipeableDrawer>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {pages.map((page) => (
                <CustomButton
                  type="contained"
                  label={page.title}
                  key={page.title}
                  onClick={() => navigate(page.path)}
                  sx={{
                    my: 2,
                    display: "block",
                    fontSize: 17,
                  }}
                  textColor={pathname === page.path ? "#faa148" : "#000"}
                  className="h-button"
                />
              ))}
            </Box>
            <SearchBar />

            <Box mr={1} className="header-icon-box">
              {orderData?.id &&
                authenticatedUser &&
                !orderStatus?.includes(orderData?.order_status) &&
                pathname !== `/track-order/${orderID}` ? (
                <IconButton
                  sx={{
                    display: {
                      xs: "none",
                      md: orderData?.id ? "inline-block" : "none",
                    },
                  }}
                  onClick={() => navigate(`/track-order/${orderData?.id}`)}
                >
                  <CardMedia
                    component="img"
                    src={Shipping}
                    alt=""
                    sx={{
                      width: 27,
                      height: 27,
                    }}
                  />
                </IconButton>
              ) : null}
              <IconButton
                sx={{ color: "black" }}
                onClick={() => navigate("/cart")}
                className="cart-btn"
              >
                <Badge
                  badgeContent={cart?.length}
                  max={10}
                  color="primary"
                  sx={{
                    "& .MuiBadge-badge": {
                      fontSize: 10,
                      height: 17,
                      minWidth: 17,
                    },
                  }}
                >
                  <ShoppingCartOutlinedIcon className="cart-icon" />
                </Badge>
              </IconButton>
            </Box>

            {authenticatedUser || token ? (
              <Box sx={{ flexGrow: 0, ml: 1 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={authenticatedUser?.first_name?.charAt(0)}
                      src={`${IMAGE_URL}/profile/${authenticatedUser?.image}`}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px", p: 10 }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={() => handleCloseUserMenu()}
                >
                  {settings?.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleCloseUserMenu(setting)}
                    >
                      <Typography>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { md: "flex", mr: 4 },
                  }}
                >
                  <CustomButton
                    label="Login"
                    variant="outlined"
                    sx={{ border: "1px solid white" }}
                    onClick={handleDialogOpen}
                    textColor="black"
                    className="login-btn"
                  />
                </Box>
              </Box>
            )}
            <Box>
              <MenuIcon
                className="MenuIcon"
                onClick={handleOpenNavMenu}
                sx={{ display: { md: "none", xs: "block" } }}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <CustomizedDialog
        handleClose={handleDialogClose}
        open={dialogOpen}
        onClick={() => { }}
        onCancel={handleDialogClose}
      >
        <Authentication handleDialogClose={handleDialogClose} />
      </CustomizedDialog>
    </>
  );
};

export default Navbar;
