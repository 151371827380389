import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import Payment from "./components/Payment";
import PageWrapper from "../../components/wrapper/PageWrapper";
import { Navigate, useLocation } from "react-router-dom";

const Checkout = () => {
  const location = useLocation();

  if (!location?.state?.fromCart) {
    // window.history.go(-1);
    return <Navigate to={-1} replace />;
  }

  return (
    <PageWrapper className="checkout-page">
      <Typography variant="h3">Checkout</Typography>
      <Box sx={{ width: "100%" }} mt={3}>
        <Grid container mt={3} wid>
          <Payment />
        </Grid>
      </Box>
    </PageWrapper>
  );
};
 
export default Checkout;
