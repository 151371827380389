import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  InputAdornment,
  IconButton,
  styled,
  Link,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik, FormikProvider, Form } from "formik";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleAuth from "./GoogleAuth";
import FacebookAuth from "./FacebookAuth";
import { loginUser, resendOTP, resetError } from "../../store/auth/actions";
import { useNavigate } from "react-router-dom";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import CustomLoadingButton from "components/ui/CustomLoadingButton";

const clientId = process.env.REACT_APP_GOOGLE_AUTH_API_CLIENT_ID || '403152643586-14j9ikn74ldtmgtj08lnk7cq3pn8a4q3.apps.googleusercontent.com';

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "15px",
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
}));

const initialState = {
  mob_no: "",
  password: "",
};

const Login = (props) => {
  const { setSignupMode, setLoginMode, handleDialogClose } = props;

  const [initFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();
 
  const validationSchema = Yup.object({
    mob_no: Yup.string()
      .required("Mobile no is required")
      .min(10)
      .label("Mobile Number"),
    password: Yup.string()
      .required("Password is required")
      .min(8)
      .label("Password"),
  });

  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, []);

  const onSubmit = (formData) => {
    setLoading(true);
    const userData = {
      mobile: formData.mob_no.replaceAll(/\s/g, ""),
      password: formData.password,
    };

    dispatch(
      loginUser({
        data: userData,
        callback: (response) => {
          if (response?.message?.includes("Account is not verify")) {
            dispatch(
              snackbarOpen({
                open: true,
                message:
                  "Your account is not verified. Please verify your account !",
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );

            dispatch(
              resendOTP({
                data: { email: response?.data?.email, otp_type: "REGISTER" },
                callback: (response) => {
                  setLoading(false);
                  if (!response?.error) {
                    setSignupMode(false);
                    setLoginMode(false);
                  }
                },
              })
            );
          }

          if (response?.message?.includes("Invalid Password")) {
            dispatch(
              snackbarOpen({
                open: true,
                message: response?.message + " !",
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
            setLoading(false);
          }

          if (response?.message?.includes("Please Enter Valid Credentials")) {
            dispatch(
              snackbarOpen({
                open: true,
                message: response?.message + " !",
                type: SNACKBAR_MESSAGE_TYPE.warning,
              })
            );
            setLoading(false);
          }
        },
      })
    );
    navigate('/menu');
  };

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const { handleChange, handleSubmit, values, errors, touched, handleBlur } =
    formik;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="food-login">
      <Typography component="h1" variant="h5">
        Welcome to Aanch Food Bazaar
      </Typography>
      <Typography variant="body2" mb={2}>
        Login to your account 
      </Typography>

      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <InputMask
            mask="9999 999 999"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mob_no}
            maskChar=""
          >
            {() => (
              <CustomTextField
                id="mob_no"
                label="Mobile no"
                name="mob_no"
                autoComplete="mobile-no"
                margin="normal"
                // autoFocus
                required
                fullWidth
                error={errors.mob_no && touched.mob_no}
                helperText={
                  errors.mob_no && touched.mob_no ? errors.mob_no : null
                }
                className="field"
              />
            )}
          </InputMask>
          <CustomTextField
            id="outlined-end-adornment"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            required
            fullWidth
            autoComplete="current-password"
            className="field"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.password && touched.password}
            helperText={
              errors.password && touched.password ? errors.password : null
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <CustomLoadingButton
            label="Login"   
            variant="contained"
            type="submit"
            loading={loading}
            onClick={handleSubmit}
            sx={{
              my: 2,
              borderRadius: 1,
              px: 3,
              py: 1,
              color: "#fff",
              boxShadow: "none",
            }}
           className="login-b"
          />

          <Box        
            sx={{
              display: { xs: "flex" },
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            {/* <Grid item xs> */}
            <Link
              variant="body2"
              onClick={() => {
                navigate("/forgot-password");
                handleDialogClose();
              }}
              width="fit-content"
            >
              Forgot password?
            </Link>
            {/* </Grid> */}

            {/* <Grid item> */}
            <Link
              href="#"
              variant="body2"
              onClick={() => {
                setLoginMode(false);
                setSignupMode(true);
              }}
            >
              {"Don't have an account? Sign Up"}
            </Link>
            {/* </Grid> */}
          </Box>

          <Divider sx={{ mt: 4 }}>
            <Typography variant="caption">OR</Typography>
          </Divider>

          <Box
            justifyContent={"space-between"}
            display="flex"
            sx={{ flexDirection: { xs: "column", md: "row" } }}
          >
            <GoogleOAuthProvider clientId={clientId}>
              <GoogleAuth />
            </GoogleOAuthProvider>
            <FacebookAuth />
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
};

Login.propTypes = {
  setSignupMode: PropTypes.func,
  setLoginMode: PropTypes.func,
  handleDialogClose: PropTypes.func,
};

export default Login;
