import {
  ADD_ADDRESS,
  ADD_ADDRESS_FAILED,
  ADD_ADDRESS_SUCCESS,
  GET_ADDRESS_LIST,
  GET_ADDRESS_LIST_FAILED,
  GET_ADDRESS_LIST_SUCCESS,
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILED,
  GET_ADDRESS,
  GET_ADDRESS_FAILED,
  GET_ADDRESS_SUCCESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILED,
  CLEAR_ADDRESS,
  CLEAR_ADDRESS_SUCCESS,
} from "../actionTypes";

export const addAddress = (data) => {
  return {
    type: ADD_ADDRESS,
    payload: data,
  };
};

export const addAddressSuccess = (data) => {
  data?.callback({ res: data?.response?.data?.data, error: false });

  return {
    type: ADD_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const addAddressFailed = (data) => {
  data.callback({ error: true });
  return {
    type: ADD_ADDRESS_FAILED,
    payload: data,
  };
};

export const getAddressList = ({ callback }) => {
  return {
    type: GET_ADDRESS_LIST,
    callback: callback,
  };
};

export const getAddressListSuccess = ({ response }) => {
  return {
    type: GET_ADDRESS_LIST_SUCCESS,
    payload: response,
  };
};

export const getAddressListFailed = ({ callback, error }) => {
  callback({ error: true, ...error });
  return {
    type: GET_ADDRESS_LIST_FAILED,
    payload: error,
  };
};

export const deleteAddress = (data) => {
  return {
    type: DELETE_ADDRESS,
    payload: data,
  };
};

export const deleteAddressSuccess = ({ id, callback }) => {
  callback({ error: false });
  return {
    type: DELETE_ADDRESS_SUCCESS,
    payload: id,
  };
};

export const deleteAddressFailed = (data) => {
  data?.callback({ error: true });
  return {
    type: DELETE_ADDRESS_FAILED,
    payload: data,
  };
};

export const getAddress = (data) => {
  return {
    type: GET_ADDRESS,
    payload: data,
  };
};

export const getAddressSuccess = ({ callback, response }) => {
  callback({ error: false });

  return {
    type: GET_ADDRESS_SUCCESS,
    payload: response,
  };
};

export const getAddressFailed = ({ error, callback }) => {
  callback({ error: true });
  return {
    type: GET_ADDRESS_FAILED,
    payload: error,
  };
};

export const updateAddress = (data) => {
  return {
    type: UPDATE_ADDRESS,
    payload: data,
  };
};

export const updateAddressSuccess = (data) => {
  data?.callback({ error: false });
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: data,
  };
};

export const updateAddressFailed = (data) => {
  data?.callback({ error: true });
  return {
    type: UPDATE_ADDRESS_FAILED,
    payload: data,
  };
};

export const clearAddress = () => {
  return {
    type: CLEAR_ADDRESS,
  };
};

export const clearAddressSuccess = () => {
  return {
    type: CLEAR_ADDRESS_SUCCESS,
  };
};
