import {
  SOCKET_GET_DRIVE_POSITION,
  SOCKET_GET_DRIVE_POSITION_FAILED,
  SOCKET_GET_DRIVE_POSITION_SUCCESS,
  SOCKET_GET_ORDER,
  SOCKET_GET_ORDER_FAILED,
  SOCKET_GET_ORDER_SUCCESS,
} from "store/actionTypes";

export const getSocketOrder = ({ data }) => {
  return { type: SOCKET_GET_ORDER, data: data };
};

export const getSocketOrderSuccess = (data) => {
  return { type: SOCKET_GET_ORDER_SUCCESS, payload: data };
};

export const getSocketOrderFail = (data) => {
  return { type: SOCKET_GET_ORDER_FAILED, error: data };
};

export const getSocketDriverPosition = ({ data }) => {
  return { type: SOCKET_GET_DRIVE_POSITION, data: data };
};

export const getSocketDriverPositionSuccess = (data) => {
  return { type: SOCKET_GET_DRIVE_POSITION_SUCCESS, payload: data };
};

export const getSocketDriverPositionFail = (data) => {
  return { type: SOCKET_GET_DRIVE_POSITION_FAILED, error: data };
};

