import React from "react";
import { Grid, Typography, Box, CardMedia } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import GogglePlay from "../../../assets/images/Googleplay.png";
import AppStore from "../../../assets/images/Appstore.png";
import PageWrapper from "components/wrapper/PageWrapper";
import Mobile from "../../../assets/images/aboutusmobile.png";

const Section2 = () => {
  return (
    <Box className="section2 order-step">
      <PageWrapper className="section-p">             
        <Grid container className="section2-container" >
          <Grid item md={6} xs={12} className="section2-item">
            <Grid mt={4} ml={4} className="section2-heading">
              <Typography variant="h3">
                Make Orders With 
                Our Application
              </Typography>
            </Grid>
            <Grid className="section2-content">
              <Typography variant="h4">
                <TaskAltIcon />
                Order and Pay In A Few Minutes
              </Typography>
              <Typography className="section2-subcontent">
                Choose food and pay for the order in a couple of clicks online
                also choose your current location using GPS.
              </Typography>
            </Grid>

            <Grid className="section2-content">
              <Typography variant="h4">
                <TaskAltIcon />
                Check Delivery Status
              </Typography>
              <Typography className="section2-subcontent">
                Follow the status of your order in real time and also track the
                delivery path until you get it.
              </Typography>
            </Grid>
            <Box className="section2-imgbox">
              <CardMedia
                className="section2-img1"
                component="img"
                src={GogglePlay}
              />
              <CardMedia
                component="img"
                className="section2-img2"
                src={AppStore}
              />
            </Box>
          </Grid>

          <Grid item md={6} xs={12} className="section2-item">
            <Box>
              <CardMedia component="img" src={Mobile} sx={{ width: "90%" }} />
            </Box>
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
};

export default Section2;
