import * as React from "react";
import Table from "@mui/material/Table";
import { useDispatch } from "react-redux";
import { useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import {
  Box,
  ButtonGroup,
  Card,
  CardMedia,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IMAGE_URL } from "utils/api";
import { addItemToCart, addItemToCartWithSize } from "utils/core-utils";
import { addToCart, deleteCartItem } from "store/cart/actions";
import CustomButton from "components/ui/CustomButton";
import DefaultFoodImg from "../../../assets/images/default-food-img.png";
import { useLocation } from "react-router-dom";
import { getItemsByCat } from "services/ItemsService";
import { useEffect } from "react";

const DataTable = (props) => {
  const { cartItems } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const matches = useMediaQuery("(max-width:600px)");
  const { pathname } = useLocation();

  let isLoggedIn = localStorage.getItem("isLoggedIn");

  const handleCartClick = ({
    itemId,
    defaultPrice,
    type = "inc",
    size,
    cartId,
  }) => {
    const cartData = [...cartItems];

    if (size) {
      const addedItems = addItemToCartWithSize(
        cartData,
        itemId,
        size,
        defaultPrice,
        type
      );
      dispatch(addToCart({ data: addedItems, isCart: true }));
    } else {
      const addedItems = addItemToCart(
        cartData,
        cartId ? cartId : itemId,
        defaultPrice,
        type,
        0,
        true
      );
      dispatch(
        addToCart({
          data: addedItems
            .map((ele) => {
              if (cartId && cartId == ele.id) {
                return ele;
              } else if (ele.itemId == itemId) {
                return ele;
              }
            })
            .filter((ele) => ele != undefined),
          isCart: true,
          isLoggedIn: isLoggedIn,
        })
      );
    }
  };

  useEffect(() => {
    Promise.all([getItemsByCat()]).then((data) => {
      setState(...data);
    });
  }, []);

  const handleDeleteFromCart = (row) => {
    const cartData = [...cartItems];
      const findItem = cartData?.findIndex((item) => item.id === row.id);
        if(localStorage.getItem("isLoggedIn") === "true"){
          dispatch(deleteCartItem({ data: row.id }));
          cartData.splice(findItem, 1);
        }else{
          dispatch(deleteCartItem({ data: row }));

        }
  };

  return (
    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
      {cartItems?.length ? (
        matches ? (
          cartItems?.map((item) => {
            const parsedImage = JSON.parse(item?.itemImage || "[]");
            const image = `${IMAGE_URL}/items/${parsedImage?.[0]?.filename}`;
            return (
              <Card
                key={item.id}
                sx={{
                  p: 1,
                  bgcolor: (theme) => theme.palette.secondary.main,
                  mb: 1,
                }}
              >
                <Box justifyContent={"space-between"} display="flex">
                  <Box display={"flex"}>
                    <CardMedia
                      sx={{
                        display: "inline",
                        width: "50px",
                        height: "50px",
                        borderRadius: "5px",
                      }}
                      onError={(e) => {
                        e.target.src = DefaultFoodImg;
                      }}
                      alt="image"
                      src={parsedImage?.[0]?.filename ? image : DefaultFoodImg}
                      component="img"
                    />
                    <Box sx={{ ml: 1 }}>
                      <Typography fontSize={15} textAlign="left">
                        {item.itemName}
                      </Typography>
                      <Typography fontSize={15} textAlign="left">
                        $ {item.defaultPrice}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                      size="small"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "50px 50px 50px 50px",
                        mb: 1,
                      }}
                    >
                      <CustomButton
                        textColor="black"
                        variant="contained"
                        label="-"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "50px 0px 0px 50px",
                        }}
                      />

                      <CustomButton
                        textColor="black"
                        variant="contained"
                        label={item?.quantity}
                        sx={{
                          backgroundColor: "white",
                          ":hover": {
                            backgroundColor: "white",
                          },
                        }}
                        onClick={() =>
                          handleCartClick({
                            itemId: item?.itemId,
                            defaultPrice: item?.defaultPrice,
                            type: "dcr",
                            size: item?.size,
                          })
                        }
                      />

                      <CustomButton
                        textColor="black"
                        variant="contained"
                        label="+"
                        sx={{
                          borderRadius: "0 50px 50px 0",
                          backgroundColor: "white",
                          ":hover": {
                            backgroundColor: "white",
                          },
                        }}
                        onClick={() =>
                          handleCartClick({
                            itemId: item?.itemId,
                            defaultPrice: item?.defaultPrice,
                            type: "inc",
                            size: item?.size,
                          })
                        }
                      />
                    </ButtonGroup>
                    <Typography fontSize={15} textAlign={"end"}>
                      ${(item?.quantity * item?.defaultPrice).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            );
          })
        ) : (
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#cccccc6b",
                  color: "#000",
                }}
              >
                <TableCell
                  sx={{
                    borderRadius: "0px",
                    color: "#000",
                    textAlign: "center",
                  }}
                />

                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Product
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Selected Items
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Item Size
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Quantity
                </TableCell>
                <TableCell sx={{ color: "#000", textAlign: "center" }}>
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  Subtotal
                </TableCell>
                <TableCell
                  sx={{
                    borderRadius: "0px",
                    color: "#000",
                    textAlign: "center",
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems?.map((row, index) => {
                const parsedImage = JSON.parse(row?.itemImage || "[]");
                const image = `${IMAGE_URL}/items/${parsedImage?.[0]?.filename}`;
                return (
                  <TableRow key={index} sx={{ border: "0px 1px" }}>
                    {console.log("row", row)}
                    <TableCell sx={{ textAlign: "right" }}>
                      <CardMedia
                        sx={{
                          display: "inline",
                          width: "50px",
                          height: "50px",
                        }}
                        alt="image"
                        src={
                          parsedImage?.[0]?.filename ? image : DefaultFoodImg
                        }
                        onError={(e) => {
                          e.target.src = DefaultFoodImg;
                        }}
                        component="img"
                      />
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography variant="body1">{row?.itemName}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography variant="body1">
                        {row?.relevantItems &&
                        row?.relevantItems?.length > 0 &&
                        row?.relevantItems[1].length > 0
                          ? state
                              ?.filter((item) =>
                                row?.relevantItems[1]?.includes(item?.id)
                              )
                              .map(
                                (item, index) =>
                                  `${index != 0 ? ", " : ""}${item.name}`
                              )
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Typography variant="body1">{row.size}</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "50px 50px 50px 50px",
                        }}
                        className="table-btn-inc-dec"
                      >
                        <CustomButton
                          textColor="black"
                          variant="contained"
                          label="-"
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50px 0px 0px 50px",
                            ":hover": {
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() =>
                            handleCartClick({
                              itemId:
                                row?.is_package == 1 ? row?.id : row?.itemId,
                              defaultPrice: row?.defaultPrice,
                              type: "dcr",
                              size: row?.size,
                              isCart: pathname == "/cart" ? true : false,
                              cartId: row?.id,
                            })
                          }
                        />

                        <CustomButton
                          textColor="black"
                          variant="contained"
                          label={row.quantity}
                          sx={{
                            backgroundColor: "white",
                            ":hover": {
                              backgroundColor: "white",
                            },
                          }}
                        />

                        <CustomButton
                          textColor="black"
                          variant="contained"
                          label="+"
                          sx={{
                            borderRadius: "0 50px 50px 0",
                            backgroundColor: "white",
                            ":hover": {
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() =>
                            handleCartClick({
                              itemId:
                                row?.is_package == 1 ? row?.id : row?.itemId,
                              defaultPrice: row?.defaultPrice,
                              type: "inc",
                              size: row?.size,
                              isCart: pathname == "/cart" ? true : false,
                              cartId: row?.id,
                            })
                          }
                        />
                      </ButtonGroup>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${row?.defaultPrice}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${(row?.quantity * row?.defaultPrice).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <IconButton sx={{ color: "black" }}>
                        <DeleteIcon
                          onClick={() => handleDeleteFromCart(row)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )
      ) : (
        <Typography m={10} textAlign="center" variant="h5">
          Your cart is empty !{" "}
          <Link href="/" underline="none">
            Go Shopping
          </Link>
        </Typography>
      )}
    </TableContainer>
  );
};

DataTable.propTypes = {
  cartItems: PropTypes.array,
};

export default DataTable;