import { Box, ButtonGroup, Grid, TextField, Typography } from "@mui/material";
import CustomButton from "components/ui/CustomButton";
import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const LoyaltyPointDialog = (props) => {
  const {
    totalPrice,
    usedLoyaltyPoints,
    setUsedLoyaltyPoints,
    handleDialogClose,
    authenticatedUser,
  } = props;

  const [appliedLoyaltyPoints, setAppliedLoyaltyPoints] =
    useState(usedLoyaltyPoints);

  useEffect(() => {
    !usedLoyaltyPoints &&
      setAppliedLoyaltyPoints(
        Math.floor(
          Math.min(authenticatedUser?.loyaltypoint, Math.floor((10 * 10) / 15))
        )
      );
  }, [authenticatedUser]);

  const handleLoyaltyPoint = (type) => {
    if (
      type === "inc" &&
      appliedLoyaltyPoints < authenticatedUser?.loyaltypoint &&
      appliedLoyaltyPoints < Math.floor((10 * 10) / 15)
    ) {
      setAppliedLoyaltyPoints(appliedLoyaltyPoints + 1);
    }

    if (type === "dec" && appliedLoyaltyPoints > 0) {
      setAppliedLoyaltyPoints(appliedLoyaltyPoints - 1);
    }
  };

  const handleLoyaltyPointChange = (event) => {
    if (
      (parseInt(event.target.value) < (totalPrice * 10) / 15 &&
        Math.sign(parseInt(event.target.value)) !== -1 &&
        parseInt(event.target.value) < authenticatedUser?.loyaltypoint) ||
      isNaN(parseInt(event.target.value))
    ) {
      setAppliedLoyaltyPoints(parseInt(event.target.value) || 0);
    } else {
      setAppliedLoyaltyPoints(Math.floor((10 * 10) / 15));
    }
  };

  const handleUseLoyaltyPoints = () => {
    setUsedLoyaltyPoints(appliedLoyaltyPoints);
    handleDialogClose(true);
    localStorage.setItem("appliedLoyaltyPoints", appliedLoyaltyPoints);
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        padding: 4,
        backgroundColor: (theme) => {
          return theme.palette.secondary.main;
        },
        // height: "100px",
      }}
    >
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ position: "relative" }}
        className="discount-point"   
      >   
        <Box
          sx={{
            // my: 1,
            // mx: 4,
            display: "flex",
            flexDirection: "column",
            p: 1,
          }}
        >
          <Box
            sx={{
              position: "sticky",
              top: "6%",
              zIndex: 1,
              bgcolor: (theme) => theme.palette.secondary.main,
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              mr="auto"
              ml="auto"
              display="flex"
              justifyContent="center"
            >
              Apply Discount
            </Typography>
          </Box>

          <Typography>
            Available Loyalty Points : &nbsp;
            {authenticatedUser?.loyaltypoint - appliedLoyaltyPoints}
          </Typography>

          <Box display={"flex"} justifyContent={"center"}>
            <ButtonGroup
              variant="contained"
              aria-label="contained primary button group"
              sx={{
                backgroundColor: "white",
                borderRadius: "50px 50px 50px 50px",
                m: 1.5,
              }}
              className="loyalty-inc-dec-btn"
            >
              <CustomButton
                textColor="black"
                // variant="contained"
                label="-"
                sx={{
                  backgroundColor: "white",
                  borderRadius: "50px 0px 0px 50px",
                  ":hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={() => handleLoyaltyPoint("dec")}
              />

              <TextField
                value={appliedLoyaltyPoints}
                onChange={handleLoyaltyPointChange}
                sx={{
                  ".MuiOutlinedInput-input": {
                    width: "30px",
                    height: "4px",
                    textAlign: "center",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                    borderRadius: "0px",
                  },
                  "& fieldset": {
                    border: "none",
                  },
                }}
              />

              <CustomButton
                textColor="black"
                // variant="contained"
                label="+"
                sx={{
                  borderRadius: "0px 50px 50px 0px",
                  backgroundColor: "white",
                  borderLeftColor: "rgb(175, 112, 50)",
                  ":hover": {
                    backgroundColor: "white",
                  },
                  // ".MuiButtonGroup-grouped:not(:first-of-type)": {
                  //   borderLeft: "1px solid #bdbdbd",
                  //   borderColor: "rgb(175, 112, 50)",
                  // },
                }}
                onClick={() => handleLoyaltyPoint("inc")}
              />
            </ButtonGroup>
          </Box>

          <Box display={"flex"} justifyContent="center">
            <CustomButton
              label={"Use"}
              type="submit"
              variant="contained"
              sx={{ width: "40%" }}
              onClick={handleUseLoyaltyPoints}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

LoyaltyPointDialog.propTypes = {
  totalPrice: PropTypes.number,
  usedLoyaltyPoints: PropTypes.number,
  setUsedLoyaltyPoints: PropTypes.func,
  handleDialogClose: PropTypes.func,
  authenticatedUser: PropTypes.object,
};

export default LoyaltyPointDialog;
