import React from "react";
import {Container, Typography, Box, CardMedia } from "@mui/material";
import SectionImage1 from "../../../assets/images/Section3-Image1.png";
import SectionImage2 from "../../../assets/images/Section3-Image2.png";
import SectionImage3 from "../../../assets/images/Section3-Image3.png";
import '../home.css';

export const Section3 = () => {
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: 8,
      }}
      className="common-header p-80 c-enjoy"
      container
      align="center"
      justifyContent="center"
    >
    <Container maxWidth="xl">  
      <Box className="main-root" sx={{  width: "70%" }}>
        <Typography variant="h3"> CHOOSE & ENJOY</Typography>
        <Typography variant="h6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas ac
        </Typography>
      </Box>
      <Box
        item
        xs={6}
        md={6}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <Box alignItems="center" sx={{ padding: 1 }}>
          <CardMedia
            component="img"
            src={SectionImage1}
            alt="Live from space album cover"
            sx={{ width: "100%" }}
          />
        </Box>
        <Box
        item
        xs={6}
        md={6}
        justifyContent="flex-start"
        display="flex"
        alignItems="center"
      >
        <Box alignItems="center" sx={{ padding: 1 }}>
          <CardMedia
            component="img"
            src={SectionImage2}
            alt="Live from space album cover"
            sx={{ width: "100%", mb: 2 }}
          />
          <CardMedia
            component="img"
            src={SectionImage3}
            alt="Live from space album cover"
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      </Box>
    
      </Container>
    </Box>
  );
};

export default Section3;
