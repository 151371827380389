import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GOOGLE_LOGIN_VERIFICATION,
  GOOGLE_LOGIN_VERIFICATION_SUCCESS,
  GOOGLE_LOGIN_VERIFICATION_FAILED,
  REQ_REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  REQ_VERIFY_OTP,
  REQ_RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILED,
  RESET_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
  PASSWORD_FORGOT,
  PASSWORD_FORGOT_SUCCESS,
  PASSWORD_FORGOT_FAILED,
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  FACEBOOK_LOGIN_VERIFICATION,
  FACEBOOK_LOGIN_VERIFICATION_SUCCESS,
  FACEBOOK_LOGIN_VERIFICATION_FAILED,
  // UPDATE_USER,
  // UPDATE_USER_FAILED,
  // UPDATE_USER_SUCCESS,
} from "../actionTypes";

const initialState = {
  user: "",
  loading: false,
  token: "",
  error: {
    message: "",
    type: "",
  },
  OTP: "",
};

const messageType = { success: "success", error: "error" };

const UserReducer = (state = initialState, action) => {
  let returnObj = state;

  if (action.type === LOGIN_USER) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === LOGIN_USER_SUCCESS) {
    returnObj = {
      ...state,
      loading: false,
      user: action.payload,
      token: action.payload.access_token,
      error: { message: "" },
    };
  }

  if (action.type === LOGIN_USER_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error?.data },
    };
  }

  if (action.type === GET_PROFILE) {
    returnObj = { ...state, loading: true, token: action?.payload?.token };
  }

  if (action.type === GET_PROFILE_SUCCESS) {
    returnObj = {
      ...state,
      loading: false,
      user: action.payload,
      error: { message: "" },
    };
  }

  if (action.type === GET_PROFILE_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action.payload.message, type: action.payload.type },
    };
  }

  if (action.type === LOGOUT_USER) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === LOGOUT_USER_SUCCESS) {
    returnObj = {
      ...state,
      loading: false,
      user: action.payload,
      token: "",
      error: { message: "" },
    };
  }

  if (action.type === LOGOUT_USER_FAILED) {
    returnObj = {
      ...state,
      loading: false,
      error: { message: action?.payload?.error?.data },
    };
  }

  // GOOGLE LOGIN
  if (action.type === GOOGLE_LOGIN_VERIFICATION) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === GOOGLE_LOGIN_VERIFICATION_SUCCESS) {
    returnObj = {
      ...state,
      user: action.payload,
      loading: false,
      error: { message: action.payload.message, type: messageType.success },
    };
  }

  if (action.type === GOOGLE_LOGIN_VERIFICATION_FAILED) {
    returnObj = {
      ...state,
      user: "",
      loading: false,
      error: { message: action.payload.message, type: messageType.error },
    };
  }

  // SIGN-UP
  if (action.type === REQ_REGISTER) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === REGISTER_SUCCESS) {
    returnObj = {
      ...state,
      user: action.payload.data,
      loading: false,
      error: {
        message: action.payload.message,
        type: messageType.success,
      },
    };
  }

  if (action.type === REGISTER_FAILED) {
    returnObj = {
      ...state,
      user: "",
      loading: false,
      error: {
        message: action.payload.message,
        type: messageType.error,
      },
    };
  }

  // OTP VERIFICATION
  if (action.type === REQ_VERIFY_OTP) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === VERIFY_OTP_SUCCESS) {
    returnObj = {
      ...state,
      OTP: action.payload,
      loading: false,
      error: { message: action.payload?.message, type: messageType.success },
    };
  }

  if (action.type === VERIFY_OTP_FAILED) {
    returnObj = {
      ...state,
      OTP: "",
      loading: false,
      error: { message: action.payload?.message, type: messageType.error },
    };
  }

  if (action.type === REQ_RESEND_OTP) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === RESEND_OTP_SUCCESS) {
    returnObj = {
      ...state,
      user: action.payload,
      loading: false,
      error: { message: action.payload?.message, type: messageType.success },
    };
  }

  if (action.type === RESEND_OTP_FAILED) {
    returnObj = {
      ...state,
      OTP: "",
      loading: false,
      error: { message: action.payload?.message, type: messageType.error },
    };
  }

  if (action.type === RESET_ERROR) {
    returnObj = { ...state, error: { message: "" } };
  }

  //forgotpassword
  if (action.type === PASSWORD_FORGOT) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === PASSWORD_FORGOT_SUCCESS) {
    returnObj = {
      ...state,
      OTP: action.payload,
      loading: false,
      error: { message: action.payload?.message, type: messageType.success },
    };
  }

  if (action.type === PASSWORD_FORGOT_FAILED) {
    returnObj = {
      ...state,
      OTP: "",
      loading: false,
      error: { message: action.payload?.message, type: messageType.error },
    };
  }

  if (action.type === CONFIRM_PASSWORD) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === CONFIRM_PASSWORD_SUCCESS) {
    returnObj = {
      ...state,
      OTP: action.payload,
      loading: false,
      error: { message: action.payload?.message, type: messageType.success },
    };
  }

  if (action.type === CONFIRM_PASSWORD_FAILED) {
    returnObj = {
      ...state,
      OTP: "",
      loading: false,
      error: { message: action.payload?.message, type: messageType.error },
    };
  }

  if (action.type === CHANGE_PASSWORD) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === CHANGE_PASSWORD_SUCCESS) {
    returnObj = {
      ...state,
      OTP: action.payload,
      loading: false,
      error: { message: action.payload?.message, type: messageType.success },
    };
  }

  if (action.type === CHANGE_PASSWORD_FAILED) {
    returnObj = {
      ...state,
      OTP: "",
      loading: false,
      error: { message: action?.payload?.message, type: messageType.error },
    };
  }

  // if (action.type === UPDATE_USER) {
  //   returnObj = { ...state, loading: true };
  // }

  // if (action.type === UPDATE_USER_SUCCESS) {
  //   returnObj = {
  //     ...state,
  //     user: action.payload,
  //     loading: false,
  //     error: { message: action.payload.message, type: messageType.success },
  //   };
  // }

  // if (action.type === UPDATE_USER_FAILED) {
  //   returnObj = {
  //     ...state,
  //     loading: false,
  //     error: { message: action?.payload?.error?.data },
  //   };
  // }

  //facebooklogin
  if (action.type === FACEBOOK_LOGIN_VERIFICATION) {
    returnObj = { ...state, loading: true };
  }

  if (action.type === FACEBOOK_LOGIN_VERIFICATION_SUCCESS) {
    returnObj = {
      ...state,
      user: action.payload,
      loading: false,
      error: { message: action.payload.message, type: messageType.success },
    };
  }

  if (action.type === FACEBOOK_LOGIN_VERIFICATION_FAILED) {
    returnObj = {
      ...state,
      user: "",
      loading: false,
      error: { message: action.payload.message, type: messageType.error },
    };
  }

  return returnObj;
};

export default UserReducer;
