import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  TextField,
  styled,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Avatar,
  Box,
  Link,
} from "@mui/material";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { upadateUser } from "services/UserService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getProfile } from "store/auth/actions";
import { IMAGE_URL } from "utils/api";
import CustomButton from "components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomLoadingButton from "components/ui/CustomLoadingButton";
import { SNACKBAR_MESSAGE_TYPE } from "utils/constants";
import { snackbarOpen } from "store/snackbar/snackbarAction";
import '../useraccount.css';

const CustomTextField = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "10px",
    backgroundColor: (theme) => theme.palette.secondary.main,
    border: "1px solid  #ded5cc",
  },
}));

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  middle_name: "",
  username: "",
  birthdate: "",
  gender: "",
  file: [],
};

const ManageUser = () => {  
  const userData = useSelector((state) => state?.AuthReducer?.user?.data);

  const navigate = useNavigate();

  const token = document?.cookie
    ?.split("; ")
    ?.find((row) => row.startsWith("token="))
    ?.split("=")[1];

  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    first_name: Yup.string().required().label("First Name"),
    last_name: Yup.string().required().label("Last Name"),
    email: Yup.string("Enter a valid email address")
      .email()
      .required()
      .label("Email"),
    mobile: Yup.string().required().label("Mobile Number"),
  });

  const [initFormData, setInitFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);

  const handleChanges = (e) => {
    setFile(e.target.files);
  };

  useEffect(() => {
    const user = {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      mobile: userData?.mobile,
      middle_name: userData?.middle_name,
      username: userData?.username,
      birthdate: userData?.birthdate,
      gender: userData?.gender,
      image: userData?.image,
    };
    setInitFormData(user);
  }, [userData]);

  const onSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("first_name", values?.first_name ? values?.first_name : "");
    formData.append("last_name", values?.last_name ? values?.last_name : "");
    formData.append("email", values?.email ? values?.email : "");
    formData.append("mobile", values?.mobile ? values?.mobile : "");
    formData.append(
      "middle_name",
      values?.middle_name ? values?.middle_name : ""
    );
    formData.append("username", values?.username ? values?.username : "");
    formData.append("birthdate", values?.birthdate ? values?.birthdate : "");
    formData.append("gender", values?.gender ? values?.gender : "");
    formData.append("file", file[0] != undefined ? file[0] : null );

    upadateUser({ formData, id: userData?.id })
      .then(() => {
        if (token) {
          dispatch(getProfile({ token: token }));
        }
        dispatch(
          snackbarOpen({
            open: true,
            message: "Your profile has been updated !",
            type: SNACKBAR_MESSAGE_TYPE.success,
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          snackbarOpen({
            open: true,
            message: "Somethinh went wrong !",
            type: SNACKBAR_MESSAGE_TYPE.error,
          })
        );
        return error;
      });
  };

  const formik = useFormik({
    initialValues: initFormData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  });

  const {
    handleChange,
    handleSubmit,
    errors,
    values,
    touched,
    handleBlur,
    setFieldValue,
  } = formik;

  const inputFile = useRef();

  const changeImageButton = () => {
    inputFile.current.click();
  };

  const handleRemoveItem = () => {
    setFile([]);
    setFieldValue("image", "");
  };

  return (      
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit} className="user-profile-account">
        <Grid container align="center" mt={4} className="user-profile-container">
          <Box display={"flex"} mr={1} maxWidth="100%">       
            <Box>
              <Avatar   
                src={
                  file?.length
                    ? URL.createObjectURL(file[0])
                    : values?.image
                    ? `${IMAGE_URL}/profile/${values?.image}`
                    : null
                }
                sx={{ width: "150px", height: "150px", borderRadius: "15px" }}
              />
              <input
                type="file"
                ref={inputFile}
                id="profileImage"
                onChange={handleChanges}
                style={{ display: "none" }}
                name="image"
              />
            </Box>

            <Box display={"flex"} maxWidth="60%">
              <CustomButton
                label="Upload"
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  ml: 2,
                  py: 2,
                  border: "3px ",
                  color: "white",
                  // boxShadow: "none",
                  height: "43%",
                  marginTop: "45px",
                }}
                onClick={changeImageButton}
              />

              {(file.length || values?.image) && (
                <CustomButton
                  label="Remove"
                  variant="contained"
                  onClick={handleRemoveItem}
                  textColor="#faa148"
                  sx={{
                    py: 2,
                    m: 2,
                    borderRadius: "10px",
                    height: "43%",
                    // width: "50%",
                    marginTop: "45px",
                    backgroundColor: "white",
                    border: " solid 3px #faa148",
                    ":hover": {
                      bgcolor: "white",
                    },
                  }}
                  className="remove-btn"
                />
              )}

              {/* <Grid item xs> */}
            </Box>
          </Box>
        </Grid>

        <Grid container mt={2}>
          <Grid item md={6} xs={12} pr={2}>
            <CustomTextField
              margin="normal"
              id="first_name"
              label="First Name"
              name="first_name"
              autoComplete="first_name"
              required
              fullWidth
              onChange={handleChange}
              value={values.first_name}
              onBlur={handleBlur}
              error={errors.first_name && touched.first_name}
              helperText={
                errors.first_name && touched.first_name
                  ? errors.first_name
                  : null
              }
            />

            <CustomTextField
              margin="normal"
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              required
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : null}
              disabled={true}

            />
            <FormControl sx={{ marginTop: "10px" }} fullWidth className="datepicker-field" >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Birth Date"
                  name="birthdate"
                  value={values.birthdate}
                  onChange={(e) => setFieldValue("birthdate", e, true)}
                  renderInput={(params) => (
                    <CustomTextField fullWidth {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12} pr={2}>
            <CustomTextField
              margin="normal"
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="last_name"
              required
              fullWidth
              onChange={handleChange}
              value={values.last_name}
              onBlur={handleBlur}
              error={errors.last_name && touched.last_name}
              helperText={
                errors.last_name && touched.last_name ? errors.last_name : null
              }
            />

            <CustomTextField
              margin="normal"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              id="mobile"
              label="Phone no"
              name="mobile"
              className="auto-fill"
              required
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mobile}
              error={errors.mobile && touched.mobile}
              helperText={
                errors.mobile && touched.mobile ? errors.mobile : null
              }
              disabled={true}
            />
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <RadioGroup
                id="gender"
                name="gender"
                value={values.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                row
              >
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="Female"
                />
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Male"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ width: "100%" }}
            className="btn-submit"
          >
            <CustomLoadingButton
              label="Save"
              type="submit"
              sx={{
                py: 1,
                mt: 2,
                border: "3px ",
                color: "white",
                boxShadow: "none",
              }}
              variant="contained"
              loading={loading}
              
            />
            <Link
              href="#"
              variant="body2"
              onClick={() => {
                navigate("/change-password");
              }}
              width="fit-content"
            >
              Change password?
            </Link>
          </Box>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ManageUser;
