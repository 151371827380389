import { Fab, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import React from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const CartBar = () => {
  const cartData = useSelector((state) => state?.CartReducer?.cart);
  const navigate = useNavigate();

  var totalPrice = cartData?.reduce((acc, current) => {
    return acc?.defaultPrice
      ? acc?.defaultPrice
      : acc + current?.defaultPrice * current.quantity;
  }, 0);

  totalPrice = parseFloat(totalPrice.toFixed(2));

  return totalPrice && cartData?.length ? (
    <Fab
      sx={{
        position: "fixed",
        bottom: "3%",
        right: "4%",
        mr: "1%",
        backgroundColor: "#000",
        color: (theme) => theme?.palette?.primary?.main,
        ":hover": {
          backgroundColor: "#000",
        },
      }}
      color="primary"
      aria-label="cart"
      variant="extended"
      size="medium"
      onClick={() => navigate("/cart")}
    >
      <Typography sx={{ mr: 1 }}>
        Total: ${totalPrice}, {cartData?.length}
      </Typography>
      <ShoppingCartIcon />
    </Fab>
  ) : null;
};

export default CartBar;
